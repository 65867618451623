import React, { useContext } from 'react';
import {
    Container,
    Wrapper,
    Title,
    RequirementContainer,
    RequirementItem,
    RequirementText,
} from './samplesComponents';
import { LanguageContext } from '../../../containers/Language';

function Samples() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="samples">
            <Wrapper>
                <Title>{dictionary.samples.title}</Title>
                <RequirementContainer>
                    <RequirementItem>
                        <RequirementText>
                            {dictionary.samples.requirementText1}
                        </RequirementText>
                        <RequirementText>
                            {' '}
                            {dictionary.samples.requirementText2}
                        </RequirementText>
                    </RequirementItem>
                    <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                    ></div>
                </RequirementContainer>
            </Wrapper>
        </Container>
    );
}

export default Samples;
