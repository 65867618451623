import React, { useContext } from "react";
import {
    Container,
    Item,
    ItemContainer,
    Title,
    Wrapper,
    Texts,
    ItemImg,
    ItemTitle,
    Subtitle,
    StyledSlider,
} from "./valuesComponents";
import value1 from "../.././../images/about-school/values/value1.PNG";
import value2 from "../.././../images/about-school/values/value2.PNG";
import value3 from "../.././../images/about-school/values/value3.PNG";
import value4 from "../.././../images/about-school/values/value4.PNG";
import value5 from "../.././../images/about-school/values/value5.PNG";
import value6 from "../.././../images/about-school/values/value6.PNG";
import value7 from "../.././../images/about-school/values/value7.PNG";
import { LanguageContext } from "../../../containers/Language";

function Values() {
    const { dictionary } = useContext(LanguageContext);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
        ],
    };

    return (
        <Container id="values">
            <Wrapper>
                <Texts>
                    <Title>{dictionary.values.title}</Title>
                    <Subtitle>{dictionary.values.subtitle}</Subtitle>
                </Texts>
                <StyledSlider {...settings}>
                    <ItemContainer>
                        <Item>
                            <ItemImg src={value1} />
                        </Item>
                        <ItemTitle>Құрмет</ItemTitle>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImg src={value2} />
                        </Item>
                        <ItemTitle> ДЕНСАУЛЫҚ</ItemTitle>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImg src={value3} />
                        </Item>
                        <ItemTitle>КЕМЕЛДЕНУ</ItemTitle>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImg src={value4} />
                        </Item>
                        <ItemTitle>Жауапкершілік</ItemTitle>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImg src={value5} />
                           
                        </Item>
                        <ItemTitle>ӨМІР БОЙЫ ОҚУ</ItemTitle>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImg src={value6} />
                            
                        </Item>
                        <ItemTitle>ӘДІЛЕТТІЛІК</ItemTitle>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImg src={value7} />
                            
                        </Item>
                        <ItemTitle>ЭМПАТИЯ</ItemTitle>
                    </ItemContainer>
                </StyledSlider>
            </Wrapper>
        </Container>
    );
}

export default Values;
