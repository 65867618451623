import styled from "styled-components";
import Slider from "react-slick";

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
`;

export const Wrapper = styled.div`
    width: 90%;
    max-width: 1280px;
    display: flex;
    padding: 70px 0;
    display: flex;
    flex-direction: column;
    color: black;

    @media only screen and (max-width: 30em) {
        padding: 0 10px;
        margin: 40px 0 50px;
    }
`;

export const Texts = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
`;

export const Title = styled.h1`
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 20px;
    text-align: center;

    @media only screen and (max-width: 67em) {
        font-size: 3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const Subtitle = styled.p`
    font-size: 1.5rem;
    line-height: 1.3;

    @media only screen and (max-width: 67em) {
        font-size: 1.3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1.2rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;

export const ItemContainer = styled.div`
    display: flex;
    cursor: pointer;
`;

export const Item = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid lightgrey;
    margin: 20px;
    height: 540px;

    @media only screen and (max-width: 30em) {
        height: 630px;
    }
`;

export const ItemImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const ItemImgContainer = styled.div`
    height: 430px;

    @media only screen and (max-width: 30em) {
        height: 520px;
    }
`;

export const ItemTitle = styled.span`
    font-size: 17px;
    line-height: 1.5;
    margin-bottom: 7px;
    padding: 10px 20px;
    margin: 0 1px;
`;

export const ItemInfo = styled.span`
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 7px;
    padding: 0 20px 10px;
    margin: 0 1px;
    color: #666666;
`;

export const StyledSlider = styled(Slider)`
    .slick-prev {
        width: 3rem;
        height: 3rem;
        left: -3.4rem;

        @media only screen and (max-width: 67em) {
            width: 2.5rem;
            height: 2.5rem;
            left: -2.4rem;
        }

        @media only screen and (max-width: 30em) {
            width: 2rem;
            height: 2rem;
            left: -1.4rem;
        }
    }

    .slick-next {
        width: 3rem;
        height: 3rem;
        right: -3.4rem;

        @media only screen and (max-width: 67em) {
            width: 2.5rem;
            height: 2.5rem;
            right: -2.4rem;
        }

        @media only screen and (max-width: 30em) {
            width: 2rem;
            height: 2rem;
            right: -1.4rem;
        }
    }

    .slick-arrow {
        border: 1px solid #121233;
        background-color: white;
        border-radius: 50%;

        @media only screen and (max-width: 30em) {
         border: none;
        background-color: white;
        border-radius: none;
        }
    }

    .slick-prev:before,
    .slick-next:before {
        color: #121233 !important;
    }
`;
