import React, { useContext } from "react";
import {
    Container,
    Wrapper,
    InfoContainer,
    ImgContainer,
    Title,
    Subtitle,
    Img,
    Top,
    Bottom,
    BottomImgContainer,
    BottomImg,
    ImgBigContainer,
    StyledSlider,
} from "./parentsComponents";
import parent2 from "../../././../images/educational-work/parent/parent2.jpeg";
import parent3 from "../../././../images/educational-work/parent/parent3.jpeg";
import parent4 from "../../././../images/educational-work/parent/parent4.PNG";
import parent5 from "../../././../images/educational-work/parent/parent5.jpeg";
import parent6 from "../../././../images/educational-work/parent/parent6.jpeg";
import parent9 from "../../././../images/educational-work/parent/parent9.jpeg";
import parent1 from "../../././../images/educational-work/parent/IMG_5559.JPG";
import parent7 from "../../././../images/educational-work/parent/IMG_5562.jpeg";
import parent10 from "../../././../images/educational-work/parent/IMG_5553.jpeg";
import parent11 from "../../././../images/educational-work/parent/IMG_5555.jpeg";
import { LanguageContext } from "../../../containers/Language";

function Mission() {
    const { dictionary } = useContext(LanguageContext);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
        ],
    };

    return (
        <Container id="parents">
            <Wrapper>
                <Top>
                    <ImgBigContainer>
                        <ImgContainer>
                            <Img src={parent4} />
                        </ImgContainer>
                    </ImgBigContainer>
                    <InfoContainer>
                        <Title>{dictionary.parents.title}</Title>
                        <Subtitle>{dictionary.parents.subtitle1}</Subtitle>
                        <Subtitle> {dictionary.parents.subtitle2} <b>{dictionary.parents.subtitle2bold}</b>{dictionary.parents.subtitle2rest}</Subtitle>
                    </InfoContainer>
                </Top>
                <Bottom>
                    <StyledSlider {...settings}>
                        <BottomImgContainer>
                            <BottomImg src={parent6} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={parent5} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={parent9} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={parent11} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={parent2} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={parent3} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={parent1} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={parent7} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={parent10} />
                        </BottomImgContainer>
                       
                    </StyledSlider>
                </Bottom>
            </Wrapper>
        </Container>
    );
}

export default Mission;
