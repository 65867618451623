import React, {useContext} from 'react';
import {
    Container,
    Wrapper,
    InfoContainer,
    Title,
    Subtitle,
} from "./helpComponents";
import { LanguageContext } from "../../../containers/Language";

function Mission() {
    const { dictionary } = useContext(LanguageContext);
    
    return (
        <Container id="help">
            <Wrapper>
                <InfoContainer>
                    <Title>{dictionary.help.title}</Title>
                    <Subtitle>
                    {dictionary.help.subtitle1}
                    </Subtitle>
                    <Subtitle>
                    {dictionary.help.subtitle2}
                    </Subtitle>
                    <Subtitle>
                    {dictionary.help.subtitle3}
                    </Subtitle>
                    <Subtitle>
                    {dictionary.help.subtitle4}
                    </Subtitle>
                    <Subtitle><a href="psychology@kemelbilim.edu.kz"  style={{color: "inherit", cursor: "pointer"}}><b>psychology@kemelbilim.edu.kz</b></a></Subtitle>
                </InfoContainer>
            </Wrapper>
        </Container>
    );
}

export default Mission;
