import React, { useContext } from 'react';
import {
    Container,
    Wrapper,
    Title,
    Subtitle,
    Document,
    Lists,
    List,
} from './documentComponents';
import { LanguageContext } from '../../../containers/Language';

export default function Documents() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="documents">
            <Wrapper>
                <Title>{dictionary.documents.title}</Title>
                <Subtitle>{dictionary.documents.subtitle}</Subtitle>
                <Document>
                    <Lists>
                        <List>{dictionary.documents.list1}</List>
                        <List>{dictionary.documents.list2}</List>
                        <List>{dictionary.documents.list3}</List>
                        <List>{dictionary.documents.list4}</List>
                        <List>{dictionary.documents.list5}</List>
                        <List>{dictionary.documents.list6} </List>
                        <List>{dictionary.documents.list7} </List>
                        <List>{dictionary.documents.list8} </List>
                        <List>{dictionary.documents.list9}</List>
                        <List>{dictionary.documents.list10}</List>
                    </Lists>
                </Document>
            </Wrapper>
        </Container>
    );
}
