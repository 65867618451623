import React, { useContext } from 'react';
import {
    Container,
    Item,
    ItemContainer,
    Title,
    Wrapper,
    Texts,
    Subtitle,
    PostDate,
    PostTitle,
    PostSubtitle,
} from './newsSeparateComponents';
import { LanguageContext } from '../../containers/Language';

function NewsSeparate() {
    const { dictionary } = useContext(LanguageContext);

    return (
        <Container>
            <Wrapper>
                <Texts>
                    <Title> {dictionary.news.title}</Title>
                    <Subtitle>{dictionary.news.subtitle}</Subtitle>
                </Texts>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate1}</PostDate>
                            <PostTitle>{dictionary.news.postTitle1}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle1}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate2}</PostDate>
                            <PostTitle>{dictionary.news.postTitle2}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle2}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate3}</PostDate>
                            <PostTitle>{dictionary.news.postTitle3}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle3}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate4}</PostDate>
                            <PostTitle>{dictionary.news.postTitle4}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle4}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate5}</PostDate>
                            <PostTitle>{dictionary.news.postTitle5}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle5}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate6}</PostDate>
                            <PostTitle>{dictionary.news.postTitle6}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle6}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate7}</PostDate>
                            <PostTitle>{dictionary.news.postTitle7}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle7}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate8}</PostDate>
                            <PostTitle>{dictionary.news.postTitle8}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle8}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate9}</PostDate>
                            <PostTitle>{dictionary.news.postTitle9}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle9}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate10}</PostDate>
                            <PostTitle>{dictionary.news.postTitle10}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle10}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate11}</PostDate>
                            <PostTitle>{dictionary.news.postTitle11}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle11}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate12}</PostDate>
                            <PostTitle>{dictionary.news.postTitle12}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle12}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate13}</PostDate>
                            <PostTitle>{dictionary.news.postTitle13}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle13}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate14}</PostDate>
                            <PostTitle>{dictionary.news.postTitle14}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle14}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate15}</PostDate>
                            <PostTitle>{dictionary.news.postTitle15}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle15}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate16}</PostDate>
                            <PostTitle>{dictionary.news.postTitle16}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle16}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate17}</PostDate>
                            <PostTitle>{dictionary.news.postTitle17}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle17}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate18}</PostDate>
                            <PostTitle>{dictionary.news.postTitle18}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle18}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate19}</PostDate>
                            <PostTitle>{dictionary.news.postTitle19}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle19}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate20}</PostDate>
                            <PostTitle>{dictionary.news.postTitle20}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle20}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
            </Wrapper>
        </Container>
    );
}

export default NewsSeparate;