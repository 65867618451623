import React from 'react';
import {
    Container,
    Item,
    ItemContainer,
    Title,
    Wrapper,
    Texts,
    PostDate,
    PostTitle,
    PostSubtitle,
} from './careersComponents';

function NewsSeparate() {

    return (
        <Container>
            <Wrapper>
                <Texts>
                    <Title> ВАКАНСИЯЛАР</Title>
                </Texts>
                <ItemContainer>
                    <Item>
                        <PostDate>До 20.03.2024

                        </PostDate>
                        <PostTitle>Объявление о вакансии: Координатор программы IB PYP для школы KEMEL BILIM</PostTitle>
                        <PostSubtitle>
                            Школа KEMEL BILIM, расположенная в городе Актобе, объявляет о поиске координатора программы Международного бакалавриата начальных классов (IB Primary Years Programme - PYP)
                        </PostSubtitle>
                        <PostSubtitle>
                            С сентября 2024 года наша школа переезжает на новый кампус и начинает реализацию программы IB PYP. Мы ищем опытного координатора, который возьмет на себя руководство этим важным направлением и поможет нам в создании качественной образовательной программы для младших классов.
                        </PostSubtitle>
                        <PostSubtitle>
                            <b>Основные обязанности:</b>
                        </PostSubtitle>
                        <ul>
                        <li><PostSubtitle>
                            Разработка и внедрение учебной программы IB PYP в соответствии с требованиями и стандартами IB.</PostSubtitle></li>
                            <li> <PostSubtitle>Курирование процесса авторизации и внедрения программы PYP в новом кампусе.
                        </PostSubtitle></li>
                        <li><PostSubtitle>Организация обучения и поддержка педагогического состава в освоении и применении методик и принципов программы PYP.
                        </PostSubtitle></li>
                        <li> <PostSubtitle>Содействие в создании образовательной среды, способствующей развитию у учащихся навыков критического мышления и самостоятельности.
                        </PostSubtitle></li>
                        <li> <PostSubtitle>Взаимодействие с родительской общиной и участие в жизни школьного сообщества.
                            </PostSubtitle></li>
                        </ul>
                        <PostSubtitle>
                            <b>Требования к кандидату:</b>
                        </PostSubtitle>
                        <ul>
                        <li><PostSubtitle>
                        Высшее образование в области педагогики.</PostSubtitle></li>
                            <li> <PostSubtitle>Опыт работы по программе IB PYP, желательно в роли координатора.
                        </PostSubtitle></li>
                        <li><PostSubtitle>Отличные организационные и коммуникативные навыки.
                        </PostSubtitle></li>
                        <li> <PostSubtitle>Знание английского языка на уровне свободного владения.
                        </PostSubtitle></li>
                        <li> <PostSubtitle>Готовность к инновациям и способность к творческому подходу в образовании.
                            </PostSubtitle></li>
                        </ul>
                        <PostSubtitle>
                            <b>Условия работы:</b>
                        </PostSubtitle>
                        <ul>
                        <li><PostSubtitle>
                        Первоначальный срок контракта: 2 года.</PostSubtitle></li>
                            <li> <PostSubtitle>Заработная плата: конкурентоспособная, обсуждается индивидуально с успешным кандидатом.
                        </PostSubtitle></li>
                        <li><PostSubtitle>Поддержка в аренде жилья для иногородних сотрудников.
                        </PostSubtitle></li>
                        <li> <PostSubtitle>Возможности для профессионального роста и развития.
                        </PostSubtitle></li>
                        </ul>
                        <PostSubtitle>
                            <b>Как подать заявку:</b>
                        </PostSubtitle>
                      
                       <PostSubtitle>
                        Отправьте ваше резюме и мотивационное письмо на электронный адрес: careers@bil.edu.kz.</PostSubtitle>
                            <PostSubtitle>Мы с нетерпением ждем новых талантов, готовых вместе с нами создавать лучшее будущее для наших учеников!
                        </PostSubtitle>
                        <PostSubtitle>Поддержка в аренде жилья для иногородних сотрудников.
                        </PostSubtitle>
                       <PostSubtitle>Возможности для профессионального роста и развития.
                        </PostSubtitle>
                        <PostSubtitle><b>Контактная информация:</b></PostSubtitle>
                        <PostSubtitle>Школа KEMEL BILIM</PostSubtitle>
                        <PostSubtitle>Город Актобе</PostSubtitle>
                        <PostSubtitle>Эл. почта: careers@bil.edu.kz</PostSubtitle>
                        <PostSubtitle>Веб-сайт: https://kemelbilim.edu.kz</PostSubtitle>
                      
                    </Item>
                </ItemContainer>
                <ItemContainer>
                    <Item>
                        <PostDate>До 20.03.2024

                        </PostDate>
                        <PostTitle>Объявление о вакансии: Координатор программы IB MYP для школы KEMEL BILIM</PostTitle>
                        <PostSubtitle>
                            Школа KEMEL BILIM, расположенная в городе Актобе, объявляет о поиске координатора программы Международного бакалавриата начальных классов (IB Middle  Years Programme - MYP)
                        </PostSubtitle>
                        <PostSubtitle>
                            С сентября 2024 года наша школа переезжает на новый кампус и начинает реализацию программы IB MYP. Мы ищем опытного координатора, который возьмет на себя руководство этим важным направлением и поможет нам в создании качественной образовательной программы для младших классов.
                        </PostSubtitle>
                        <PostSubtitle>
                            <b>Основные обязанности:</b>
                        </PostSubtitle>
                        <ul>
                        <li><PostSubtitle>
                            Разработка и внедрение учебной программы IB MYP в соответствии с требованиями и стандартами IB.</PostSubtitle></li>
                            <li> <PostSubtitle>Курирование процесса авторизации и внедрения программы MYP в новом кампусе.
                        </PostSubtitle></li>
                        <li><PostSubtitle>Поддержка и обучение учителей методикам и принципам IB MYP.
                        </PostSubtitle></li>
                        <li> <PostSubtitle>Создание образовательной среды, способствующей развитию учащихся.
                        </PostSubtitle></li>
                        <li> <PostSubtitle>Участие в развитии школьного сообщества и взаимодействие с родителями.
                            </PostSubtitle></li>
                        </ul>
                        <PostSubtitle>
                            <b>Требования к кандидату:</b>
                        </PostSubtitle>
                        <ul>
                        <li><PostSubtitle>
                        Высшее образование в области образования или педагогики.</PostSubtitle></li>
                            <li> <PostSubtitle>Опыт работы по программе IB MYP, желательно в роли координатора.
                        </PostSubtitle></li>
                        <li><PostSubtitle>Превосходные организационные и коммуникативные способности.
                        </PostSubtitle></li>
                        <li> <PostSubtitle>Владение английским языком на высоком уровне.
                        </PostSubtitle></li>
                        <li> <PostSubtitle>Инициативность и креативный подход к обучению.
                            </PostSubtitle></li>
                        </ul>
                        <PostSubtitle>
                            <b>Условия работы:</b>
                        </PostSubtitle>
                        <ul>
                        <li><PostSubtitle>
                        Первоначальный срок контракта: 2 года.</PostSubtitle></li>
                            <li> <PostSubtitle>Конкурентоспособная заработная плата.
                        </PostSubtitle></li>
                        <li><PostSubtitle>Поддержка с жильем для не местных сотрудников.
                        </PostSubtitle></li>
                        <li> <PostSubtitle>Широкие возможности для профессионального и личностного роста.
                        </PostSubtitle></li>
                        </ul>
                        <PostSubtitle>
                            <b>Как подать заявку:</b>
                        </PostSubtitle>
                      
                       <PostSubtitle>
                       Присылайте ваше резюме и мотивационное письмо на адрес: careers@bil.edu.kz
</PostSubtitle>
                            <PostSubtitle>Мы с нетерпением ждем новых талантов, готовых вместе с нами создавать лучшее будущее для наших учеников!
                        </PostSubtitle>
                    
                        <PostSubtitle><b>Контактная информация:</b></PostSubtitle>
                        <PostSubtitle>Школа KEMEL BILIM</PostSubtitle>
                        <PostSubtitle>Город Актобе</PostSubtitle>
                        <PostSubtitle>Эл. почта: careers@bil.edu.kz</PostSubtitle>
                        <PostSubtitle>Веб-сайт: https://kemelbilim.edu.kz</PostSubtitle>
                      
                    </Item>
                </ItemContainer>

            </Wrapper>
        </Container>
    );
}

export default NewsSeparate;