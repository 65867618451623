import React, {useContext} from 'react';
import {
    Container,
    Wrapper,
    InfoContainer,
    Title,
    Subtitle,
} from "./pblComponents";
import { LanguageContext } from "../../../containers/Language";

function Pbl() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="pbl">
            <Wrapper>
            <InfoContainer>
                    <Title>{dictionary.pbl.title}</Title>
                    <Subtitle>{dictionary.pbl.subtitle1}</Subtitle>
                    <Subtitle>{dictionary.pbl.subtitle2}</Subtitle> 
                    <Subtitle>{dictionary.pbl.subtitle4}</Subtitle> 
                </InfoContainer>
            </Wrapper>
        </Container>
    );
}

export default Pbl;
