import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 67em) {
        display: none;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1280px;
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const MenuList = styled.ul`
    list-style: none;
    display: flex;
    justify-content: center;
`;

export const MenuItem = styled.li`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const MenuLink = styled.a`
    text-decoration: none;
    text-transform: uppercase;
    color: #151515;
    font-weight: 500;
    font-size: 16px;
    padding: 0 30px;
    transition: 0.5s;
    border-right: ${(props) =>
        props.lastChild ? "none" : "1px solid #d0d0d0"};
`;
