import styled from "styled-components";

export const Container = styled.div``;

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: calc(100vh - 130px);
    background-color: black;
    @media only screen and (max-width: 67em) {
        height: calc(60vh);
        padding: 0;
        flex-direction: column;
    }

    @media only screen and (max-width: 48em) {
        height: calc(60vh - 70px);
    }

    @media only screen and (max-width: 30em) {
        height: 80vh;
    }
`;

export const BannerImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.6;

    @media only screen and (max-width: 30em) {
    }
`;
export const Info = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export const Title = styled.h1`
    color: white;
    text-transform: uppercase;
    margin-bottom: 30px;
    line-height: 1.1;
    width: 80%;
    font-size: 4.75rem;
    font-weight: 600;
    text-align: center;
    margin-right: auto;
    margin-left: auto;

    @media only screen and (max-width: 67em) {
        font-size: 2.8rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.625em;
        margin-bottom: 26px;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.75em;
    }
`;
export const Subtitle = styled.h1`
    color: white;
    line-height: 1.1;
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
    margin-right: auto;
    margin-left: auto;

    @media only screen and (max-width: 67em) {
        font-size: 1.5rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1.375rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.1rem;
    }
`;
