import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Banner from "../components/home/banner/Banner";
import About from "../components/home/about/About";
import Question from "../components/home/faq/FAQ";
import News from "../components/home/news/News";
import Info from "../components/home/info/Info";
import Activity from "../components/home/activity/Activity";
import Feature from "../components/home/feature/Feature";

function Home() {
    return (
        <div>
            <Header />
            <Banner />
            <About />
            <Activity />
            <Feature />
            <News />
            <Question />
            <Info />
            <Footer />
        </div>
    );
}

export default Home;
