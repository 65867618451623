import React from 'react';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import SchoolCertification from '../components/school-certification/SchoolCertification';

function SchoolCertificationPage() {
    return (
        <div>
            <Header />
            <SchoolCertification />
            <Footer />
        </div>
    );
}

export default SchoolCertificationPage;
