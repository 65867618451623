import React, { useContext } from "react";
import {
    Container,
    Item,
    ItemContainer,
    ItemInfo,
    ItemLink,
    Title,
    Wrapper,
    Texts,
    ItemImg,
    Subtitle,
    StyledSlider,
} from "./faqComponents";
import { LanguageContext } from "../../../containers/Language";
import address from "../../../images/home/address.jpg";
import faq2 from "../../../images/home/faq2.jpeg";
import faq3 from "../../../images/home/faq3.jpeg";

function Question() {
    const { dictionary } = useContext(LanguageContext);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <Container>
            <Wrapper>
                <Texts>
                    <Title> {dictionary.faq.title}</Title>
                    <Subtitle>{dictionary.faq.subtitle}</Subtitle>
                </Texts>
                <StyledSlider {...settings}>
                    <ItemContainer>
                        <ItemLink href="/contacts">
                            <Item>
                                <ItemImg src={address} />
                                <ItemInfo>{dictionary.faq.ItemInfo1}</ItemInfo>
                            </Item>
                        </ItemLink>
                    </ItemContainer>
                    <ItemContainer>
                        <ItemLink href={"/education-cost"}>
                            <Item>
                                <ItemImg src={faq2} />
                                <ItemInfo>{dictionary.faq.ItemInfo2}</ItemInfo>
                            </Item>
                        </ItemLink>
                    </ItemContainer>
                    <ItemContainer>
                        <ItemLink href="/admission">
                            <Item>
                                <ItemImg src={faq3} />
                                <ItemInfo>{dictionary.faq.ItemInfo3}</ItemInfo>
                            </Item>
                        </ItemLink>
                    </ItemContainer>
                </StyledSlider>
            </Wrapper>
        </Container>
    );
}

export default Question;
