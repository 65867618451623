import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;

export const Wrapper = styled.div`
    max-width: 1280px;
    display: flex;
    justify-content: center;
    padding: 90px 20px 0 20px;
    z-index: 2;

    @media only screen and (max-width: 67em) {
        width: 1050px;
        padding-top: 80px;
    }

    @media only screen and (max-width: 48em) {
        padding-top: 50px;
    }

    @media only screen and (max-width: 30em) {
        flex-flow: column-reverse;
        padding-bottom: 40px;
        padding: 50px 15px 40px 15px;
    }

    @media only screen and (max-width: 22em) {
        padding-bottom: 40px;
    }
`;

export const Left = styled.div`
    flex: 1;
    justify-content: center;
`;

export const LeftText = styled.div`
    padding: 50px 78px;
    color: black;
    justify-content: flex-start;
    align-self: flex-start;

    @media only screen and (max-width: 67em) {
        padding: 90px 40px;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1rem;
        padding: 80px 30px 60px;
        margin-bottom: -80px;
    }

    @media only screen and (max-width: 30em) {
        padding: 30px 0 0;
        margin-bottom: 0;
    }
`;

export const Right = styled.div`
    display: flex ;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 0 30px;
    font-size: 1.25rem;
    width: calc(50% - 50px);

    @media only screen and (max-width: 67em) {
        padding-bottom: 50px;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1rem;
        padding: 0 20px;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        padding: 0 0 20px 0;
    }
`;

export const Img = styled.img`
    width: 100%;
    padding: 0 35px;
    height: 367px;
    object-fit: cover;

    @media only screen and (max-width: 48em) {
        padding: 0 20px;
        height: 300px;
    }

    @media only screen and (max-width: 30em) {
        padding: 0;
        height: 220px;
    }
`;

export const Title = styled.h1`
    text-transform: uppercase;
    font-size: 3.75rem;
    line-height: 1.1;
    font-weight: 800;
    color: #10a74b;;
    padding: ${(props) => (props.right ? "20px 0 0 20px" : "")};

    @media only screen and (max-width: 67em) {
        font-size: 2.5rem;
    }
    @media only screen and (max-width: 48em) {
        font-size: 2rem;
        padding: ${(props) => (props.right ? "20px 0 0 10px" : "")};
    }

    @media only screen and (max-width: 40em) {
        font-size: 1.8rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.6rem;
        padding: 0;
    }
`;

export const Subtitle = styled.p`
    padding-top: 20px;
    font-size: 1.25rem;
    width: ${(props) => (props.right ? "" : "72%")};
    line-height: ${(props) => (props.right ? "1.6" : "1.3")};
    color: grey;
    padding-left: ${(props) => (props.right ? "20px" : "")};

    @media only screen and (max-width: 48em) {
        width: 100%;
        font-size: 1rem;
        padding-left: ${(props) => (props.right ? "10px" : "")};
    }

    @media only screen and (max-width: 30em) {
        padding-top: 0;
        padding-left: ${(props) => (props.right ? "0" : "")};
        margin-top: 20px;
    }
`;
