import styled from "styled-components";

export const Container = styled.div`
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin: 90px 0;

    @media only screen and (max-width: 48em) {
        margin: 70px 0;
    }

    @media only screen and (max-width: 30em) {
        margin: 40px 0;
        padding: 0 15px;
    }
`;

export const Top = styled.div`

`;

export const Bottom = styled.div`
    display: flex;
    border: 1px solid lightgray;

    @media only screen and (max-width: 48em) {
        flex-direction: column;
    }

    @media only screen and (max-width: 30em) {
        flex-direction: column;
    }
`;

export const MapContainer = styled.div`
    width: 43%;

    @media only screen and (max-width: 48em) {
        width: 100%;
    }

    @media only screen and (max-width: 30em) {
        height: 320px;
        width: 100%;
    }
`;

export const Script = styled.iframe`
    border: none;
`;

export const InfoContainer = styled.div`
    display: flex;
    padding-left: 50px;
    width: 57%;
    background: #f1f1f1;
    position: relative;
    font-size: 1rem;

    @media only screen and (max-width: 67em) {
        padding-left: 40px;
        padding-right: 20px;
    }

    @media only screen and (max-width: 48em) {
        padding-top: 30px;
        width: 100%;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        padding: 40px 25px 20px;
        flex-direction: column;
        order: 1;
    }
`;

export const Title = styled.h2`
    padding: 15px 0 20px;
    text-align: center;
    line-height: 1.7;
`;

export const Text = styled.div`
    flex: 2;
    flex-direction: column;
    padding: 0;
`;

export const Subtitle = styled.p`
    padding-bottom: 20px;
    text-decoration: underline;
`;
export const List = styled.ul`
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
`;

export const ListItemInfo = styled.li`
    padding-bottom: 7px;
    display: flex;
    color: black;
    line-height: 1.2;
`;

export const ListItemWrapper = styled.div`
    margin-bottom: 20px;
`;

export const IconContainer = styled.div`
    display: flex;
    gap: 7px;
    color: #1a1a4d;
    padding-right: 5px;
`;



export const ContactsRow = styled.div`
    display: flex;
    gap: 15px;
    justify-content: space-around;

    @media only screen and (max-width: 67em) {
        flex-wrap: wrap;
    }
`;

export const ContactContainer = styled.div`
    background: #f1f1f1;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 17px;
    font-size: 16px;

    @media only screen and (max-width: 67em) {
        width: calc(33.33% - 10px);
        min-height: 120px;
    }

    @media only screen and (max-width: 48em) {
        padding: 15px;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        padding: 25px 30px;
    }
`;

export const ContactTitle = styled.div`
    margin-bottom: 5px;
    font-weight: bold;
    padding-bottom: 5px;
    justify-content: center;
    align-items: center;
    line-height: 1.3;
`;

export const ContactLink = styled.a`
    color: #151515;
    transition: 0.5s;
    white-space: nowrap;
    text-decoration: none;
`;

export const SocialMedia = styled.div`
    padding: 0 60px;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 30em) {
        padding: 0 60px;
    } 

    @media only screen and (max-width: 20em) {
        padding: 0 40px;
    } 
`;

export const SocialMediaContainer = styled.div`
    display: flex;
    padding-bottom: 40px;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;

    @media only screen and (max-width: 30em) {
        gap: 10px;
    } 
`;

export const Link = styled.a`
    display: flex;
    text-decoration: none;
    list-style-type: none;
`;
