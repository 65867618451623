import React, {useContext} from "react";
import {
    Container,
    Wrapper,
    Right,
    Left,
    Img,
    Title,
    Subtitle,
    LeftText
} from "./aboutComponents";
import about from "../.././../images/home/about.jpg";
import { LanguageContext } from "../../../containers/Language";

function About() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container>
            <Wrapper>
                <Left>
                    <Img src={about} />
                    <LeftText>
                        <Title>{dictionary.about.title1}</Title>
                        <Title>{dictionary.about.title2}</Title>
                        <Subtitle>
                        {dictionary.about.subtitle}
                        </Subtitle>
                    </LeftText>
                </Left>
                <Right>
                    <Title right>
                    {dictionary.about.title3}
                    </Title>
                    <Subtitle right>
                    {dictionary.about.subtitle2} <b>{dictionary.about.subtitle2bold}</b>{dictionary.about.subtitle2rest}
                    </Subtitle>
                </Right>
            </Wrapper>
        </Container>
    );
}

export default About;
