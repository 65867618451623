import styled from "styled-components";

export const Container = styled.div`
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const Wrapper = styled.div`
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    margin: 40px 0 90px;
    padding: 0 20px;

    @media only screen and (max-width: 67em) {
        margin: 40px 0 80px;
    }

    @media only screen and (max-width: 48em) {
        margin: 60px 0 60px;
    }

    @media only screen and (max-width: 30em) {
        margin: 50px 0 50px;
        padding: 0 15px;
    }
`;

export const Info = styled.div`
    font-weight: 800;
    font-size: 2rem;
    margin-bottom: 30px;
    line-height: 1.2;
`;
export const Items = styled.div`
    display: flex;
    gap: 17px;

    @media only screen and (max-width: 67em) {
      flex-wrap: wrap;
    }
`;

export const Item = styled.div`
    border: 1px solid #cfcfcf;
    padding: 17px 21px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 250px;

    @media only screen and (max-width: 67em) {
        width: calc(50% - 10px);
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
    }
    
`;

export const Title = styled.div`
    color: #868686;
    font-size: 1.5rem;
    margin-bottom: 7px;

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;

export const Text = styled.div`
    color: #151515;
    font-size: 1.5rem;
    line-height: 1.3;

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;

export const Subtitle = styled.div`
    margin: 0 0 12.5px;
`;
