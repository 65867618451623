import React, { useContext } from "react";
import {
    Container,
    Wrapper,
    InfoContainer,
    ImgContainer,
    Title,
    Subtitle,
    Img,
} from "./classesComponents";
import { LanguageContext } from "../../../containers/Language";
import classes from "../.././../images/education-process/classses.JPG";

function Classes() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="classes">
            <Wrapper>
                <ImgContainer>
                    <Img src={classes} />
                </ImgContainer>
                <InfoContainer>
                    <Title>{dictionary.classes.title}</Title>
                    <Subtitle>
                        1. {dictionary.classes.subtitle1}
                    </Subtitle>
                    <Subtitle>2. {dictionary.classes.subtitle2}</Subtitle>
                    <Subtitle>3. {dictionary.classes.subtitle3}</Subtitle>
                    <Subtitle>
                        4. {dictionary.classes.subtitle4}
                    </Subtitle>
                    <Subtitle>
                        5. {dictionary.classes.subtitle5}
                    </Subtitle>
                    <Subtitle>
                        6. {dictionary.classes.subtitle6}
                    </Subtitle>
                    <Subtitle>
                        7. {dictionary.classes.subtitle7}
                    </Subtitle>
                    <Subtitle>
                        8. {dictionary.classes.subtitle8}
                    </Subtitle>
                    <Subtitle>
                        9. {dictionary.classes.subtitle9}
                    </Subtitle>
                </InfoContainer>
            </Wrapper>
        </Container>
    );
}

export default Classes;
