import React, {useContext} from "react";
import {
    Container,
    Wrapper,
    Left,
    Right,
    MapContainer,
    InfoContainer,
    List,
    ListItemInfo,
    ListItemWrapper,
    IconContainer,
    Title,
    Subtitle,
    RightContainerTop,
    RightContainerBottom,
    RightContainer,
    Text,
    Script,
    Link,
} from "./infoComponents";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
    faArrowRightToCity,
    faEnvelope,
    faLocationDot,
    faMoneyCheck,
    faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LanguageContext } from "../../../containers/Language";

function Info() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container>
            <Wrapper>
                <Left>
                    <MapContainer>
                        <Script
                            src="https://yandex.ru/map-widget/v1/?um=constructor%3A8babb0e98a74ba894a9065dbf53bf56098f449126232e203faf18f4f91bd4f22&amp;source=constructor"
                            width="100%"
                            height="440"
                            frameborder="0"
                        ></Script>
                    </MapContainer>
                    <InfoContainer>
                        <Title>   {dictionary.info.title1}</Title>
                        <List>
                            <ListItemWrapper>
                                <ListItemInfo>
                                    <IconContainer>
                                        <FontAwesomeIcon icon={faLocationDot} />
                                    </IconContainer>
                                    <b>{dictionary.info.listItemInfo1}</b>
                                </ListItemInfo>
                                <ListItemInfo>
                                    030020, Ақтөбе қ., Маресьев көш., 81 үй
                                </ListItemInfo>
                            </ListItemWrapper>
                            <ListItemWrapper>
                                <ListItemInfo>
                                    <IconContainer>
                                        <FontAwesomeIcon icon={faClock} />
                                    </IconContainer>
                                    <b>{dictionary.info.listItemInfo2}</b>
                                </ListItemInfo>
                                <ListItemInfo>
                                    Дс. – Жм.: 09:00 - 18:00
                                </ListItemInfo>
                            </ListItemWrapper>
                            <ListItemWrapper>
                                <ListItemInfo>
                                    <IconContainer>
                                        <FontAwesomeIcon icon={faPhone} />
                                    </IconContainer>
                                    <b>{dictionary.info.listItemInfo3}</b>
                                </ListItemInfo>
                                <ListItemInfo>8 (707) 555-24-24</ListItemInfo>
                            </ListItemWrapper>
                            <ListItemWrapper>
                                <ListItemInfo>
                                    <IconContainer>
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </IconContainer>
                                    <b>{dictionary.info.listItemInfo4}</b>
                                </ListItemInfo>
                                <ListItemInfo>info@kemelbilim.edu.kz</ListItemInfo>
                            </ListItemWrapper>
                        </List>
                    </InfoContainer>
                </Left>
                <Right>
                    <RightContainerTop>
                        <Text>
                            <Title>{dictionary.info.title2}</Title>
                            <Subtitle>
                                <Link href="/registration">{dictionary.info.link1}</Link>
                            </Subtitle>
                        </Text>
                        <RightContainer>
                            <FontAwesomeIcon
                                icon={faArrowRightToCity}
                                className="fa-4x"
                            />
                        </RightContainer>
                    </RightContainerTop>
                    <RightContainerBottom>
                        <Text>
                            <Title>{dictionary.info.title3}</Title>
                            <Subtitle>
                                <Link href="/education-cost">
                                {dictionary.info.link2}
                                </Link>
                            </Subtitle>
                        </Text>
                        <RightContainer>
                            <FontAwesomeIcon
                                icon={faMoneyCheck}
                                className="fa-4x"
                            />
                        </RightContainer>
                    </RightContainerBottom>
                </Right>
            </Wrapper>
        </Container>
    );
}

export default Info;
