import React, { useContext } from "react";
import {
    Container,
    Item,
    ItemContainer,
    ItemImgContainer,
    ItemInfo,
    Title,
    Wrapper,
    Texts,
    ItemImg,
    ItemTitle,
    Subtitle,
    Top,
    Bottom,
    ImgContainer,
    InfoContainer,
    Img,
    InfoContainerTitle,
    InfoContainerSubtitle,
    StyledSlider,
} from "./administrationComponents";
import { LanguageContext } from "../../../containers/Language";
import akimshilik1 from "../.././../images/about-school/akimshilik/akimshilik1.jpeg";
import akimshilik3 from "../.././../images/about-school/akimshilik/akimshilik3.png";
import akimshilik4 from "../.././../images/about-school/akimshilik/akimshilik4.png";
import akimshilik411 from "../.././../images/about-school/akimshilik/akimshilik411.JPG";
import akimshilik5 from "../.././../images/about-school/akimshilik/akimshilik5.png";
import akimshilik6 from "../.././../images/about-school/akimshilik/akimshilik6.png";
import akimshilik7 from "../.././../images/about-school/akimshilik/administration7.png";
import akimshilik9 from "../.././../images/about-school/akimshilik/administration9.PNG";
import akimshilik10 from "../.././../images/about-school/akimshilik/administration10.PNG";
import akimshilik11 from "../.././../images/about-school/akimshilik/administration11.JPG";
import akimshilikM from "../.././../images/about-school/akimshilik/akimshilikM.PNG";
import akimshilikN from "../.././../images/about-school/akimshilik/akimshilikN.PNG";
import Tab from "./Tab";

function Administration() {
    const { dictionary } = useContext(LanguageContext);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                },
            },
        ],
    };

    return (
        <Container id="administration">
            <Wrapper>
                <Texts>
                    <Title>{dictionary.administration.title}</Title>
                    <Subtitle>{dictionary.administration.subtitle}</Subtitle>
                </Texts>
                <Top>
                    <ImgContainer>
                        <Img src={akimshilik1} />
                    </ImgContainer>
                    <InfoContainer>
                        <InfoContainerTitle>Байрак Абдуллах</InfoContainerTitle>
                        <InfoContainerSubtitle>Директор</InfoContainerSubtitle>
                        <Tab />
                    </InfoContainer>
                </Top>
                <Bottom>
                    <StyledSlider {...settings}>
                    <ItemContainer>
                            <Item>
                                <ItemImgContainer>
                                    <ItemImg src={akimshilik7} />
                                </ItemImgContainer>
                                <ItemTitle>
                                    <b>Саулеева Эльмира</b>
                                </ItemTitle>
                                <ItemInfo>
                                Директордың ғылыми-әдістемелік жұмыстар бойынша орынбасары
                                </ItemInfo>
                            </Item>
                        </ItemContainer>
                        <ItemContainer>
                            <Item>
                                <ItemImgContainer>
                                    <ItemImg src={akimshilikM} />
                                </ItemImgContainer>
                                <ItemTitle>
                                    <b>Мевлют Аяз</b>
                                </ItemTitle>
                                <ItemInfo>
                                Директордың оқу ісі жөніндегі орынбасары
                                </ItemInfo>
                            </Item>
                        </ItemContainer>
                        <ItemContainer>
                            <Item>
                                <ItemImgContainer>
                                    <ItemImg src={akimshilik3} />
                                </ItemImgContainer>
                                <ItemTitle>
                                    <b>Жумекин Кайрат</b>
                                </ItemTitle>
                                <ItemInfo>
                                    Директордың оқу ісі жөніндегі орынбасары
                                </ItemInfo>
                            </Item>
                        </ItemContainer>
                        <ItemContainer>
                            <Item>
                                <ItemImgContainer>
                                    <ItemImg src={akimshilik5} />
                                </ItemImgContainer>
                                <ItemTitle>
                                    <b>Өтемұратова Кәмшат</b>
                                </ItemTitle>
                                <ItemInfo>
                                    Директордың оқу ісі жөніндегі орынбасары
                                </ItemInfo>
                            </Item>
                        </ItemContainer>
                        <ItemContainer>
                            <Item>
                                <ItemImgContainer>
                                    <ItemImg src={akimshilik10} />
                                </ItemImgContainer>
                                <ItemTitle>
                                    <b>Эркан Саит</b>
                                </ItemTitle>
                                <ItemInfo>
                                    Директордың оқу ісі жөніндегі орынбасары
                                </ItemInfo>
                            </Item>
                        </ItemContainer>
                        <ItemContainer>
                            <Item>
                                <ItemImgContainer>
                                    <ItemImg src={akimshilik4} />
                                </ItemImgContainer>
                                <ItemTitle>
                                    <b>Муратов Асхат Айтпаевич</b>
                                </ItemTitle>
                                <ItemInfo>
                                    Директордың қоғам мен байланыс жөніндегі
                                    орынбасары
                                </ItemInfo>
                            </Item>
                        </ItemContainer>
                      
                        <ItemContainer>
                            <Item>
                                <ItemImgContainer>
                                    <ItemImg src={akimshilik11} />
                                </ItemImgContainer>
                                <ItemTitle>
                                    <b>Эрылмаз Бейза</b>
                                </ItemTitle>
                                <ItemInfo>
                                Директордың тәрбие ісі жөніндегі орынбасары
                                </ItemInfo>
                            </Item>
                        </ItemContainer>
                      
                        <ItemContainer>
                            <Item>
                                <ItemImgContainer>
                                    <ItemImg src={akimshilik411} />
                                </ItemImgContainer>
                                <ItemTitle>
                                    <b>Тойбазар Бақытнұр</b>
                                </ItemTitle>
                                <ItemInfo>
                                    Директордың тәрбие ісі жөніндегі орынбасары
                                </ItemInfo>
                            </Item>
                        </ItemContainer>
                        <ItemContainer>
                            <Item>
                                <ItemImgContainer>
                                    <ItemImg src={akimshilikN} />
                                </ItemImgContainer>
                                <ItemTitle>
                                    <b>Нурие Йорукоглу</b>
                                </ItemTitle>
                                <ItemInfo>
                                    CIS coordinator
                                </ItemInfo>
                            </Item>
                        </ItemContainer>
                        <ItemContainer>
                            <Item>
                                <ItemImgContainer>
                                    <ItemImg src={akimshilik6} />
                                </ItemImgContainer>
                                <ItemTitle>
                                    <b>Альбекова Асем Ерсаиновна</b>
                                </ItemTitle>
                                <ItemInfo>Педагог-ұйымдастырушы</ItemInfo>
                            </Item>
                        </ItemContainer>
                        <ItemContainer>
                            <Item>
                                <ItemImgContainer>
                                    <ItemImg src={akimshilik9} />
                                </ItemImgContainer>
                                <ItemTitle>
                                    <b>Башембаева Гулшара Менаждиновна</b>
                                </ItemTitle>
                                <ItemInfo>
                                Педагог-ұйымдастырушы
                                </ItemInfo>
                            </Item>
                        </ItemContainer>
                       
                    </StyledSlider>
                </Bottom>
            </Wrapper>
        </Container>
    );
}

export default Administration;
