import React, {useContext} from 'react';
import {
    Container,
    Wrapper,
    InfoContainer1,
    InfoContainer2,
    ImgContainer1,
    ImgContainer2,
    Title,
    Subtitle,
    Img1,
    Img3,
    Top,
    Bottom,
} from "./missionComponents";
import mission1 from "../../././../images/about-school/mission1.JPG";
import mission2 from "../.././../images/about-school/mission2.JPG";
import { LanguageContext } from "../../../containers/Language";

function Mission() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="#mission">
            <Wrapper>
                <Top>
                    <InfoContainer1>
                        <Title>{dictionary.mission.title1}</Title>
                        <Subtitle>
                        {dictionary.mission.subtitle1} <b>{dictionary.mission.subtitle1bold}</b>{dictionary.mission.subtitle1rest}
                        </Subtitle>
                    </InfoContainer1>
                    <ImgContainer1>
                        <Img1 src={mission1} />
                    </ImgContainer1>
                </Top>
                <Bottom>
                    <ImgContainer2>
                        <Img3 src={mission2} />
                    </ImgContainer2>
                    <InfoContainer2>
                        <Title>{dictionary.mission.title2}</Title>
                        <Subtitle>
                        {dictionary.mission.subtitle2} <b>{dictionary.mission.subtitle2bold}</b>{dictionary.mission.subtitle2rest}
                        </Subtitle>
                    </InfoContainer2>
                </Bottom>
            </Wrapper>
        </Container>
    );
}

export default Mission;
