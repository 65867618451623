import React, { useContext } from 'react';
import {
    Container,
    Wrapper,
    Text,
    Content,
    Title,
    ActivityInfo,
    ActivityItemSubtitle,
    ActivityVisual,
    ActivityImg1
} from "./historyComponents";
import { LanguageContext } from "../../../containers/Language";
import history1 from "../.././../images/about-school/IMG_1609.jpg";

function History() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="history">
            <Wrapper>
                <Text>
                    <Title>{dictionary.history.title}</Title>
                </Text>
                <Content>
                <ActivityVisual>
                    <ActivityImg1 src={history1} />
                </ActivityVisual>
                <ActivityInfo>
                    <ActivityItemSubtitle>
                        {dictionary.history.activityItemSubtitle1}
                    </ActivityItemSubtitle>
                    <ActivityItemSubtitle>
                    <i>{dictionary.history.activityItemSubtitle2}</i>
                           {dictionary.history.activityItemSubtitle21}
                    </ActivityItemSubtitle>
                    <ActivityItemSubtitle>
                    <i> {dictionary.history.activityItemSubtitle3}</i>
                            {dictionary.history.activityItemSubtitle31}
                    </ActivityItemSubtitle>
                    <ActivityItemSubtitle>
                    <i>{dictionary.history.activityItemSubtitle4}</i>
                            {dictionary.history.activityItemSubtitle41}
                    </ActivityItemSubtitle>
                    <ActivityItemSubtitle>
                    <i> {dictionary.history.activityItemSubtitle5}</i>
                            {dictionary.history.activityItemSubtitle51}
                    </ActivityItemSubtitle>
                    <ActivityItemSubtitle>
                    <i> {dictionary.history.activityItemSubtitle6}</i>
                            {dictionary.history.activityItemSubtitle61}
                    </ActivityItemSubtitle>
                    <ActivityItemSubtitle>
                    <i> {dictionary.history.activityItemSubtitle7} </i>
                            {dictionary.history.activityItemSubtitle71}
                    </ActivityItemSubtitle>
                    <ActivityItemSubtitle>
                            <b>{dictionary.history.activityItemSubtitle8}</b>
                            
                    </ActivityItemSubtitle>
                    <ActivityItemSubtitle>
                    <i> {dictionary.history.activityItemSubtitle9}</i>
                            {dictionary.history.activityItemSubtitle91}
                    </ActivityItemSubtitle>
                    <ActivityItemSubtitle>
                    <i> {dictionary.history.activityItemSubtitle10}</i>
                            {dictionary.history.activityItemSubtitle101}
                    </ActivityItemSubtitle>
                    <ActivityItemSubtitle>
                    <i> {dictionary.history.activityItemSubtitle11}</i>
                            {dictionary.history.activityItemSubtitle111}
                        </ActivityItemSubtitle>
                        <ActivityItemSubtitle>
                        <i> {dictionary.history.activityItemSubtitle12}</i>
                            {dictionary.history.activityItemSubtitle121}
                    </ActivityItemSubtitle>
                    <ActivityItemSubtitle>
                    <i>{dictionary.history.activityItemSubtitle13}</i>
                            {dictionary.history.activityItemSubtitle131}
                        </ActivityItemSubtitle>
                        <ActivityItemSubtitle>
                        <i>{dictionary.history.activityItemSubtitle14}</i>
                            {dictionary.history.activityItemSubtitle141}
                    </ActivityItemSubtitle>
                    </ActivityInfo>
                    </Content>
            </Wrapper>
        </Container>
    );
}

export default History;
