import React, {useContext} from 'react';
import {
    Container,
    Wrapper,
    ImgContainer,
    Title,
    Img,
} from "./timetableComponents";
import { LanguageContext } from "../../../containers/Language";
import timetable from "../.././../images/education-process/timetable.jpg";
import timetablephoto from "../.././../images/education-process/timetable.jpeg";

function Timetable() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="timetable">
            <Wrapper>
                <ImgContainer>
                <Title>{dictionary.timetable.title}</Title>
                    <Img src={timetable} />
                </ImgContainer>
                <ImgContainer>
                    <Img src={timetablephoto} />
                </ImgContainer>
            </Wrapper>
        </Container>
    );
}

export default Timetable;
