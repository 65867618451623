import React, {useContext} from 'react';
import {
    Container,
    Wrapper,
    InfoContainer,
    ImgContainer,
    Title,
    Subtitle,
    Img,
} from "./priceComponents";
import price from "../../../images/education-cost/price.jpg";
import { LanguageContext } from "../../../containers/Language";

function Price() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="price">
            <Wrapper>
                    <ImgContainer>
                        <Img src={price} />
                    </ImgContainer>
                    <InfoContainer>
                        <Title>{dictionary.price.title}</Title>
                        <Subtitle>
                        {dictionary.price.subtitle}
                        </Subtitle>
                    </InfoContainer>
            </Wrapper>
        </Container>
    );
}

export default Price;
