import styled from "styled-components";
import { FormGroup, FormControl, Button, Input } from "@mui/material";

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;

export const Wrapper = styled.div`
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    margin: 90px 0;
    padding: 0 20px;

    @media only screen and (max-width: 48em) {
        width: 100%;
        margin: 70px 0;
    }

    @media only screen and (max-width: 30em) {
        margin: 60px 0;
        padding: 0 15px;
    }
`;

export const Text = styled.div``;

export const Title = styled.h1`
    text-transform: uppercase;
    text-align: center;
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 20px;
    width: 600px;
    color: #1a1a4d;

    @media only screen and (max-width: 67em) {
        font-size: 3rem;
        width: 100%;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const Subtitle = styled.p`
    width: 600px;
    font-size: 1.3rem;
    line-height: 1.7;
    color: gray;
    margin-bottom: 30px;

    @media only screen and (max-width: 67em) {
        font-size: 1rem;
        width: 100%;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1.1rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
        width: 300px;
    }
`;

export const Content = styled.div`
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 48em) {
        justify-content: center;
    }
`;

export const ImgContainer = styled.div`
    width: 47%;

    @media only screen and (max-width: 67em) {
        width: 43%;
    }

    @media only screen and (max-width: 48em) {
        display: none;
    }
`;

export const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const InfoContainer = styled.div`
    width: calc(53% - 55px);

    @media only screen and (max-width: 67em) {
        width: calc(57% - 45px);
    }

    @media only screen and (max-width: 48em) {
        width: 90%;
    }
`;

export const StyledForm = styled.form``;

export const StyledFormGroup = styled(FormGroup)`
    gap: 20px;
`;

export const StyledFormControl = styled(FormControl)`
    background: #f1f1f1;
`;

export const StyledButton = styled(Button)`
    width: 25%;
    margin-top: 15px;
    align-self: center;

    @media only screen and (max-width: 48em) {
        width: 35%;
    }
`;

export const StyledInput = styled(Input)`
    padding: 0 20px;
    size: 50;
`;

export const Confirm = styled.div`
    display: flex;
    align-items: center;
`;

export const ConfirmText = styled.div`
    display: flex;
    color: gray;
    font-size: 0.9rem;
`;
