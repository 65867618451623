import React, { useContext } from 'react';
import {
    Container,
    Wrapper,
    MapContainer,
    InfoContainer,
    List,
    ListItemInfo,
    ListItemWrapper,
    IconContainer,
    Title,
    Script,
    SocialMedia,
    SocialMediaContainer,
    Link,
    Top,
    Bottom
} from "./contactComponents";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
    faAddressBook,
    faEnvelope,
    faLocationDot,
    faSchool,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LanguageContext } from "../../../containers/Language";

function Info() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container>
            <Wrapper>
                <Top>
                    <SocialMedia>
                        <Title>
                            {dictionary.contacts.title}
                        </Title>
                        <SocialMediaContainer>
                            <Link href="https://www.instagram.com/kemelbilim.kz/">
                                <img
                                    src="https://img.icons8.com/color/48/null/instagram-new--v1.png"
                                    alt=""
                                    style={{ width: "55px" }}
                                />
                            </Link>
                            <Link
                                href="https://m.facebook.com/kemelbilim.mektebi?eav=AfZNugtVSSNnu7o162hOf9avHLvHXEexrCanDPvu-bbnzxMN94PKHXgKakL1qRrzn08&fref=nf&paipv=0"
                            >
                                <img
                                    src="https://img.icons8.com/color/48/null/facebook-new.png"
                                    alt=""
                                    style={{ width: "55px" }}
                                />
                            </Link>
                            <Link href="http://wa.me/77075552424/">
                                <img
                                    src="https://img.icons8.com/color/48/null/whatsapp--v1.png"
                                    alt=""
                                    style={{ width: "55px" }}
                                />
                            </Link>
                            <Link href="www.tiktok.com/@kemelbilim.kz">
                                <img
                                    src="https://img.icons8.com/color/48/null/tiktok--v1.png"
                                    alt=""
                                    style={{ width: "55px" }}
                                />
                            </Link>
                            <Link href="https://t.me/kemelbilim">
                                <img
                                    src="https://img.icons8.com/color/48/null/telegram-app--v1.png"
                                    alt=""
                                    style={{ width: "55px" }}
                                />
                            </Link>
                            <Link href="https://www.youtube.com/channel/UCASJic-k_E6XIV1yRXEOePA">
                                <img
                                    src="https://img.icons8.com/color/48/null/youtube-play.png"
                                    alt=""
                                    style={{ width: "55px" }}
                                />
                            </Link>
                        </SocialMediaContainer>
                    </SocialMedia>
                    </Top>
                <Bottom>
                    <MapContainer>
                        <Script
                            src="https://yandex.ru/map-widget/v1/?um=constructor%3A8babb0e98a74ba894a9065dbf53bf56098f449126232e203faf18f4f91bd4f22&amp;source=constructor"
                            width="100%"
                            height="455"
                            frameborder="0"
                        ></Script>
                    </MapContainer>
                    <InfoContainer>
                        <List>
                            <ListItemWrapper>
                                <ListItemInfo>
                                    <IconContainer>
                                        <FontAwesomeIcon
                                            icon={faSchool}
                                        />
                                    </IconContainer>
                                    <b>{dictionary.contacts.listItemInfo0}</b>
                                </ListItemInfo>
                                <ListItemInfo>
                                {dictionary.contacts.listItemInfo1}
                                </ListItemInfo>
                            </ListItemWrapper>
                            <ListItemWrapper>
                                <ListItemInfo>
                                    <IconContainer>
                                        <FontAwesomeIcon
                                            icon={faLocationDot}
                                        />
                                    </IconContainer>
                                    <b>{dictionary.info.listItemInfo1}</b>
                                </ListItemInfo>
                                <ListItemInfo>
                                {dictionary.info.listItemInfo11}
                                </ListItemInfo>
                            </ListItemWrapper>
                            <ListItemWrapper>
                                <ListItemInfo>
                                    <IconContainer>
                                        <FontAwesomeIcon icon={faClock} />
                                    </IconContainer>
                                    <b>{dictionary.info.listItemInfo2}</b>
                                </ListItemInfo>
                                <ListItemInfo>
                                {dictionary.contacts.listItemInfo22}
                                </ListItemInfo>
                            </ListItemWrapper>
                            <ListItemWrapper>
                                <ListItemInfo>
                                    <IconContainer>
                                        <FontAwesomeIcon icon={faAddressBook} />
                                    </IconContainer>
                                    <b>{dictionary.info.listItemInfo3}</b>
                                </ListItemInfo>
                                <ListItemInfo>
                                    8 (707) 555 24 24
                                </ListItemInfo>
                                <ListItemInfo>
                                   admission@kemelbilim.edu.kz
                                </ListItemInfo>
                            </ListItemWrapper>

                        </List>
                        <List>
                            <ListItemWrapper>
                                <ListItemInfo>
                                    <IconContainer>
                                        <FontAwesomeIcon
                                            icon={faEnvelope}
                                        />
                                    </IconContainer>
                                    <b>{dictionary.info.listItemInfo4}</b>
                                </ListItemInfo>
                                <ListItemInfo>
                                    info@kemelbilim.edu.kz
                                </ListItemInfo>
                            </ListItemWrapper>
                            <ListItemWrapper>
                                <ListItemInfo>
                                    <IconContainer>
                                        <FontAwesomeIcon
                                             icon={faEnvelope}
                                        />
                                    </IconContainer>
                                    <b>{dictionary.contacts.listItemInfo2}</b>
                                </ListItemInfo>
                                <ListItemInfo>
                                    hr@kemelbilim.edu.kz
                                </ListItemInfo>
                            </ListItemWrapper>
                            <ListItemWrapper>
                                <ListItemInfo>
                                    <IconContainer>
                                        <FontAwesomeIcon
                                            icon={faEnvelope}
                                        />
                                    </IconContainer>
                                    <b>{dictionary.contacts.listItemInfo3}</b>
                                </ListItemInfo>
                                <ListItemInfo>
                               accountant@kemelbilim.edu.kz
                                </ListItemInfo>
                            </ListItemWrapper>
                            <ListItemWrapper>
                                <ListItemInfo>
                                    <IconContainer>
                                        <FontAwesomeIcon
                                            icon={faEnvelope}
                                        />
                                    </IconContainer>
                                    <b>Ресепшн:</b>
                                </ListItemInfo>
                                <ListItemInfo>
                            reception@kemelbilim.edu.kz
                                </ListItemInfo>
                            </ListItemWrapper>
                            <ListItemWrapper>
                                <ListItemInfo>
                                    <IconContainer>
                                        <FontAwesomeIcon
                                            icon={faEnvelope}
                                        />
                                    </IconContainer>
                                    <b>Директор:</b>
                                </ListItemInfo>
                                <ListItemInfo>
                                    principal@kemelbilim.edu.kz
                                </ListItemInfo>
                            </ListItemWrapper>
                        </List>
                    </InfoContainer>
                </Bottom>
            </Wrapper>
        </Container>
    );
}

export default Info;
