import React from "react";
import Banner from "../components/contacts/banner/Banner";
import Header from "../components/header/Header";
import Alumni from "../components/contacts/alumni/Alumni";
import Cis from "../components/contacts/cis/Cis";
import StudentSuccess from "../components/contacts/student-success/StudentSuccess";
import TeacherSuccess from "../components/contacts/teacher-success/TeacherSuccess";
import SchoolSuccess from "../components/contacts/school-success/SchoolSuccess";
import Footer from "../components/footer/Footer";

function Success() {
    return (
        <div>
            <Header />
            <Banner />
            <StudentSuccess />
            <TeacherSuccess />
            <SchoolSuccess />
            <Alumni />
            <Cis />
            <Footer />
        </div>
    );
}

export default Success;
