import React, {useContext} from 'react';
import {
    Container,
    Wrapper,
    MenuList,
    MenuItem,
    MenuLink,
} from "./navigationComponents";
import { LanguageContext } from "../../../containers/Language";

function Navigation() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container>
            <Wrapper>
                <MenuList>
                <MenuItem>
                        <MenuLink href="#values">{dictionary.header.values}</MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink href="#sections">{dictionary.header.sections}</MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink href="#book">{dictionary.header.book}</MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink href="#parents">{dictionary.header.parents}</MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink href="#activities" lastChild>{dictionary.header.activities}</MenuLink>
                    </MenuItem>
                </MenuList>
            </Wrapper>
        </Container>
    );
}

export default Navigation;
