import React, { useContext } from "react";
import {
    Container,
    Wrapper,
    InfoContainer,
    ImgContainer,
    Title,
    Subtitle,
    Img,
    Top,
    Bottom,
    BottomImgContainer,
    BottomImg,
    StyledSlider
} from "./activityComponents";
import activity2 from "../.././../images/educational-work/activity/IMG_4254.jpeg";
import activity3 from "../.././../images/educational-work/activity/activity3.jpg";
import activity4 from "../.././../images/educational-work/activity/activity4.jpeg";
import activity11 from "../.././../images/educational-work/activity/activity11.jpeg";
import activity13 from "../.././../images/educational-work/activity/activity13.jpeg";
import activity14 from "../.././../images/educational-work/activity/activity14.jpg";
import activity15 from "../.././../images/educational-work/activity/activity15.jpeg";
import activity16 from "../.././../images/educational-work/activity/activity16.jpeg";
import activity18 from "../.././../images/educational-work/activity/IMG_5295.jpeg";
import activity19 from "../.././../images/educational-work/activity/IMG_5304.jpeg";
import activity21 from "../.././../images/educational-work/activity/IMG_5325.jpeg";
import { LanguageContext } from "../../../containers/Language";

function Activities() {
    const { dictionary } = useContext(LanguageContext);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
        ],
    };

    return (
        <Container id="activities">
            <Wrapper>
                <Top>
                    <InfoContainer>
                        <Title>{dictionary.activities.title}</Title>
                        <Subtitle>  {dictionary.activities.subtitle} <b>{dictionary.activities.subtitlebold}</b>{dictionary.activities.subtitlerest}</Subtitle>
                    </InfoContainer>
                    <ImgContainer>
                        <Img src={activity11} />
                    </ImgContainer>
                </Top>
                <Bottom>
                    <StyledSlider {...settings}>
                        <BottomImgContainer>
                            <BottomImg src={activity3} />
                        </BottomImgContainer>

                        <BottomImgContainer>
                            <BottomImg src={activity15} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={activity2} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={activity16} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={activity14} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={activity19} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={activity13} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={activity4} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={activity21} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={activity18} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={activity21} />
                        </BottomImgContainer>
                    </StyledSlider>
                </Bottom>
            </Wrapper>
        </Container>
    );
}

export default Activities;
