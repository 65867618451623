import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from "./pages/Home";
import AboutSchool from "./pages/AboutSchool";
import Admission from "./pages/Admission";
import EducationProcess from "./pages/EducationProcess";
import EducationalWork from "./pages/EducationalWork";
import EducationCost from "./pages/EducationCost";
import Success from "./pages/Success";
import Registration from "./pages/Registration";
import { LanguageProvider } from "./containers/Language";
import NewsPage from "./pages/NewsPage";
import CareersPage from "./pages/CareersPage";
import SchoolCertificationPage from "./pages/SchoolCertificationPage";

function App() {
    useEffect(() => {
        const urlHash = window.location.hash;
        if (urlHash.length) {
            const element = document.getElementById(urlHash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    }, []);

    return (
        <LanguageProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about-school" element={<AboutSchool />} />
                    <Route path="/education-process" element={<EducationProcess />} />
                    <Route path="/educational-work" element={<EducationalWork />} />
                    <Route path="/admission" element={<Admission />} />
                    <Route path="/education-cost" element={<EducationCost />} />
                    <Route path="/success" element={<Success />} />
                    <Route path="/registration" element={<Registration />} />
                    <Route path="/news-page" element={<NewsPage />} />
                    <Route path="/careers-page" element={<CareersPage />} />
                    <Route path="/school-certification" element={<SchoolCertificationPage />} />
                </Routes>
            </BrowserRouter>
        </LanguageProvider>
    );
}

export default App;
