import React, { useContext } from "react";
import {
    Container,
    Item,
    ItemContainer,
    Title,
    Wrapper,
    Texts,
    ItemImg,
    Subtitle,
    ItemTitle,
    StyledSlider
} from "./valuesComponents";
import value from "./../../../images/educational-work/value.png";
import { LanguageContext } from "../../../containers/Language";

function Values() {
    const { dictionary } = useContext(LanguageContext);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                },
            },
        ],
    };

    return (
        <Container id="values">
            <Wrapper>
                <Texts>
                    <Title>{dictionary.value.title}</Title>
                    <Subtitle>{dictionary.value.subtitle}</Subtitle>
                </Texts>
                <StyledSlider {...settings}>
                    <ItemContainer>
                        <Item>
                            <ItemImg src={value} />
                            <ItemTitle>Этика</ItemTitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImg src={value} />
                            <ItemTitle>Ынтымақтастық</ItemTitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImg src={value} />
                            <ItemTitle>Құрмет</ItemTitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImg src={value} />
                            <ItemTitle>Отансүйгіштік</ItemTitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImg src={value} />
                            <ItemTitle>Адалдық</ItemTitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImg src={value} />
                            <ItemTitle>Жауапкершілік</ItemTitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImg src={value} />
                            <ItemTitle>Ұлттық құндылық</ItemTitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImg src={value} />
                            <ItemTitle>Тазалық</ItemTitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImg src={value} />
                            <ItemTitle>Батылдық</ItemTitle>
                        </Item>
                    </ItemContainer>
                </StyledSlider>
            </Wrapper>
        </Container>
    );
}

export default Values;
