import React, { useContext } from "react";
import {
    Container,
    Wrapper,
    Bottom,
    BottomTitle,
    BottomInfo,
    BottomItem,
    BottomItems,
    Title
} from "./stepComponents";
import { LanguageContext } from "../../../containers/Language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

function Step() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container>
            <Wrapper>
            <Title>{dictionary.steps.title}</Title>
                <Bottom>
                    <BottomItems>
                        <BottomItem>
                            <BottomTitle>
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    style={{ marginRight: "10px" }}
                                />
                                <b> {dictionary.steps.bottomTitle1}</b>
                            </BottomTitle>
                            <BottomInfo>
                            {dictionary.steps.bottomInfo1}
                            </BottomInfo>
                            <BottomInfo>
                            {dictionary.steps.bottomInfo2}
                            </BottomInfo>
                            <BottomInfo>
                            {dictionary.steps.bottomInfo3}
                            </BottomInfo>
                        </BottomItem>
                        <BottomItem>
                            <BottomTitle>
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    style={{ marginRight: "10px" }}
                                />
                                <b>{dictionary.steps.bottomTitle2}</b>
                            </BottomTitle>
                            <BottomInfo>
                            {dictionary.steps.bottomInfo4}
                            </BottomInfo>
                            <BottomInfo>
                            {dictionary.steps.bottomInfo5}
                            </BottomInfo>
                        </BottomItem>
                        <BottomItem>
                            <BottomTitle>
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    style={{ marginRight: "10px" }}
                                />
                                <b>{dictionary.steps.bottomTitle3}</b>
                            </BottomTitle>
                            <BottomInfo>
                            {dictionary.steps.bottomInfo6}
                            </BottomInfo>
                            <BottomInfo>  {dictionary.steps.bottomInfo7}</BottomInfo>
                            <BottomInfo>  {dictionary.steps.bottomInfo8}</BottomInfo>
                            <BottomInfo>
                            {dictionary.steps.bottomInfo9}
                            </BottomInfo>
                            <BottomInfo>
                            {dictionary.steps.bottomInfo10}
                            </BottomInfo>
                            <BottomInfo>
                            {dictionary.steps.bottomInfo11}
                            </BottomInfo>
                            <BottomInfo>
                            {dictionary.steps.bottomInfo12}
                            </BottomInfo>
                        </BottomItem>
                    </BottomItems>
                </Bottom>
            </Wrapper>
        </Container>
    );
}

export default Step;
