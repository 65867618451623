import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin: 50px 0;

    @media only screen and (max-width: 30em) {
        margin-bottom: 10px;
    }
`;

export const Top = styled.div`
    display: flex;
    margin-bottom: 50px;

    @media only screen and (max-width: 30em) {
        flex-direction: column;
    }
`;

export const Bottom = styled.div`
    display: flex;

    @media only screen and (max-width: 30em) {
        flex-direction: column-reverse;
    }
`;

export const InfoContainer1 = styled.div`
    display: flex;
    flex-direction: column;
    
    width: calc(50% - 1px);
    padding-right: 60px;

    @media only screen and (max-width: 67em) {
        width: calc(50% - 1px);
        padding-top: 0;
        padding-right: 30px;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        padding-right: 0;
        margin-bottom: 30px;
    }
`;

export const ImgContainer1 = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 67em) {
        width: 50%;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
    }
`;

export const Img1 = styled.img`
    width: 100%;
    height: 448px;
    object-fit: cover;
    margin-bottom: 17px;

    @media only screen and (max-width: 67em) {
        height: 300px;
    }

    @media only screen and (max-width: 48em) {
        height: 220px;
    }

    @media only screen and (max-width: 30em) {
        margin-bottom: 10px;
    }
`;

export const InfoContainer2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(50% - 1px);
    padding-left: 50px;

    @media only screen and (max-width: 67em) {
        padding-right: 0;
        padding-left: 40px;
    }

    @media only screen and (max-width: 48em) {
        width: calc(50% - 1x);
        padding-left: 50px;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        margin-bottom: 20px;
        padding-left: 0;
    }
`;

export const ImgContainer2 = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 67em) {
    }

    @media only screen and (max-width: 48em) {
        width: 50%;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        height: 250px;
    }
`;

export const Img3 = styled.img`
    width: 100%;
    height: 448px;
    object-fit: cover;

    @media only screen and (max-width: 30em) {
        height: 100%;
        object-fit: cover;
    }
`;

export const Title = styled.h1`
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 20px;
    color: #1a1a4d;

    @media only screen and (max-width: 67em) {
        font-size: 3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const Subtitle = styled.p`
    font-size: 1.5rem;
    line-height: 1.7;
    color: gray;
    padding-bottom: 10px;

    @media only screen and (max-width: 67em) {
        font-size: 1.3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1.1rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;
