import React, { useContext } from "react";
import {
    Container,
    Wrapper,
    MenuList,
    MenuItem,
    MenuLink,
} from "./navigationComponents";
import { LanguageContext } from "../../../containers/Language";

function Navigation() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container>
            <Wrapper>
                <MenuList>
                    <MenuItem>
                        <MenuLink href="#classes">
                            {dictionary.header.classes}
                        </MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink href="#timetable">
                            {dictionary.header.timetable}
                        </MenuLink>
                    </MenuItem>

                    <MenuItem>
                        <MenuLink href="#assessment">
                            {dictionary.header.grades}
                        </MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink href="#edupage">
                            {dictionary.header.edupage}
                        </MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink href="#pbl">
                            {dictionary.header.pbl}
                        </MenuLink>
                    </MenuItem>

                    <MenuItem>
                        <MenuLink href="#competition" >
                            {dictionary.header.competition}
                        </MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink href="#competition" lastChild>
                        {dictionary.header.english}
                        </MenuLink>
                    </MenuItem>
                </MenuList>
            </Wrapper>
        </Container>
    );
}

export default Navigation;
