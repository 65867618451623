import styled from "styled-components";

export const Container = styled.div`
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1280px;
    display: flex;
    padding: 90px 20px;

    @media only screen and (max-width: 67em) {
        display: flex;
        flex-direction: column;
    }

    @media only screen and (max-width: 30em) {
        padding: 50px 0;
        margin: 0 15px;
    }
`;

export const Left = styled.div`
    display: flex;
    flex: 2;
    border: 1px solid lightgray;

    @media only screen and (max-width: 30em) {
        display: flex;
        flex-flow: column-reverse;
        margin: 0;
    }
`;

export const MapContainer = styled.div`
    width: 57%;

    @media only screen and (max-width: 48em) {
        width: 50%;
    }

    @media only screen and (max-width: 30em) {
        height: 320px;
        width: 100%;
    }
`;

export const Script = styled.iframe`
    border: none;
    height: 100%;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 43%;
    background: #f1f1f1;
    position: relative;
    font-size: 1rem;

    @media only screen and (max-width: 67em) {
    }

    @media only screen and (max-width: 48em) {
        width: 50%;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        padding: 20px 25px 20px;
    }
`;

export const List = styled.ul`
    list-style: none;
    padding: 0;
    flex: 1;
`;

export const ListItemInfo = styled.li`
    padding-bottom: 7px;
    display: flex;
    color: black;
    line-height: 1.2;
`;

export const ListItemWrapper = styled.div`
    margin-bottom: 20px;
`;

export const IconContainer = styled.div`
    display: flex;
    gap: 7px;
    color: #1a1a4d;
    padding-right: 5px;
`;

export const Right = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    margin-left: 25px;

    @media only screen and (max-width: 67em) {
        display: flex;
        flex-direction: row;
        margin-top: 50px;
        margin-left: 0;
        gap: 25px;
    }

    @media only screen and (max-width: 48em) {
        margin-top: 30px;
        gap: 10px;
    }

    @media only screen and (max-width: 30em) {
        display: flex;
        flex-direction: column;
    }
`;

export const RightContainerTop = styled.div`
    display: flex;
    color: white;
    background-color:#10a74b;
    padding: 50px 30px;

    @media only screen and (max-width: 67em) {
        display: flex;
        flex: 1;
        margin-bottom: 0;
        padding: 30px 20px;
    }

    @media only screen and (max-width: 30em) {
        padding: 20px 10px;
    }
`;

export const RightContainerBottom = styled.div`
    display: flex;
    color: white;
    background-color: #1a1a4d;
    padding: 50px 30px;

    @media only screen and (max-width: 67em) {
        display: flex;
        flex: 1;
        padding: 30px 20px;
    }

    @media only screen and (max-width: 30em) {
        padding: 20px 10px;
    }
`;

export const RightContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: relative;
`;

export const Text = styled.div`
    flex: 2;
    flex-direction: column;
    padding: 0;

    @media only screen and (max-width: 48em) {
        padding: 0 10px;
    }
`;

export const Title = styled.h2`
    padding: 0 0 20px;

    @media only screen and (max-width: 22em) {
        font-size: 1.2rem;
    }
`;

export const Subtitle = styled.p`
    text-decoration: underline;

    @media only screen and (max-width: 22em) {
        font-size: 1.1rem;
    }
`;

export const Link = styled.a`
    text-decoration: none;
    cursor: pointer;
    color: inherit;
`;
