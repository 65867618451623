import React from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Banner from "../components/education-process/banner/Banner";
import Navigation from "../components/education-process/navigation/Navigation";
import Classes from "../components/education-process/classes/Classes";
import Timetable from "../components/education-process/timetable/Timetable";
import Edupage from "../components/education-process/edupage/Edupage";
import Assessment from "../components/education-process/assessment/Assessment";
import Help from "../components/education-process/help/Help";
import Pbl from "../components/education-process/pbl/Pbl";
import Competition from "../components/education-process/competition/Competition";
import English from "../components/education-process/english/English";


function EducationProcess() {
    return (
        <div>
            <Header />
            <Banner />
            <Navigation />
            <Classes />
            <Timetable />
            <Assessment />
            <Edupage />
            <Help />
            <Pbl />
            <Competition />
            <English />
            <Footer />
        </div>
    );
}

export default EducationProcess;
