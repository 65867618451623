import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;

export const Wrapper = styled.div`
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    margin: 30px 0;

    @media only screen and (max-width: 67em) {
        margin: 90px 0 60px;
    }

    @media only screen and (max-width: 48em) {
        margin: 70px 0 40px;
    }

    @media only screen and (max-width: 30em) {
        margin: 50px 0 0;
        padding: 0 15px;
    }
`;

export const Top = styled.div`
    display: flex;
    margin-bottom: 50px;

    @media only screen and (max-width: 30em) {
        flex-direction: column-reverse;
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(54% - 1px);
    padding-left: 80px;

    @media only screen and (max-width: 67em) {
        padding-right: 0;
        padding-left: 40px;
    }

    @media only screen and (max-width: 48em) {
        width: calc(60% - 1x);
        padding-left: 50px;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        margin-bottom: 20px;
        padding-left: 0;
    }
`;

export const Title = styled.h1`
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 20px;
    color: #1a1a4d;

    @media only screen and (max-width: 67em) {
        font-size: 3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const Subtitle = styled.p`
    font-size: 1.5rem;
    line-height: 1.7;
    color: gray;
    padding-bottom: 10px;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 67em) {
        font-size: 1.3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1.1rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;

export const ImgContainer = styled.div`
    width: 46%;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 67em) {
    }

    @media only screen and (max-width: 48em) {
        width: 40%;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        height: 450px;
    }
`;

export const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media only screen and (max-width: 30em) {
        height: 100%;
       
    }
`;



export const RegistrationButton = styled.button`
    border: 1px solid #10a74b;
    padding: 12px 20px;
    cursor: pointer;
    color: #10a74b;
    background-color: white;
    font-weight: bold;
    width: 30%;
    margin-top: 30px;

    &:hover {
        color: white;
        padding: 11px 19px;
        background-color: #10a74b;
        border: 1px solid #10a74b;
    }

    @media only screen and (max-width: 67em) {
        width: 30%;
    }

    @media only screen and (max-width: 48em) {
        width: 100%;
        margin-top: 20px;
    }
`;

export const Link = styled.a`
    text-decoration: none;
    color: inherit;
`;

export const IconContainer = styled.div`
    display: flex;
    gap: 7px;
    color: #1a1a4d;
    padding-right: 10px;
`;

