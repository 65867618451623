import styled from "styled-components";
import Slider from "react-slick";

export const Container = styled.div`
    color: black;
    display: flex;
    justify-content: center;

`;
export const Wrapper = styled.div`
    width: 90%;
    max-width: 1280px;
    display: flex;
    padding: 70px 65px 0;
    flex-direction: column;

    
    @media only screen and (max-width: 30em) {
        padding: 0 10px;
        margin: 40px 0 0;
    }
`;

export const ItemContainer = styled.div`
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const Item = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 50px 7px 20px;
    align-items: center;

    @media only screen and (max-width: 67em) {
        margin: 30px;
    }

    @media only screen and (max-width: 48em) {
        margin: 20px 20px 20px;
    }

    @media only screen and (max-width: 30em) {
        margin: 30px 15px 0;
    }

`;
export const Icon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 94px;
    height: 94px;
    border-radius: 50%;
    background-color: #3aba66;
    color: white;
    border: 1px solid #3aba66;
`;

export const Title = styled.h1`
    height: 65px;
    font-size:22px;
    text-align: center;
    letter-spacing: .09em;
    text-transform: uppercase;
    line-height: 1.1;
    margin-bottom: 12px;
    font-weight: 800;
    padding: 15px 15px 0 15px;

    @media only screen and (max-width: 67em) {
        padding: 15px 35px 0 ;
    }

    @media only screen and (max-width: 48em) {
        padding: 15px 15px 0 ;
    }

    @media only screen and (max-width: 30em) {
        padding: 15px 35px 0 ;
    }
`;
export const Subtitle = styled.p`
    text-align: center;
    font-size: 16px;
    padding: 0 15px 0;
    line-height: 1.4;
`;

export const StyledSlider = styled(Slider)`
    .slick-prev {
        width: 3rem;
        height: 3rem;
        left: -3.4rem;

        @media only screen and (max-width: 67em) {
            width: 2.5rem;
            height: 2.5rem;
            left: -2.4rem;
        }

        @media only screen and (max-width: 30em) {
            width: 2rem;
            height: 2rem;
            left: -1.4rem;
        }
    }

    .slick-next {
        width: 3rem;
        height: 3rem;
        right: -3.4rem;

        @media only screen and (max-width: 67em) {
            width: 2.5rem;
            height: 2.5rem;
            right: -2.4rem;
        }

        @media only screen and (max-width: 30em) {
            width: 2rem;
            height: 2rem;
            right: -1.4rem;
        }
    }

    .slick-arrow {
        border: 1px solid #10a74b;
        background-color: white;
        border-radius: 50%;

        @media only screen and (max-width: 30em) {
         border: none;
        background-color: white;
        border-radius: none;
        }
    }

    .slick-prev:before,
    .slick-next:before {
        color: #10a74b !important;
    }
`;