import React, { useContext } from "react";
import {
    Container,
    Wrapper,
    InfoContainer,
    Title,
    Subtitle,
} from "./competitionComponents";
import { LanguageContext } from "../../../containers/Language";

function Mission() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="competition">
            <Wrapper>
                <InfoContainer>
                    <Title>{dictionary.competition.title} </Title>
                    <Subtitle>
                        <b>{dictionary.competition.subtitle1}</b>
                    </Subtitle>
                    <Subtitle>
                    {dictionary.competition.subtitle2}
                    </Subtitle>
                    <Subtitle>
                        <b> {dictionary.competition.subtitle3}</b>
                    </Subtitle>
                    <Subtitle>
                        •  {dictionary.competition.subtitle4};
                    </Subtitle>
                    <Subtitle>
                        •  {dictionary.competition.subtitle5};
                    </Subtitle>
                    <Subtitle>
                        •  {dictionary.competition.subtitle6};
                    </Subtitle>
                    <Subtitle>
                        •  {dictionary.competition.subtitle7};
                    </Subtitle>
                    <Subtitle>
                        •  {dictionary.competition.subtitle8};
                    </Subtitle>
                    <Subtitle>
                        •  {dictionary.competition.subtitle9};
                    </Subtitle>
                    <Subtitle>
                    {dictionary.competition.subtitle10}
                    </Subtitle>
                    <Subtitle>
                        <a
                            href="olimpiada@kemelbilim.edu.kz"
                            style={{ color: "inherit", cursor: "pointer" }}
                        >
                            <b>olympiad@kemelbilim.edu.kz</b>
                        </a>
                    </Subtitle>
                </InfoContainer>
            </Wrapper>
        </Container>
    );
}

export default Mission;
