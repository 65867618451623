import React, {useContext} from 'react';
import {
    Container,
    Wrapper,
    MenuList,
    MenuItem,
    MenuLink,
} from "./navigationComponents";
import { LanguageContext } from "../../../containers/Language";

function Navigation() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container>
            <Wrapper>
                <MenuList>
                    <MenuItem>
                        <MenuLink href="#price">{dictionary.header.price}</MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink href="#online">{dictionary.header.online}</MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink href="#bank-account" lastChild>{dictionary.header.bankAccount}</MenuLink>
                    </MenuItem>
                </MenuList>
            </Wrapper>
        </Container>
    );
}

export default Navigation;
