import React, { useContext } from "react";
import {
    Container,
    Wrapper,
    InfoContainer,
    Title,
    Subtitle,
    Top,
    Bottom,
    BottomImgContainer,
    BottomImg,
    StyledSlider,
} from "./internatComponents";
import internat1 from "../.././../images/educational-work/internat/internat1.JPG";
import internat2 from "../.././../images/educational-work/internat/internat2.JPG";
import internat3 from "../.././../images/educational-work/internat/internat3.JPG";
import internat4 from "../.././../images/educational-work/internat/internat4.JPG";
import internat5 from "../.././../images/educational-work/internat/internat5.JPG";

import { LanguageContext } from "../../../containers/Language";

function Internat() {
    const { dictionary } = useContext(LanguageContext);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
        ],
    };

    return (
        <Container id="internat">
            <Wrapper>
                <Top>
                    <InfoContainer>
                        <Title>ИНТЕРНАТ</Title>
                        <Subtitle>{dictionary.school.subtitle}</Subtitle>
                    </InfoContainer>
                </Top>
                <Bottom>
                    <StyledSlider {...settings}>
                        <BottomImgContainer>
                            <BottomImg src={internat1} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={internat2} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={internat3} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={internat4} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={internat5} />
                        </BottomImgContainer>
                    </StyledSlider>
                </Bottom>
            </Wrapper>
        </Container>
    );
}

export default Internat;
