import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
`;

export const Wrapper = styled.div`
    width: 90%;
    max-width: 1280px;
    padding-top: 90px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 48em) {
        padding-top: 50px;
    }

      @media only screen and (max-width: 30em) {
       margin-bottom: 0;
    }
`;

export const Texts = styled.div`
    margin-bottom: 50px;
    padding: 0 20px;
    @media only screen and (max-width: 67em) {
        margin-bottom: 30px;
    }

    @media only screen and (max-width: 48em) {
        margin-bottom: 20px;
    }
`;

export const Title = styled.h1`
    color: #1a1a4d;
    font-size: 4rem;
    font-weight: bold;
    padding-bottom: 15px;

    @media only screen and (max-width: 67em) {
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
        padding-left: 0;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;


export const ItemContainer = styled.div`
    padding: 0 1rem;

    @media only screen and (max-width: 30em) {
        padding: 0 15px;
    }
`;

export const Item = styled.div`
    padding: 60px 35px;
`;



export const PostDate = styled.div`
    margin-bottom: 13px;
    line-height: 1.1;
    font-style: italic;
`;

export const PostTitle = styled.div`
    font-size: 22px;
    line-height: 1.2;
    margin-bottom: 13px;
`;

export const PostSubtitle = styled.div`
    font-size: 16px;
    line-height: 1.7;
    padding-bottom: 10px;
`;
