import React, { useContext } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { InfoContainerList } from "./administrationComponents";
import { LanguageContext } from "../../../containers/Language";
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);
    const { dictionary } = useContext(LanguageContext);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                >
                    <Tab label={dictionary.tab.label1} {...a11yProps(0)} />
                    <Tab label={dictionary.tab.label2}  {...a11yProps(1)} />
                    <Tab label={dictionary.tab.label3}  {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <InfoContainerList>
                    1992-1994 Балыкесир биология мұғалімі
                </InfoContainerList>
                <InfoContainerList>
                    1994-1998 Қызылорда "БІЛІМ-ИННОВАЦИЯ" ұл балалар лицейі
                    биология мұғалім
                </InfoContainerList>
                <InfoContainerList>
                    1998-2001 Семей "БІЛІМ-ИННОВАЦИЯ" ұл балалар лицейі биология
                    мұғалім
                </InfoContainerList>
                <InfoContainerList>
                    2001-2006 Алматы "БІЛІМ-ИННОВАЦИЯ" қыз балалар лицейі
                    директорының оқу ісі жөніндегі орынбасары{" "}
                </InfoContainerList>
                <InfoContainerList>
                    2006-2010 Алматы "БІЛІМ-ИННОВАЦИЯ" қыз балалар лицейі
                    директоры
                </InfoContainerList>
                <InfoContainerList>
                    2010-2014 Алматы "БІЛІМ-ИННОВАЦИЯ" ұл балалар лицейі
                    директоры
                </InfoContainerList>
                <InfoContainerList>
                    2014-2022 Bilim-Orda Qor бас хатшы{" "}
                </InfoContainerList>
                <InfoContainerList>
                    2022-2023 Ақтөбе "Kemel Bilim" мектеп-лицей директоры
                </InfoContainerList>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <InfoContainerList>
                    1988-1993-Туркия Балыкесир қаласында Uludağ университеті
                    биология мұғаліміне оқыды
                </InfoContainerList>
                <InfoContainerList>
                    2017-London Perceptum educational «Education leadership»
                </InfoContainerList>
                <InfoContainerList>
                    2017-Brussels COACHING CERTIFICATE «student and life
                    coaching»
                </InfoContainerList>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <InfoContainerList>
                    2012 жыл УРКЕР мадақтамасын алды{" "}
                </InfoContainerList>
                <InfoContainerList>
                    2014 жыл ҚР және білім министрлігінің «БІЛІМ БЕРУ ІСІНІҢ
                    ҚҰРМЕТТІ ҚЫЗМЕТКЕРІ»
                </InfoContainerList>
                <InfoContainerList>
                    2014 жыл Houston.Texas.USA I-SWEEEP{" "}
                </InfoContainerList>
                <InfoContainerList>
                    2019 жыл ҚР «Ы.АЛТЫНСАРИН» белгісімен марапатталған жыл ҚР
                    «Бірлік» алтын медалімен марапатталған
                </InfoContainerList>
            </TabPanel>
        </Box>
    );
}
