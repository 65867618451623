import styled from "styled-components";
import Slider from "react-slick";

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
    background-color: #10a74b;
    color: white;
`;

export const Wrapper = styled.div`
    width: 90%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    margin: 90px 0 70px;
    padding: 0 20px;

    @media only screen and (max-width: 48em) {
        margin: 70px 0;
    }

    @media only screen and (max-width: 30em) {
        padding: 0 15px;
        margin: 60px 0 30px;
    }
`;

export const Title = styled.h1`
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 30px;
    text-align: center;

    @media only screen and (max-width: 67em) {
        font-size: 3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const ItemContainer = styled.div`
    padding: 1rem;
`;

export const Item = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    position: relative;
`;

export const ItemImgContainer = styled.div`
    height: 500px;

    @media only screen and (max-width: 30em) {
        height: 420px;
    }
`;

export const ItemImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
`;

export const ItemText = styled.div`
    padding: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    justify-content: end;
    opacity: 0;
    transition: opacity 0.25s;

    &:hover {
        opacity: 1;
       
    }
   
    &:hover > * {
        transform: translateY(0);
    }
`;

export const ItemTitle = styled.div`
    color: #ffffff;
    font-weight: 700;
    font-size: 1.875rem;
    padding-bottom: 5px;
    padding-top: 20px;
    opacity: 1;
    border-bottom: 1px solid white;
`;

export const ItemSubtitle = styled.p`
padding-top: 10px;
    margin: 0 0 12.5px;
    font-size: 1rem;
    line-height: 1.6;
`;

export const StyledSlider = styled(Slider)`
    .slick-prev {
        width: 3rem;
        height: 3rem;
        left: -3.4rem;

        @media only screen and (max-width: 67em) {
            width: 2.5rem;
            height: 2.5rem;
            left: -2.4rem;
        }

        @media only screen and (max-width: 30em) {
            width: 2rem;
            height: 2rem;
            left: -1.9rem;
        }
    }

    .slick-next {
        width: 3rem;
        height: 3rem;
        right: -3.4rem;

        @media only screen and (max-width: 67em) {
            width: 2.5rem;
            height: 2.5rem;
            right: -2.4rem;
        }

        @media only screen and (max-width: 30em) {
            width: 2rem;
            height: 2rem;
            right: -1.9rem;
        }
    }

    .slick-arrow {
        border: 1px solid white;
        border-radius: 50%;

        @media only screen and (max-width: 30em) {
            border: none;
            border-radius: none;
        }
    }

    .slick-prev:before,
    .slick-next:before {
    }
`;
