import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const Wrapper = styled.div`
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin: 90px 0;

    @media only screen and (max-width: 48em) {
        margin: 50px 0;
    }

    @media only screen and (max-width: 30em) {
        padding: 0 15px;
        margin: 20px 0;
    }
`;

export const TextContainer = styled.div`
    display: grid;
    
    @media only screen and (max-width: 48em) {
        display: flex;
        flex-direction: column;
    }
`;

export const Title = styled.div`
    text-align: center;
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 20px;
    color: #1a1a4d;

    @media only screen and (max-width: 67em) {
        font-size: 3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const Item = styled.div`
    background: #f6f6f6;
    border-radius: 6px;
    padding: 25px 50px;
    color: #222222;
    font-size: 20px;
    line-height: 1.4;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Text = styled.p`
    margin: 0 0 12.5px;

    @media only screen and (max-width: 48em) {
        font-size: 1.3rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;
