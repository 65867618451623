import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;

export const Wrapper = styled.div`
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    margin: 40px 0 60px;

    @media only screen and (max-width: 67em) {
        margin: 40px 0 60px;
    }

    @media only screen and (max-width: 48em) {
        margin: 40px 0 20px;
    }

    @media only screen and (max-width: 30em) {
        margin: 30px 0 30px;
        padding: 0 15px;
    }
`;

export const Title = styled.div`
    text-align: center;
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 20px;
    color: #1a1a4d;

    @media only screen and (max-width: 67em) {
        font-size: 3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const Bottom = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    @media only screen and (max-width: 48em) {
        flex-direction: column;
    }
`;

export const BottomTitle = styled.h1`
    align-self: center;
    font-size: 1.5rem;
    padding: 20px 0 20px;
    border-bottom: 1px solid gray;

    @media only screen and (max-width: 67em) {
        width: 100%;
        margin-bottom: 25px;
        font-size: 1.7rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1.5rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.3rem;
    }
`;

export const BottomInfo = styled.span`
    display: flex;
    padding-top: 15px;
    line-height: 1.5;
`;

export const BottomItems = styled.div`
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 100%;
   

    @media only screen and (max-width: 67em) {
        width: 100%;
    }

    @media only screen and (max-width: 48em) {
        flex-direction: column;
        
    }

    @media only screen and (max-width: 48em) {
        gap: 0;
    }
`;

export const BottomItem = styled.div`
    padding: 25px 25px;
    width: 33%;

    @media only screen and (max-width: 48em) {
        width: 100%;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        padding: 10px 25px;
    }
`;

