import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    background-color: #10a74b;;
`;

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1280px;
    display: flex;
    color: white;
    padding: 90px 20px;
    flex-direction: column;

    @media only screen and (max-width: 67em) {
        padding: 60px 30px 60px 30px;
    }

    @media only screen and (max-width: 57em) {
        padding: 60px 30px 60px 30px;
    }

    @media only screen and (max-width: 40em) {
        padding-top: 50px 0 0;
    }

    @media only screen and (max-width: 30em) {
        padding: 50px 15px;
    }
`;

export const Text = styled.div`
    height: 40px;
    display: flex;
    flex-direction: column;
    padding-bottom: 200px;

    @media only screen and (max-width: 54.3em) {
        padding-bottom: 130px;
    }

    @media only screen and (max-width: 48em) {
        padding-bottom: 130px;
    }

    @media only screen and (max-width: 40em) {
        padding-bottom: 110px;
    }
`;

export const Title = styled.h1`
    color: white;
    font-size: 4.125rem;
    padding-left: 100px;
    font-weight: bold;
    padding-bottom: 15px;

    @media only screen and (max-width: 67em) {
        padding-left: 40px;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
        padding-left: 0;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const Subtitle = styled.h5`
    font-size: 1.5rem;
    padding-left: 250px;
    letter-spacing: 0.15em;
    

    @media only screen and (max-width: 67em) {
        padding-left: 100px;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1rem;
        padding-left: 0;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;

export const Content = styled.div`
    display: flex;

    @media only screen and (max-width: 54.3em) {
        flex-direction: column;
    }

    @media only screen and (max-width: 48em) {
        flex-direction: column;
    }
`;

export const Left = styled.div`
    flex: 2;
    display: grid;
    gap: 50px;

    @media only screen and (max-width: 67em) {
        gap: 30px;
    }

    @media only screen and (max-width: 30em) {
        gap: 15px;
    }
`;

export const LeftActivityItem = styled.div`
    display: flex;

    @media only screen and (max-width: 54.3em) {
        margin-left: 20px;
        margin-right: 20px;
    }

    @media only screen and (max-width: 48em) {
        margin-left: 0;
        margin-right: 0;
    }

    @media only screen and (max-width: 40em) {
        display: flex;
        flex-direction: column;
    }
`;

export const Right = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    margin-left: 40px;

    @media only screen and (max-width: 54.3em) {
        padding: 0 20px 0 20px;
        margin-top: 15px;
        margin-left: 0;
    }

    @media only screen and (max-width: 48em) {
        padding: 0;
        margin-top: 15px;
        margin-left: 0;
        margin-left: 0;
    }

    @media only screen and (max-width: 22em) {
        padding: 0;
    }
`;

export const RightActivityItem = styled.div`
    @media only screen and (max-width: 54.3em) {
        display: flex;
        flex-direction: row-reverse;
    }

    @media only screen and (max-width: 40em) {
        display: flex;
        flex-direction: column;
    }

    @media only screen and (max-width: 22em) {
        margin-left: 0;
        margin-right: 0;
    }
`;

export const ActivityInfo = styled.div`
    box-shadow: green 0px 0 50px -12px;
    position: relative;
    padding: ${(props) =>
        props.topItem ? "40px 70px 40px 50px" : "40px 50px 40px 70px"};
    padding: ${(props) => (props.right ? "60px 50px" : "")};

    &:hover {
        color: #10a74b;;
        background-color: white;
        transition: 0.3s ease-out;
    }

    @media only screen and (max-width: 67em) {
        padding: ${(props) =>
            props.topItem ? "40px 60px 40px 30px" : "40px 30px 40px 50px"};
        padding: ${(props) => (props.right ? "40px 30px" : "")};
        width: 380px;
        font-size: 0.9375rem;
        width: 100%;
    }

    @media only screen and (max-width: 54.3em) {
        padding: 50px 30px;
    }

    @media only screen and (max-width: 40em) {
        padding: 35px 20px;
        order: ${(props) => (props.topItem ? "2" : "")};
    }

    @media only screen and (max-width: 22em) {
        padding: 35px 20px;
    }
`;

export const ActivityTitle = styled.h1`
    font-size: 1.5rem;

    @media only screen and (max-width: 40em) {
        font-size: 1.375em;
    }
`;

export const ActivityItemSubtitle = styled.p`
    padding-top: 15px;
    line-height: 1.4;
    font-size: 1rem;
    
`;

export const ActivityVisual = styled.div``;

export const ActivityImg1 = styled.img`
    width: 415px;
    height: 274px;
    margin-bottom: -30px;
    margin-left: -30px;
    position: relative;
    z-index: 2;

    @media only screen and (max-width: 67em) {
        width: 350px;
        height: 250px;
        margin-right: 0;
    }

    @media only screen and (max-width: 54.3em) {
        height: 100%;
        margin: 0;
    }

    @media only screen and (max-width: 40em) {
        height: 100%;
        width: 100%;
    }

    @media only screen and (max-width: 30em) {
        height: 100%;
        width: 100%;
    }
`;

export const ActivityImg2 = styled.img`
    width: 415px;
    height: 274px;
    margin-top: -30px;
    margin-right: -30px;
    position: relative;
    z-index: 2;

    @media only screen and (max-width: 67em) {
        width: 350px;
        height: 250px;
        margin-right: 0;
    }

    @media only screen and (max-width: 54.3em) {
        height: 100%;
        margin: 0;
    }

    @media only screen and (max-width: 40em) {
        height: 100%;
        width: 100%;
    }

    @media only screen and (max-width: 30em) {
        height: 100%;
        width: 100%;
    }
`;

export const ActivityImg3 = styled.img`
    height: 300px;
    width: 100%;
    position: relative;
    z-index: 2;

    @media only screen and (max-width: 67em) {
        height: 270px;
        min-width: 200px;
    }

    @media only screen and (max-width: 54.3em) {
        object-fit: cover;
        height: 100%;
        width: 300px;
    }

    @media only screen and (max-width: 40em) {
        height: 100%;
        width: 100%;
    }

    @media only screen and (max-width: 30em) {
        height: 100%;
    }
`;
