import React from 'react';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import Careers from '../components/careers/Careers';

function CareersPage() {
    return (
        <div>
            <Header />
            <Careers />
            <Footer />
        </div>
    );
}

export default CareersPage;
