import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
    color: gray;
`;

export const Wrapper = styled.div`
    width: 90%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    margin: 90px 0 70px;
    padding: 0 20px;

    @media only screen and (max-width: 48em) {
        margin: 70px 0;
    }

    @media only screen and (max-width: 30em) {
        padding: 0 15px;
        margin: 60px 0 30px;
    }
`;

export const Title = styled.h1`
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 30px;
    text-align: center;
    color: #1a1a4d;
    @media only screen and (max-width: 67em) {
        font-size: 3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const Items = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;

@media only screen and (max-width: 67em) {
        
    }

    @media only screen and (max-width: 48em) {
        flex-direction: column;
    }

    @media only screen and (max-width: 30em) {
        flex-direction: column;
    }
`;



export const Item = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 30px;
    align-items: center;
    border-width: 5px;
    flex-direction: column;

    @media only screen and (max-width: 30em) {
        padding: 0;
    }
`;

export const ItemImgContainer = styled.div`
    height: 600px;
    
    @media only screen and (max-width: 30em) {
        height: 420px;
    }
`;

export const ItemImg = styled.img`
    width: 500px;
    height: 100%;
    object-fit: cover;
    display: block;

    @media only screen and (max-width: 67em) {
        width: 400px;
        height: 500px;
    }

    @media only screen and (max-width: 48em) {
        flex-direction: column;
        height: 500px;
    }

    @media only screen and (max-width: 30em) {
        width: 250px;
        height: 400px;
    }
`;


export const ItemSubtitle = styled.p`
padding-top: 5px;
    margin: 0 0 12.5px;
    font-size: 1rem;
    line-height: 1.6;
    @media only screen and (max-width: 30em) {
        padding-top: 5px;
    }
`;

export const Subtitle = styled.p`
    font-size: 1.5rem;
    line-height: 1.3;
    color: #1a1a4d;
    text-align: center;

    @media only screen and (max-width: 67em) {
        font-size: 1.3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1.2rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
        padding-bottom: 20px;
    }
`