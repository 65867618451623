import React, { useContext } from "react";
import {
    Container,
    Wrapper,
    Title,
    Item,
    ItemImg,
    ItemSubtitle,
    Items,
    ItemImgContainer,
    Subtitle
} from "./studentSuccessComponents";
import { LanguageContext } from "../../../containers/Language";
import student1 from "../.././../images/success/student1.PNG";
import student2 from "../.././../images/success/student2.PNG";
import student3 from "../.././../images/success/student3.JPG";
import student4 from "../.././../images/success/student4.JPG";


function Sections() {
    const { dictionary } = useContext(LanguageContext);

    return (
        <Container id="sections">
            <Wrapper>
                <Title>{dictionary.studentSuccess.title}</Title>
                <Subtitle>2021-2022</Subtitle>
                <Items>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={student1} />
                            </ItemImgContainer>

                            <ItemSubtitle>
                                Дүйсенбаева Айгерім
                            </ItemSubtitle>
                            <ItemSubtitle>
                                География пәні
                            </ItemSubtitle>
                            <ItemSubtitle>
                                3 орын
                            </ItemSubtitle>
                        </Item>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={student2} />
                            </ItemImgContainer>

                            <ItemSubtitle>
                                Әйіп Ақнұр
                            </ItemSubtitle>
                            <ItemSubtitle>
                                Химия пәні
                            </ItemSubtitle>
                            <ItemSubtitle>
                                3-орын
                            </ItemSubtitle>
                        </Item>
                </Items>
                <Subtitle>2022-2023</Subtitle>
                <Items>
                    <Item>
                        <ItemImgContainer>
                            <ItemImg src={student3} />
                        </ItemImgContainer>
                        <ItemSubtitle>
                            Әйіп Ақнұр
                        </ItemSubtitle>
                        <ItemSubtitle>
                            Химия пәні
                        </ItemSubtitle>
                        <ItemSubtitle>
                            3-орын
                        </ItemSubtitle>
                    </Item>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={student4} />
                            </ItemImgContainer>

                            <ItemSubtitle>
                                Жүсіп Хадиша
                            </ItemSubtitle>
                            <ItemSubtitle>
                                Математика
                            </ItemSubtitle>
                            <ItemSubtitle>
                                3-орын
                            </ItemSubtitle>
                        </Item>
                </Items>
            </Wrapper>
        </Container>
    );
}

export default Sections;