import React, {useContext} from 'react';
import { Container, Wrapper, BannerImg, Info, Title, Subtitle } from './bannerComponents';
import main from "../../../images/home/main.jpg";
import { LanguageContext } from '../../../containers/Language';

function Banner() {
  const { dictionary } = useContext(LanguageContext);
  return (
    <Container>
        <Wrapper>
            <BannerImg src={main}/>
            <Info>
                <Title>{dictionary.bannerMain.title}</Title>
                <Subtitle>{dictionary.bannerMain.subtitle}</Subtitle>
            </Info>
        </Wrapper>
    </Container>
  )
}

export default Banner;