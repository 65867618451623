import styled from "styled-components";
import Slider from "react-slick";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    background-color: #10a74b;
    color: white;
`;

export const Wrapper = styled.div`
    width: 90%;
    max-width: 1280px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
    margin: 90px 0;

    @media only screen and (max-width: 67em) {
    }

    @media only screen and (max-width: 48em) {
        margin: 70px 0;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        font-size: 1rem;
        flex-direction: column;
        padding: 0 15px;
        margin: 30px 0;
    }
`;

export const Top = styled.div`
    display: flex;
    padding-bottom: 30px;
    justify-content: space-between;

    @media only screen and (max-width: 30em) {
        flex-direction: column;
    }
`;

export const Bottom = styled.div`
    width: 100%;
    max-width: 1280px;

      @media only screen and (max-width: 30em) {
        padding-bottom: 30px;
    }
`;

export const BottomImgContainer = styled.div`
    padding: 1rem;
    height: 400px;

    @media only screen and (max-width: 30em) {
        height: 300px;
    }
`;

export const BottomImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(53% - 75px);

    @media only screen and (max-width: 67em) {
        width: calc(53% - 50px);
        align-self: center;
    }

    @media only screen and (max-width: 48em) {
        width: calc(53% - 40px);
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        margin-bottom: 20px;
    }
`;

export const ImgContainer = styled.div`
    width: 47%;

    @media only screen and (max-width: 30em) {
        width: 100%;
        height: 300px;
    }
`;

export const Img = styled.img`
        width: 100%;
    height: 100%;
    object-fit: cover;

    @media only screen and (max-width: 67em) {
        height: 100%;
    }

    
`;

export const Title = styled.h1`
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 20px;

    @media only screen and (max-width: 67em) {
        font-size: 3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const Subtitle = styled.p`
    font-size: 1.5rem;
    line-height: 1.7;
    text-align: justify;

    @media only screen and (max-width: 67em) {
        font-size: 1.3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1.1rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;

export const StyledSlider = styled(Slider)`
    .slick-prev {
        width: 3rem;
        height: 3rem;
        left: -3.4rem;

        @media only screen and (max-width: 67em) {
            width: 2.5rem;
            height: 2.5rem;
            left: -2.4rem;
        }

        @media only screen and (max-width: 30em) {
            width: 2rem;
            height: 2rem;
            left: -1.4rem;
        }
    }

    .slick-next {
        width: 3rem;
        height: 3rem;
        right: -3.4rem;

        @media only screen and (max-width: 67em) {
            width: 2.5rem;
            height: 2.5rem;
            right: -2.4rem;
        }

        @media only screen and (max-width: 30em) {
            width: 2rem;
            height: 2rem;
            right: -1.4rem;
        }
    }

    .slick-arrow {
        border: 1px solid white;
        border-radius: 50%;

        @media only screen and (max-width: 30em) {
        border: none;
        }
    }

    .slick-prev:before,
    .slick-next:before {
    }
`;