import React, { useContext } from 'react';
import {
    Container,
    Wrapper,
    MenuList,
    MenuItem,
    MenuLink,
} from './navigationComponents';
import { LanguageContext } from '../../../containers/Language';

function Navigation() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container>
            <Wrapper>
                <MenuList>
                    <MenuItem>
                        <MenuLink href="#exam">
                            {dictionary.header.exam}
                        </MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink href="#samples">
                            {dictionary.header.samples}
                        </MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink href="#documents">
                            {dictionary.header.documents}
                        </MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink href="#examination-results" lastChild>
                            Examination results 2023!
                        </MenuLink>
                    </MenuItem>
                </MenuList>
            </Wrapper>
        </Container>
    );
}

export default Navigation;
