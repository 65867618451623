import React, { useContext, useEffect, useState } from "react";
import {
    Container,
    Wrapper,
    InfoContainer,
    ImgContainer,
    Title,
    Subtitle,
    Img,
    Top,
    IconContainer,
    Link,
    RegistrationButton,
} from "./examComponents";
import { LanguageContext } from "../../../containers/Language";
import exam from "../../../images/admission/admission.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

function Exam() {
    const { userLanguage, dictionary } = useContext(LanguageContext);
    const [googleFormUrl, setGoogleFormUrl] = useState(
        "https://forms.gle/7TfHvMqN2f21Fdey6"
    );

    useEffect(() => {
        setRegistrationUrl(userLanguage);
    }, [userLanguage]);

    function setRegistrationUrl(userLanguage) {
        let url;

        if (userLanguage === "kz") {
            url =
                "https://forms.gle/7TfHvMqN2f21Fdey6";
        } else if (userLanguage === "ru") {
            url =
                "https://forms.gle/7TfHvMqN2f21Fdey6";
        } else {
            url =
                "https://forms.gle/7TfHvMqN2f21Fdey6";
        }

        setGoogleFormUrl(url);
    }
    return (
        <Container id="exam">
            <Wrapper>
                <Top>
                    <ImgContainer>
                        <Img src={exam} />
                    </ImgContainer>
                    <InfoContainer>
                        <Title>{dictionary.exam.title}</Title>
                        <Subtitle>{dictionary.exam.subtitle}</Subtitle>
                        <Subtitle>
                            <IconContainer>
                                <FontAwesomeIcon icon={faPhone} />
                            </IconContainer>
                            +7 705 601 2424
                        </Subtitle>
                        <Subtitle>
                            <IconContainer>
                                <FontAwesomeIcon icon={faEnvelope} />
                            </IconContainer>
                            admission@kemelbilim.edu.kz
                        </Subtitle>
                        <Link href={googleFormUrl} target="_blank">
                            <RegistrationButton>
                                {dictionary.exam.registrationButton}
                            </RegistrationButton>
                        </Link>
                    </InfoContainer>
                </Top>
            </Wrapper>
        </Container>
    );
}

export default Exam;
