import React, { useContext } from "react";
import {
    Container,
    Wrapper,
    InfoContainer,
    ImgContainer,
    Title,
    Subtitle,
    Img,
    List,
    Lists,
} from "./edupageComponents";
import edupage from "../../../images/education-process/edupage.png";
import { LanguageContext } from "../../../containers/Language";

function Mission() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="edupage">
            <Wrapper>
                <InfoContainer>
                    <Title>{dictionary.edupage.title} </Title>
                    <Subtitle>
                        {dictionary.edupage.subtitle1}{" "}
                        <a
                            href="https://kemelbilim.edupage.org/"
                            style={{ color: "inherit", cursor: "pointer" }}
                        >
                            {" "}
                            https://kemelbilim.edupage.org/
                        </a>
                    </Subtitle>
                    <Subtitle>{dictionary.edupage.subtitle2}</Subtitle>
                    <Lists>
                        <List>{dictionary.edupage.list1}</List>
                        <List>{dictionary.edupage.list2} </List>
                        <List>{dictionary.edupage.list3} </List>
                        <List>{dictionary.edupage.list4} </List>
                        <List>{dictionary.edupage.list5} </List>
                        <List>{dictionary.edupage.list6} </List>
                        <List>{dictionary.edupage.list7} </List>
                        <List>{dictionary.edupage.list8} </List>
                        <List>{dictionary.edupage.list9} </List>
                        <List>{dictionary.edupage.list10} </List>
                        <List>{dictionary.edupage.list11} </List>
                    </Lists>
                </InfoContainer>
                <ImgContainer>
                    <Img src={edupage} />
                </ImgContainer>
            </Wrapper>
        </Container>
    );
}

export default Mission;
