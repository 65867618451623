import styled from "styled-components";

export const Container = styled.div`
    color: black;
    display: flex;
    justify-content: center;
`;
export const Wrapper = styled.div`
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin: 50px 0 90px;

    @media only screen and (max-width: 48em) {
        margin: 50px 0 70px;
    }

    @media only screen and (max-width: 30em) {
        padding: 0 15px;
        margin: 40px 0 60px;
    }
`;

export const Title = styled.div`
    text-align: center;
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 20px;
    color: #1a1a4d;

    @media only screen and (max-width: 67em) {
        font-size: 3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const Subtitle = styled.p`
    font-size: 1.5rem;
    line-height: 1.7;
    color: gray;
    padding-bottom: 30px;
    text-align: center;

    @media only screen and (max-width: 67em) {
        font-size: 1.3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1.1rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;

export const Document = styled.div`
    background: #f6f6f6;
    border-radius: 6px;
    width: 100%;
    margin-bottom: 10px;
    padding: 40px 60px;
    color: #222222;
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 500;
    line-height: 1.6;
`;

export const Lists = styled.ol`
    padding: 0;
    margin: 0;
    list-style: none;
`;

export const List = styled.li`
    /* list-style: disc; */
    margin-bottom: 5px;
    font-size: 1.25rem;

    @media only screen and (max-width: 48em) {
        font-size: 1.1rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;
