import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    background-color: #10a74b;
`;

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1280px;
    color: white;
    padding: 70px 20px;

    @media only screen and (max-width: 48em) {
        padding: 50px 20px;
    }
`;
export const Text = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 40px;
`;

export const Title = styled.h1`
    color: white;
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 20px;

    @media only screen and (max-width: 67em) {
        font-size: 3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const Subtitle = styled.h5`
    letter-spacing: 0.15em;
    font-size: 1.5rem;
    line-height: 1.3;

    @media only screen and (max-width: 67em) {
        font-size: 1.3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1.2rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;

export const Content = styled.div`
    display: grid;
    grid-template-columns: 46% calc(54% - 35px);

    @media only screen and (max-width: 64em) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;

export const ActivityItem1 = styled.div`
    display: flex;
    background-color: #10a74b;
    box-shadow: green 0px 0 50px -12px;

    &:hover {
        color: #10a74b;
        background-color: white;
        transition: 0.3s ease-out;
    }

    @media only screen and (max-width: 30em) {
        flex-direction: column;
    }
`;

export const ActivityItem2 = styled.div`
    display: flex;
    background-color: #10a74b;
    box-shadow: green 0px 0 50px -12px;

    &:hover {
        color: #10a74b;
        background-color: white;
        transition: 0.3s ease-out;
    }
    @media only screen and (max-width: 67em) {
        grid-area: auto;
        flex-direction: row;
    }

    @media only screen and (max-width: 30em) {
        flex-direction: column-reverse;
    }
`;

export const ActivityItem3 = styled.div`
    display: flex;
    background-color: #10a74b;
    grid-area: 1 / 2 / 3 / 3;
    flex-direction: column;
    box-shadow: green 0px 0 50px -12px;

    &:hover {
        color: #10a74b;
        background-color: white;
        transition: 0.3s ease-out;
    }

    @media only screen and (max-width: 64em) {
        display: flex;
        flex-direction: row;
    }

    @media only screen and (max-width: 30em) {
        flex-direction: column;
    }
`;

export const ActivityInfo = styled.div`
    padding: 30px 40px;

    @media only screen and (max-width: 67em) {
        width: 100%;
    }

    @media only screen and (max-width: 48em) {
        padding: 30px 20px;
        width: 80%;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
    }
`;

export const ActivityTitle = styled.h1``;
export const ActivityItemSubtitle = styled.p`
    line-height: 1.4;
    font-size: 1.1rem;
`;

export const ActivityVisual = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ActivityImg1 = styled.img`
    width: 550px;

    object-fit: cover;
    vertical-align: middle;

    @media only screen and (max-width: 67em) {
        width: 250px;
    }

    @media only screen and (max-width: 48em) {
        width: 100%;
        height: 195px;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        height: 195px;
    }
`;

export const ActivityImg3 = styled.img`
    width: 100%;
    height: 294px;
    object-fit: cover;
    vertical-align: middle;

    @media only screen and (max-width: 67em) {
        width: 100%;
        height: 195px;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
    }
`;
