import React, { useContext } from 'react';
import {
    Container,
    Wrapper,
    Title,
    Document,
    Lists,
    List,
} from './schoolCertificationComponents';
import { LanguageContext } from './/../../containers/Language';
import Application7 from ".//./../../documents/schoolCertification/Приложение 7.pdf";
import Application8 from ".//./../../documents/schoolCertification/Приложение 8.pdf";
import Application9 from ".//./../../documents/schoolCertification/Приложение 9.pdf";
import Application10 from ".//./../../documents/schoolCertification/Приложение 10.pdf";
import Application11 from ".//./../../documents/schoolCertification/Приложение 11.pdf";
import Application12 from ".//./../../documents/schoolCertification/Приложение 12.pdf";
import Application13 from ".//./../../documents/schoolCertification/13 қосымша.pdf";
import Application13_1 from ".//./../../documents/schoolCertification/Приложение 13-1.pdf";
import PedKenesKoshirme from ".//./../../documents/schoolCertification/Педагогикалық кеңес көшірмесі.pdf";
import OzinOziBagalau from ".//./../../documents/schoolCertification/ЖШС  Кемел Билим ми өзін өзі бағалау құрылымы.doc";
import Khattama from ".//./../../documents/schoolCertification/Педагогикалық кеңес хаттамасы №1 2024-2025.pdf";
import Buiryq from ".//./../../documents/schoolCertification/Комиссия құрамын бектіу туралы бұйрық.pdf";
import BagalauParagy from ".//./../../documents/schoolCertification/Бағалау парағы.pdf";
import synyp4 from ".//./../../documents/schoolCertification/4 сыныптардың кешенді тестлеу ведомосі.pdf";
import synyp9 from ".//./../../documents/schoolCertification/9 сыныптардың кешенді тестлеу ведомосі.pdf";

function NewsSeparate() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container>
            <Container id="school-certification">
                <Wrapper>
                    <Title>{dictionary.schoolCertification.title}</Title>
                    <Document>
                        <Lists>
                            <List>
                                <a
                                    href={Buiryq}
                                    download={Buiryq}
                                    style={{ color: "inherit", cursor: "pointer" }}
                                >
                                    1. {dictionary.schoolCertification.list1} </a>
                            </List>
                            <List>
                                <a
                                    href={Khattama}
                                    download={Khattama}
                                    style={{ color: "inherit", cursor: "pointer" }}
                                >
                                    2. {dictionary.schoolCertification.list2}
                                </a>
                            </List>
                            <List>
                                <a
                                    href={OzinOziBagalau}
                                    download={OzinOziBagalau}
                                    style={{ color: "inherit", cursor: "pointer" }}
                                >
                                    3. {dictionary.schoolCertification.list3}
                                </a>
                            </List>
                            <List>
                                4. {dictionary.schoolCertification.list4} :
                                <Lists style={{ paddingLeft: "1rem" }}>
                                    <List>
                                        <a
                                            href={Application7}
                                            download={Application7}
                                            style={{ color: "inherit", cursor: "pointer" }}
                                        >
                                            {dictionary.schoolCertification.list5}
                                        </a>
                                    </List>
                                    <List>
                                        <a
                                            href={Application8}
                                            download={Application8}
                                            style={{ color: "inherit", cursor: "pointer" }}
                                        >{dictionary.schoolCertification.list6} </a> </List>
                                    <List>
                                        <a
                                            href={Application9}
                                            download={Application9}
                                            style={{ color: "inherit", cursor: "pointer" }}
                                        >
                                            {dictionary.schoolCertification.list7}
                                        </a>
                                    </List>
                                    <List>
                                        <a
                                            href={Application10}
                                            download={Application10}
                                            style={{ color: "inherit", cursor: "pointer" }}
                                        >{dictionary.schoolCertification.list8}
                                        </a>
                                    </List>
                                    <List>
                                        <a
                                            href={Application11}
                                            download={Application11}
                                            style={{ color: "inherit", cursor: "pointer" }}
                                        >
                                            {dictionary.schoolCertification.list9}
                                        </a>
                                    </List>
                                    <List>
                                        <a
                                            href={Application12}
                                            download={Application12}
                                            style={{ color: "inherit", cursor: "pointer" }}
                                        >{dictionary.schoolCertification.list10}
                                        </a>
                                    </List>
                                    <List>
                                        <a
                                            href={Application13}
                                            download={Application13}
                                            style={{ color: "inherit", cursor: "pointer" }}
                                        >
                                            {dictionary.schoolCertification.list11}
                                        </a>
                                    </List>
                                    <List>
                                        <a
                                            href={Application13_1}
                                            download={Application13_1}
                                            style={{ color: "inherit", cursor: "pointer" }}
                                        >
                                            {dictionary.schoolCertification.list12}
                                        </a>
                                    </List>
                                </Lists>
                            </List>
                            <List>
                                <a
                                    href={BagalauParagy}
                                    download={BagalauParagy}
                                    style={{ color: "inherit", cursor: "pointer" }}
                                >
                                    5. {dictionary.schoolCertification.list13}
                                </a>
                            </List>
                            <List>
                                <a
                                    href={PedKenesKoshirme}
                                    download={PedKenesKoshirme}
                                    style={{ color: "inherit", cursor: "pointer" }}
                                >
                                    6. {dictionary.schoolCertification.list14}
                                </a>
                            </List>
                            <List>
                                <a
                                    href={synyp4}
                                    download={synyp4}
                                    style={{ color: "inherit", cursor: "pointer" }}
                                >
                                    7. {dictionary.schoolCertification.list15}
                                </a>
                            </List>  <List>
                                <a
                                    href={synyp9}
                                    download={synyp9}
                                    style={{ color: "inherit", cursor: "pointer" }}
                                >
                                    8. {dictionary.schoolCertification.list16}
                                </a>
                            </List>
                        </Lists>
                    </Document>
                </Wrapper>
            </Container>
        </Container>
    );
}

export default NewsSeparate;