import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../../containers/Language';
import {
    Container,
    Wrapper,
    FormAndResultContainer,
    FormContainer,
    StyledButton,
    List,
    Lists,
} from './examinationResultsComponents';
import { Title } from '../documents/documentComponents';
import { Subtitle, StyledInput } from '../../registration/form/formComponents';
import { InputLabel } from '@mui/material';
import Alert from '@mui/material/Alert';

export default function ExaminationResults() {
    const PORT =
        'https://kemelbilim-service.netlify.app/.netlify/functions/index';
    const { dictionary } = useContext(LanguageContext);
    const [iin, setIin] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [examResult, setExamResult] = useState({});

    async function onSubmit() {
        setIsLoading(true);
        try {
            setIsError(false);
            const examResult = await getExamResult(iin);
            setExamResult(examResult);
        } catch (error) {
            console.error(error);
            setIsError(true);
        }
        setIsLoading(false);
    }

    async function getExamResult(iin) {
        const response = await fetch(
            `${PORT}/admission/examination-result/${iin}`
        );

        if (!response.ok) {
            throw new Error('Request failed');
        }

        const data = await response.json();
        return data;
    }

    function errorResponseAlert() {
        return (
            <Alert severity="error">
                Unexpected error! Please, try again later.
            </Alert>
        );
    }

    return (
        <Container id="examination-results">
            <Wrapper>
                <Title>ЕМТИХАН НӘТИЖЕЛЕРІ 2024</Title>
                <FormAndResultContainer>
                    <FormContainer>
                        <Subtitle>Талапкердің ЖСН-ын енгізіңіз</Subtitle>
                        <InputLabel htmlFor="my-input">ЖСН</InputLabel>
                        <StyledInput
                            aria-describedby="my-helper-text"
                            required
                            name="iin"
                            onChange={(e) => setIin(e.target.value)}
                        />
                        <StyledButton
                            type="submit"
                            variant="contained"
                            color="success"
                            disabled={!iin || isLoading}
                            onClick={onSubmit}
                        >
                            {dictionary.form.button}
                        </StyledButton>
                        {isError && errorResponseAlert(true)}
                    </FormContainer>
                    <Lists>
                        <List>
                            {examResult?.fullName && `Аты-жөні: ${examResult.fullName}`}
                        </List>
                        <List>
                            {examResult?.grade && `Сыныбы: ${examResult.grade}`}
                        </List>
                        <List>
                            {examResult?.status && `Статус: ${examResult.status}`}
                        </List>
                    </Lists>
                </FormAndResultContainer>
            </Wrapper>
        </Container>
    );
}
