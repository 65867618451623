import React, { useContext } from 'react';
import {
    Container,
    Wrapper,
    PriceItem,
    PriceGrade,
    PriceInfo,
    Tr,
    Th,
    Table,
} from './priceDetailsComponents';
import { LanguageContext } from '../../../containers/Language';

function PriceDetails() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="priceDetails">
            <Wrapper>
                <PriceItem>
                    <PriceGrade>
                        {dictionary.priceDetails.priceGrade}
                    </PriceGrade>
                    <PriceInfo>
                        <Table>
                            <Tr>
                                <Th>Сыныптар </Th>
                                <Th> Айлық оқу ақысы </Th>
                                <Th>Тамақтану ақысы </Th>
                                <Th> Жатақхана ақысы </Th>
                            </Tr>
                            <Tr>
                                <Th>7</Th>
                                <Th>127 000</Th>
                                <Th>-</Th>
                                <Th>200 000</Th>
                            </Tr>
                            <Tr>
                                <Th>8 - 11</Th>
                                <Th>88 000</Th>
                                <Th>-</Th>
                                <Th>200 000</Th>
                            </Tr>
                        </Table>
                    </PriceInfo>
                </PriceItem>
            </Wrapper>
        </Container>
    );
}

export default PriceDetails;
