import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    background-color: #10a74b;
    color: white;
`;

export const Wrapper = styled.div`
    width: 1280px;
    display: flex;
    justify-content: space-between;
    margin: 90px 0;
    padding: 0 20px;

    @media only screen and (max-width: 67em) {
        margin: 70px 0;
    }

    @media only screen and (max-width: 48em) {
        margin: 60px 0 60px;
        flex-direction: column-reverse;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
        flex-direction: column-reverse;
        margin: 50px 0 50px;
        padding: 0 15px;
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 90px;

    @media only screen and (max-width: 67em) {
        padding-right: 50px;
    }

    @media only screen and (max-width: 48em) {
        padding: 0;
        width: 100%;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
    }
`;



export const Title = styled.h1`
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 20px;

    @media only screen and (max-width: 67em) {
        font-size: 3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const Subtitle = styled.p`
    font-size: 1.2rem;
    line-height: 1.7;
    padding-bottom: 20px;

    @media only screen and (max-width: 67em) {
        font-size: 1.2rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1.1rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;
