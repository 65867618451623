import React, { useContext } from 'react';
import {
    Container,
    Title,
    Wrapper,
    Texts,
    Subtitle,
    Table,
    Th,
    Tr,
} from './schoolSuccessComponents';
import { LanguageContext } from '../../../containers/Language';

function SchoolSuccess() {
    const { dictionary } = useContext(LanguageContext);

    return (
        <Container id="school-success">
            <Wrapper>
                <Texts>
                    <Title>{dictionary.schoolSuccess.title}</Title>

                    <Table>
                        <Tr style={{rowspan: "2"}}>
                            <Th>

                            </Th>
                            <Th >
                                <Subtitle >
                                    {dictionary.schoolSuccess.subtitle1}
                                </Subtitle>
                            </Th>
                        </Tr>
                        <Tr>

                            <Th>{dictionary.schoolSuccess.text1}</Th>
                            <Th>{dictionary.schoolSuccess.text2}</Th>
                        </Tr>
                        <Tr>
                            <Th>

                            </Th>
                            <Th>
                                <Subtitle>
                                    {dictionary.schoolSuccess.subtitle2}
                                </Subtitle>
                            </Th>
                        </Tr>
                        <Tr>
                            <Th>{dictionary.schoolSuccess.text3}</Th>
                            <Th>{dictionary.schoolSuccess.text4}</Th>
                        </Tr>
                        <Tr>
                            <Th>{dictionary.schoolSuccess.text5}</Th>
                            <Th>
                                {dictionary.schoolSuccess.text6}
                                {dictionary.schoolSuccess.text7}
                            </Th>
                        </Tr>

                        <Tr>
                            <Th>

                            </Th>
                            <Th>

                                <Subtitle>
                                    {dictionary.schoolSuccess.subtitle3}
                                </Subtitle>
                            </Th>
                        </Tr>

                        <Tr>
                            <Th>{dictionary.schoolSuccess.text8}</Th>
                            <Th>
                                {dictionary.schoolSuccess.text9}
                                {dictionary.schoolSuccess.text10}
                            </Th>
                        </Tr>
                        <Tr>
                            <Th>{dictionary.schoolSuccess.text11}</Th>
                            <Th>
                                {dictionary.schoolSuccess.text12}
                                {dictionary.schoolSuccess.text13}
                            </Th>
                        </Tr>
                    </Table>
                </Texts>
            </Wrapper>
        </Container>
    );
}

export default SchoolSuccess;
