import React from 'react'
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import Form from "../components/registration/form/Form";
import Banner from "../components/registration/banner/Banner";

function Registration() {
  return (
    <div>
    <Header />
    <Banner />
    <Form />
    <Footer />
    </div>
  )
}

export default Registration