import styled from "styled-components";
import Slider from "react-slick";

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
`;

export const Wrapper = styled.div`
    width: 90%;
    max-width: 1280px;
    padding-top: 90px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 48em) {
        padding-top: 50px;
    }

      @media only screen and (max-width: 30em) {
       margin-bottom: 0;
    }
`;

export const Texts = styled.div`
    margin-bottom: 50px;
    padding: 0 20px;

    @media only screen and (max-width: 67em) {
        margin-bottom: 30px;
    }

    @media only screen and (max-width: 48em) {
        margin-bottom: 20px;
    }
`;

export const Title = styled.h1`
    color: #10a74b;
    font-size: 4rem;
    padding-left: 100px;
    font-weight: bold;
    padding-bottom: 15px;

    @media only screen and (max-width: 67em) {
        padding-left: 40px;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
        padding-left: 0;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const Subtitle = styled.h5`
    font-size: 1.5rem;
    padding-left: 350px;
    letter-spacing: 0.15em;
    color: #10a74b;

    @media only screen and (max-width: 67em) {
        padding-left: 100px;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1rem;
        padding-left: 0;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;

export const ItemContainer = styled.div`
    padding: 0 1rem;

    @media only screen and (max-width: 30em) {
        padding: 0 15px;
    }
`;

export const Item = styled.div`
    background: #10a74b;
    height: 450px;
    padding: 60px 35px;
    color: #fff;
    transition: 0.5s;

    &:hover {
        color: #10a74b;
        background-color: white;
        transition: 0.3s ease-out;
        box-shadow: 0 10px 10px gray;
    }

    @media only screen and (max-width: 30em) {
        height: 550px;
    }
`;

export const ItemImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const ItemInfo = styled.div`
    font-size: 1.375rem;
    padding: 35px 0;
    font-weight: bold;
    cursor: pointer;

    @media only screen and (max-width: 67em) {
        font-size: 1.25rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;

export const ItemLink = styled.a`
    text-decoration: none;
    color: white;

    &:hover {
        color: #10a74b;
        text-decoration: underline;
    }
`;

export const StyledSlider = styled(Slider)`
    padding-bottom: 20px;
    .slick-prev {
        width: 3rem;
        height: 3rem;
        left: -3.4rem;

        @media only screen and (max-width: 67em) {
            width: 2.5rem;
            height: 2.5rem;
            left: -2.4rem;
        }

        @media only screen and (max-width: 30em) {
            width: 2rem;
            height: 2rem;
            left: -1.4rem;
        }
    }

    .slick-next {
        width: 3rem;
        height: 3rem;
        right: -3.4rem;

        @media only screen and (max-width: 67em) {
            width: 2.5rem;
            height: 2.5rem;
            right: -2.4rem;
        }

        @media only screen and (max-width: 30em) {
            width: 2rem;
            height: 2rem;
            right: -1.4rem;
        }
    }

    .slick-arrow {
        border: 1px solid #10a74b;
        background-color: white;
        border-radius: 50%;

        @media only screen and (max-width: 30em) {
            border: none;
            border-radius: none;
        }
    }

    .slick-prev:before,
    .slick-next:before {
        color: #10a74b !important;
    }
`;

export const PostDate = styled.div`
    margin-bottom: 13px;
    line-height: 1.1;
    font-style: italic;
`;

export const PostTitle = styled.div`
    font-size: 22px;
    line-height: 1.2;
    margin-bottom: 13px;
`;

export const PostSubtitle = styled.div`
    font-size: 16px;
    line-height: 1.7;
`;
