import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
`;

export const Wrapper = styled.div`
    padding: 50px 20px;
    width: 1280px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: 30em) {
        padding: 30px 0 95px;
    }
`;

export const Lists = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex: 3;

    @media only screen and (max-width: 67em) {
        display: flex;
        flex-direction: column;
    }

    @media only screen and (max-width: 48em) {
        display: none;
    }
`;

export const List = styled.ul`
    list-style: none;
    margin-left: 10px;
    padding: 0;
    flex: 2;

    @media only screen and (max-width: 67em) {
        flex: 3;
    }
`;

export const ListItem = styled.li`
    margin-bottom: 20px;
    color: black;
    cursor: pointer;
    font-weight: bold;

    @media only screen and (max-width: 48em) {
        font-size: 1rem;
    }
`;

export const ListLink = styled.a`
    text-decoration: none;
    cursor: pointer;
    color: inherit;
`;

export const Logo = styled.img`
    width: 50%;
    margin-top: -35px;

    @media only screen and (max-width: 67em) {
        width: 80%;
    }
    @media only screen and (max-width: 48em) {
        width: 70%;
        margin-top: -15px;
    }
`;

export const LogoContainer = styled.div`
    flex: 2;
    width: 50%;

    @media only screen and (max-width: 30em) {
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 15px;
        text-align: center;
    }
`;

export const ListItemInfo = styled.li`
    padding-bottom: 7px;
    display: flex;
    color: black;
    line-height: 1.2;
`;

export const ListItemWrapper = styled.div`
    margin-bottom: 20px;
    margin-left: 39px;

    @media only screen and (max-width: 67em) {
        padding-right: 10px;
        margin-left: 19px;
    }

    @media only screen and (max-width: 67em) {
        margin-left: 0;
    }
`;
export const IconContainer = styled.div`
    display: flex;
    gap: 7px;
    color: #10a74b;
    padding-right: 7px;
`;

export const TopContainer = styled.div`
    display: flex;

    @media only screen and (max-width: 30em) {
        display: flex;
        flex-direction: column;
        margin: 0 20px;
    }
`;

export const BottomContainer = styled.div`
    display: flex;
    border-top: 1px solid #c5c5c5;
    height: 70px;
    padding: 30px 0;
    width: 100%;

    @media only screen and (max-width: 30em) {
        display: flex;
        flex-direction: column;
        padding: 30px 20px;
    }
`;

export const SocialMedia = styled.div`
    flex: 2;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    float: right;
    gap: 17px;
    margin-top: 25px;

    @media only screen and (max-width: 30em) {
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }
`;

export const Texts = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
`;

export const Text = styled.span`
    padding-bottom: 15px;
`;

export const Link = styled.a`
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    list-style-type: none;
    color: black;
`;
