import React, { useContext } from "react";
import {
    Container,
    Wrapper,
    Title,
    ItemContainer,
    Item,
    ItemImg,
    ItemTitle,
    ItemSubtitle,
    StyledSlider,
    ItemImgContainer,
} from "./sectionsComponents";
import { LanguageContext } from "../../../containers/Language";
import qolOner from "../.././../images/educational-work/section/photo1669753424.jpeg";
import robot from "../.././../images/educational-work/section/IMG_5459.jpeg";
import intellektum from "../.././../images/educational-work/section/IMG_5494.jpeg";
import taekvondo from "../.././../images/educational-work/section/IMG_5542.jpeg";
import kures from "../.././../images/educational-work/section/IMG_5538.jpeg";
import dombyra from "../.././../images/educational-work/section/DSC08144.jpeg";
import art from "../.././../images/educational-work/section/DSC08129.jpeg";
import shahmat from "../.././../images/educational-work/section/DSC08113.jpeg";
import cooking from "../.././../images/educational-work/section/DSC08108.jpeg";
import bi from "../.././../images/educational-work/section/IMG_5591.jpeg";
import volleyball from "../.././../images/educational-work/section/IMG_5614.PNG";
import football from "../.././../images/educational-work/section/IMG_5615.PNG";
import tennis from "../.././../images/educational-work/section/IMG_5616.PNG";
import vokal from "../.././../images/educational-work/section/IMG_5617.PNG";
import english from "../.././../images/educational-work/section/IMG_5621.PNG";
import kazak from "../.././../images/educational-work/section/IMG_5622.PNG";

function Sections() {
    const { dictionary } = useContext(LanguageContext);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
        ],
    };

    return (
        <Container id="sections">
            <Wrapper>
                <Title>{dictionary.sections.title}</Title>
                <StyledSlider {...settings}>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={shahmat} />
                            </ItemImgContainer>
                            <ItemTitle>Шахмат</ItemTitle>
                            <ItemSubtitle>
                                Оқушылардың ойын шоғырландыруына, жүйелеуге және
                                шешім шығаруға үйретеді, алдына қойған мақсатына
                                жетуге, төзімділікке тәрбиелейді.
                            </ItemSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={cooking} />
                            </ItemImgContainer>
                            <ItemTitle>Ас мәзірі</ItemTitle>
                            <ItemSubtitle>
                                Оқушыларды аспаздық өнердің қыр-сыры мен әлем
                                халықтарының асханасымен таныстыру, дәмді әрі
                                тәтті кондитерлік өнімдерді дайындауға үйрету,
                                дұрыс тамақтануды насихаттау.
                            </ItemSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={taekvondo} />
                            </ItemImgContainer>
                            <ItemTitle>Таеквондо</ItemTitle>
                            <ItemSubtitle>
                                Спортшыға қажетті әдіс-тәсілін, күшін,
                                шыдамдылығын, ептілігін, иілгіштігін бойына
                                сіңіріп, жетілдіре тәрбиелеу.
                            </ItemSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={dombyra} />
                            </ItemImgContainer>
                            <ItemTitle>Домбыра</ItemTitle>
                            <ItemSubtitle>
                                Домбыра тарту шеберлігін дұрыс меңгеруге,
                                оқушының өнерге деген ынта-ықыласын өсіріп,
                                эстетикалық талғамын арттыруға, музыканы дұрыс
                                тыңдап, тереңдей түсіне білуге тәрбиелеуді
                                мақсат етеді.
                            </ItemSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={intellektum} />
                            </ItemImgContainer>
                            <ItemTitle>Интеллектум</ItemTitle>
                            <ItemSubtitle>
                                Балалардың танымдық іс-әрекетін, ойлау
                                қабілетін, ой белсенділігін көру-есту дағдылары
                                арқылы ойын түрінде қабылдауға негізделген оқу
                                жүйесі.
                            </ItemSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={bi} />
                            </ItemImgContainer>
                            <ItemTitle>Би</ItemTitle>
                            <ItemSubtitle>
                                Балалардың би өнеріне деген қызығушылығын
                                арттырып, шығармашылық қабілетін дамыту.
                            </ItemSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={robot} />
                            </ItemImgContainer>
                            <ItemTitle>Робототехника, стем</ItemTitle>
                            <ItemSubtitle>
                                Құрал-жабдықтар көмегімен пайдалы дағдылар мен
                                ғылыми-техникалық бағыттағы креативті
                                шығармашылық ойлауды ынталандыру.
                            </ItemSubtitle>
                        </Item>
                    </ItemContainer>

                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={art} />
                            </ItemImgContainer>
                            <ItemTitle>Арт</ItemTitle>
                            <ItemSubtitle>
                                Бейнелеу өнерінің барлық әдіс-тәсілдерін
                                үйретіп, көркемдік білім беру, оқушының бойына
                                эстетикалық талғам қалыптастыру.
                            </ItemSubtitle>
                        </Item>
                    </ItemContainer>

                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={qolOner} />
                            </ItemImgContainer>
                            <ItemTitle>Қолөнер</ItemTitle>
                            <ItemSubtitle>
                                Оқушыларды қолөнерге баулу, қызығушылықтарын,
                                шығармашылық белсенділіктерін арттыру,
                                жинақылыққа, төзімділікке, икемділікке,
                                ұқыптылыққа, тиянақтылыққа тәрбиелеу.
                            </ItemSubtitle>
                        </Item>
                    </ItemContainer>

                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={kures} />
                            </ItemImgContainer>
                            <ItemTitle>Қазақша күрес</ItemTitle>
                            <ItemSubtitle>
                                Оқушылар күрес тәсілі ережесін үйренеді, ұлттық
                                спорт түрлеріне деген қызығушылығын арттырады.{" "}
                            </ItemSubtitle>
                        </Item>
                    </ItemContainer>

                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={tennis} />
                            </ItemImgContainer>
                            <ItemTitle>Үлкен теннис</ItemTitle>
                            <ItemSubtitle>
                                Үстел теннис ойыны техникасы мен тактикасын
                                меңгерту, оқушыларды әр түрлі деңгейдегі
                                жарыстарға дайындау.
                            </ItemSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={volleyball} />
                            </ItemImgContainer>
                            <ItemTitle>Волейбол</ItemTitle>
                            <ItemSubtitle>
                                Оқушыларға волейбол ойынының техникасы мен
                                тактикасын түсіндіру, денсаулығын нығайту,
                                достық пен өзара көмек көрсетуге тәрбиелеу.
                            </ItemSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={football} />
                            </ItemImgContainer>
                            <ItemTitle>Футбол</ItemTitle>
                            <ItemSubtitle>
                                Балалардың шыдамдылық , шапшаңдылық, ой желісін
                                дәлдеп алыстан болжау қасиетін дамытады.
                            </ItemSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={vokal} />
                            </ItemImgContainer>
                            <ItemTitle>Вокал</ItemTitle>
                            <ItemSubtitle>
                                Жеке тұлғаны ән үйрену арқылы, жан- жақты
                                дамыту, көркемдік талғамы мен шығармашылық
                                қабілетін қалыптастыруға, алған білімдерін
                                сабақта және өмірде пайдалана білуіне ықпал
                                жасау.
                            </ItemSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={kazak} />
                            </ItemImgContainer>
                            <ItemTitle>Қазақша үйренейік</ItemTitle>
                            <ItemSubtitle>
                                Балалардың білімін тереңдету, қабілетін дамыту,
                                қазақ тіліне қызығушылығын арттыру.
                            </ItemSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={english} />
                            </ItemImgContainer>
                            <ItemTitle>Ағылшын тілі</ItemTitle>
                            <ItemSubtitle>
                                Ағылшын тілін үйрену барысында балалар ауызекі
                                сөйлеуді түсінуді, өз ойларын шет тілінде
                                білдіруді, жазбаша жаттығуларды орындауды,
                                мәтіндерді оқып, аударуды үйренеді.
                            </ItemSubtitle>
                        </Item>
                    </ItemContainer>
                </StyledSlider>
            </Wrapper>
        </Container>
    );
}

export default Sections;
