import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;

export const Wrapper = styled.div`
    width: 1280px;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin: 50px 0 90px;

    @media only screen and (max-width: 67em) {
        margin: 30px 0 90px;
    }

    @media only screen and (max-width: 48em) {
        margin: 15px 0 75px;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
        flex-direction: column-reverse;
        padding: 0 15px;
        margin: 15px 0 50px;
    }
`;

export const PriceItem = styled.div`
    background: #f6f6f6;
    padding: 45px 75px 30px;

    @media only screen and (max-width: 67em) {
        font-size: 1.6rem;
    }
    @media only screen and (max-width: 48em) {
        padding: 35px 65px 20px;
    }

    @media only screen and (max-width: 30em) {
        padding: 20px;
    }
`;


export const PriceGrade = styled.div`
    color: #1a1a4d;
    font-weight: 800;
    font-size: 1.9rem;
    padding-bottom: 18px;
    line-height: 1;
    border-bottom: 1px solid #cfcfcf;

    @media only screen and (max-width: 67em) {
        font-size: 1.6rem;
    }
    @media only screen and (max-width: 48em) {
        font-size: 1.5rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.3rem;
    }
`;

export const PriceInfo = styled.div`
    padding-top: 16px;
`;

export const Table = styled.div`
    border-collapse: collapse;
    width: 100%;
    overflow-x:auto;
`;

export const Tr = styled.tr``;

export const Th = styled.th`
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    border: 1px solid #ddd;
    padding: 8px;
    font-weight: 500;
    font-size: 1.6rem;

    @media only screen and (max-width: 67em) {
        font-size: 1.3rem;
    }
    @media only screen and (max-width: 48em) {
        font-size: 1.2rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;
