import React from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Banner from "../components/educational-work/banner/Banner";
import Navigation from "../components/educational-work/navigation/Navigation";
import Sections from "../components/educational-work/sections/Sections";
import Parents from "../components/educational-work/parents/Parents";
import Activities from "../components/educational-work/activites/Activities";
import Book from "../components/educational-work/book/Book";
import Values from "../components/educational-work/values/Values.jsx";
import Plan from "../components/educational-work/plan/Plan.jsx";
import Internat from "../components/educational-work/internat/Internat.jsx";
import InternatRules from "../components/educational-work/internat-rules/InternatRules.jsx";
import InternatInfo from "../components/educational-work/internat-info/InternatInfo.jsx";


function EducationalWork() {
    return (
        <div>
            <Header />
            <Banner />
            <Navigation />
            <Values />
            <Sections />
            <Book />
            <Parents />
            <Activities />
            <Internat />
            <InternatInfo />
            <InternatRules />
            <Plan />
            <Footer />
        </div>
    );
}

export default EducationalWork;
