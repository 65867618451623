import React from 'react';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import NewsSeparate from '../components/news-page/NewsSeparate';

function NewsPage() {
    return (
        <div>
            <Header />
            <NewsSeparate />
            <Footer />
        </div>
    );
}

export default NewsPage;
