import React, { useContext } from 'react';
import {
    Container,
    Item,
    ItemContainer,
    ItemImgContainer,
    Title,
    Wrapper,
    Texts,
    ItemImg,
    ItemTitle,
    ItemInfo,
    StyledSlider,
} from './kuratorComponents';
import kurator1 from '../.././../images/about-school/kurator/kurator1.png';
import kurator2 from '../.././../images/about-school/kurator/kurator2.png';
import kurator3 from '../.././../images/about-school/kurator/kurator3.png';
import kurator4 from '../.././../images/about-school/kurator/kurator4.png';
import kurator5 from '../.././../images/about-school/kurator/kurator5.png';
import kurator6 from '../.././../images/about-school/kurator/kurator6.png';
import kurator7 from '../.././../images/about-school/kurator/kurator7.png';
import kurator8 from '../.././../images/about-school/kurator/kurator8.png';
import kurator9 from '../.././../images/about-school/kurator/kurator9.png';
import kurator10 from '../.././../images/about-school/kurator/kurator10.png';
import { LanguageContext } from '../../../containers/Language';

function Teacher() {
    const { dictionary } = useContext(LanguageContext);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
        ],
    };

    return (
        <Container id="kurator">
            <Wrapper>
                <Texts>
                    <Title>{dictionary.kurator.title}</Title>
                </Texts>
                <StyledSlider {...settings}>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={kurator1} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Ералы Мәдина Ералықызы</b>
                            </ItemTitle>
                            <ItemInfo>1 "A" сынып кураторы</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={kurator2} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Ибаділда Назым Мұратбекқызы </b>
                            </ItemTitle>
                            <ItemInfo>1 "Ә" сынып кураторы</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={kurator3} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Серикбаева Айдана Кеңесбайқызы</b>
                            </ItemTitle>
                            <ItemInfo>2 "A" сынып кураторы</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={kurator4} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Жеңісбекқызы Шәмсия </b>
                            </ItemTitle>
                            <ItemInfo>2 "Ә" сынып кураторы</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={kurator5} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Махамбет Жанар Жолдасбай</b>
                            </ItemTitle>
                            <ItemInfo>3 "A" сынып кураторы</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={kurator6} />
                            </ItemImgContainer>
                            <ItemTitle>
                            <b>Нұрғалиева Индира Қолғанатқызы</b>
                            </ItemTitle>
                            <ItemInfo>3 "Ә" сынып кураторы</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={kurator7} />
                            </ItemImgContainer>
                            <ItemTitle>
                            <b>Сембаева Ляззат Жарасқызы</b>
                            </ItemTitle>
                            <ItemInfo>4 - сынып кураторы</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={kurator8} />
                            </ItemImgContainer>
                            <ItemTitle>
                                
                                <b>Төлеу Гүлшат Дарханқызы</b>
                            </ItemTitle>
                            <ItemInfo>5 - сынып кураторы</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={kurator9} />
                            </ItemImgContainer>
                            <ItemTitle>
                                
                                <b>Мүслім Ұлбала Нұржанқызы</b>
                            </ItemTitle>
                            <ItemInfo>6 - сынып кураторы</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={kurator10} />
                            </ItemImgContainer>
                            <ItemTitle>
                                
                                <b>Биғалиқызы Динара</b>
                            </ItemTitle>
                            <ItemInfo>7 -  сынып кураторы</ItemInfo>
                        </Item>
                    </ItemContainer>
                </StyledSlider>
            </Wrapper>
        </Container>
    );
}

export default Teacher;
