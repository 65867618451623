import styled from "styled-components";

export const Container = styled.div`
overflow: hidden;
display: block;
`;

export const Wrapper = styled.div`
    position: relative;
    background-color: black;
    width: 100%;
    height: 530px;

    @media only screen and (max-width: 67em) {
        height: 400px;
    }

    @media only screen and (max-width: 48em) {
        height: 350px;
    }

    @media only screen and (max-width: 30em) {
        height: 280px;
    }
`;

export const BannerImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
    vertical-align: middle;
`;

export const Text = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    z-index: 1;
`;

export const InfoContainer = styled.div`
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;


    @media only screen and (min-width: 79.99em) {
        width: 80em;
        margin-left: 300px;
    }

    @media only screen and (min-width: 75em) {
        width: 73.125em;
    }

    @media screen and (min-width: 67em) {
        width: 60.625em;
    }

    @media only screen and (min-width: 48em) {
        width: 46.875em;
    }
`;

export const Info = styled.div`    
    position: relative;
    border-left: 5px solid white;
    padding-left: 45px;

    @media only screen and (max-width: 30em) {
        padding-left: 25px;
    }
`;

export const Title = styled.h1`
    color: white;
    margin-top: 0;
    line-height: 1;
    font-weight: 600;
    font-size: 4rem;
    margin-bottom: 0;
    text-transform: uppercase;

    @media only screen and (max-width: 67em) {
        font-size: 3.125rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.5rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.5rem;
    }
`;

export const Subtitle = styled.p`
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 14px;

    @media only screen and (max-width: 67em) {
        font-size: 1.3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1.1rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;
