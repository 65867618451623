import React, {useContext} from 'react';
import {
    Container,
    Wrapper,
    InfoContainer,
    MainContainer,
    ImgContainer,
    Title,
    Subtitle,
    Img,
    List,
    Lists,
    TopImgContainer,
    TopImg
} from "./bookComponents";
import kitap1 from "../../../images/educational-work/kitap.JPG";
import kitap2 from "../../../images/educational-work/kitap2.PNG";
import { LanguageContext } from "../../../containers/Language";

function Mission() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="book">
            <Wrapper>
                <Title>{dictionary.book.title}</Title>
                <MainContainer>
                    <InfoContainer>
                        <Subtitle>
                            <b>{dictionary.book.subtitle1}</b>
                        </Subtitle>
                        <Subtitle>
                        {dictionary.book.subtitle2}
                        </Subtitle>
                        <Lists>
                            <List>
                            {dictionary.book.list1}
                            </List>
                            <List>
                            {dictionary.book.list2}
                            </List>
                            <List>
                            {dictionary.book.list3}
                            </List>
                            <List>
                            {dictionary.book.list4}
                            </List>
                            <List>
                            {dictionary.book.list5}
                            </List>
                            <List>
                            {dictionary.book.list6}
                            </List>
                        </Lists>
                    </InfoContainer>
                    <TopImgContainer >
                        <TopImg src={kitap1} />
                    </TopImgContainer>
                </MainContainer>
                <MainContainer second>
                    <ImgContainer second>
                        <Img src={kitap2} />
                    </ImgContainer>
                    <InfoContainer secondary>
                        <Subtitle>
                            <b>{dictionary.book.subtitle3}</b>
                        </Subtitle>
                        <Subtitle>
                        {dictionary.book.subtitle4}
                        </Subtitle>
                        <Lists>
                            <List>
                            {dictionary.book.list7}
                            </List>
                            <List>{dictionary.book.list8}</List>
                            <List>
                            {dictionary.book.list9}
                            </List>
                            <List>
                            {dictionary.book.list10}
                            </List>
                            <List>{dictionary.book.list11} </List>
                            <List>
                            {dictionary.book.list12}
                            </List>{" "}
                            <List>
                            {dictionary.book.list13}
                            </List>{" "}
                            <List>{dictionary.book.list14}</List>{" "}
                            <List>
                            {dictionary.book.list15}
                            </List>{" "}
                            <List>
                            {dictionary.book.list16}
                            </List>{" "}
                        </Lists>
                    </InfoContainer>
                </MainContainer>
            </Wrapper>
        </Container>
    );
}

export default Mission;
