import React from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Banner from "../components/about-school/banner/Banner";
import Mission from "../components/about-school/mission/Mission";
import Values from "../components/about-school/values/Values";
import Navigation from "../components/about-school/navigation/Navigation";
import History from "../components/about-school/history/History";
import School from "../components/about-school/school/School";
import Administration from "../components/about-school/administation/Administration";
import Teacher from "../components/about-school/teacher/Teacher";
import Kurator from "../components/about-school/kurator/Kurator";
import Uniform from "../components/about-school/uniform/Uniform";
import Contact from "../components/about-school/contact/Contact";

function AboutSchool() {
    return (
        <div>
            <Header />
            <Banner />
            <Navigation />
            <Mission />
            <Values />
            <History />
            <School />
            <Uniform />
            <Administration />
            <Teacher />
            <Kurator />
            <Contact />
            <Footer />
        </div>
    );
}

export default AboutSchool;
