import React from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Banner from "../components/education-cost/banner/Banner";
import Navigation from "../components/education-cost/navigation/Navigation";
import Price from "../components/education-cost/price/Price";
import Online from "../components/education-cost/online/Online";
import PriceDetails from "../components/education-cost/price-details/PriceDetails";
import BankAccount from "../components/education-cost/bank-account/BankAccount";

function EducationCost() {
    return (
        <div>
            <Header />
            <Banner />
            <Navigation />
            <Price />
            <PriceDetails />
            <Online />
            <BankAccount />
            <Footer />
        </div>
    );
}

export default EducationCost;
