import React, { useContext } from "react";
import {
    Container,
    Wrapper,
    InfoContainer,
    Title,
    Subtitle,
    Top,
    Bottom,
    BottomImgContainer,
    BottomImg,
    StyledSlider,
} from "./schoolComponents";
import school1 from "../.././../images/about-school/mektep/IMG_5237.jpeg";
import school2 from "../.././../images/about-school/mektep/IMG_5243.jpeg";
import school3 from "../.././../images/about-school/mektep/IMG_5253.jpeg";
import school4 from "../.././../images/about-school/mektep/IMG_5363.jpeg";
import school5 from "../.././../images/about-school/mektep/IMG_5365.jpeg";
import school6 from "../.././../images/about-school/mektep/IMG_5366.jpeg";
import school9 from "../.././../images/about-school/mektep/IMG_5456.jpeg";
import school10 from "../.././../images/about-school/mektep/IMG_5457.jpeg";
import school11 from "../.././../images/about-school/mektep/IMG_5458.jpeg";
import school12 from "../.././../images/about-school/mektep/IMG_5466.jpeg";
import school13 from "../.././../images/about-school/mektep/IMG_5467.jpeg";
import school14 from "../.././../images/about-school/mektep/IMG_5468.jpeg";
import school15 from "../.././../images/about-school/mektep/IMG_5469.jpeg";
import school16 from "../.././../images/about-school/mektep/IMG_5471.jpeg";
import school17 from "../.././../images/about-school/mektep/IMG_5477.jpeg";
import school18 from "../.././../images/about-school/mektep/IMG_5482.jpeg";
import school19 from "../.././../images/about-school/mektep/IMG_5485.jpeg";
import school20 from "../.././../images/about-school/mektep/IMG_5486.jpeg";
import school21 from "../.././../images/about-school/mektep/IMG_5493.jpeg";
import school22 from "../.././../images/about-school/mektep/IMG_5496.jpeg";
import school23 from "../.././../images/about-school/mektep/IMG_5497.jpeg";
import school24 from "../.././../images/about-school/mektep/IMG_5498.jpeg";
import school25 from "../.././../images/about-school/mektep/IMG_5499.jpeg";
import school26 from "../.././../images/about-school/mektep/IMG_5501.jpeg";

import { LanguageContext } from "../../../containers/Language";

function School() {
    const { dictionary } = useContext(LanguageContext);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
        ],
    };

    return (
        <Container id="school">
            <Wrapper>
                <Top>
                    <InfoContainer>
                        <Title>{dictionary.school.title}</Title>
                        <Subtitle>{dictionary.school.subtitle}</Subtitle>
                    </InfoContainer>
                </Top>
                <Bottom>
                    <StyledSlider {...settings}>
                        <BottomImgContainer>
                            <BottomImg src={school4} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school5} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school23} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school24} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school25} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school26} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school2} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school3} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school1} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school6} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school9} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school10} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school11} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school12} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school13} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school14} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school15} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school16} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school17} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school18} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school19} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school20} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school21} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={school22} />
                        </BottomImgContainer>
                     
                    </StyledSlider>
                </Bottom>
            </Wrapper>
        </Container>
    );
}

export default School;
