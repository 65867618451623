import React, {useContext} from 'react';
import {
    Container,
    Wrapper,
    BannerImg,
    InfoContainer,
    Text,
    Info,
    Title,
    Subtitle,
} from "./bannerComponents";
import banner from "../.././../images/registration/IMG_5935.PNG";
import { LanguageContext } from "../../../containers/Language";

function Banner() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container>
            <Wrapper>
                <BannerImg src={banner} />
                <Text>
                <InfoContainer>
                <Info>
                    <Title>{dictionary.bannerRegistration.title}</Title>
                    <Subtitle>{dictionary.bannerRegistration.subtitle}</Subtitle>
                </Info>
                </InfoContainer>
                </Text>
            </Wrapper>
        </Container>
    );
}

export default Banner;
