import React, { useContext } from "react";
import {
    Container,
    Wrapper,
    InfoContainer,
    ImgContainer,
    Title,
    Subtitle,
    Img
} from "./teacherSuccessComponents";
import teacherSuccess from "../../../images/success/teacherSuccess.JPG";
import { LanguageContext } from "../../../containers/Language";

function TeacherSuccess() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="teacher-success">
            <Wrapper>
            <ImgContainer>
                    <Img src={teacherSuccess} />
                </ImgContainer>
                <InfoContainer>
                    <Title>{dictionary.teacherSuccess.title} </Title>
                    <Subtitle>{dictionary.teacherSuccess.subtitle1}</Subtitle>
                    <Subtitle>{dictionary.teacherSuccess.subtitle2}</Subtitle>
                    <Subtitle>{dictionary.teacherSuccess.subtitle3}</Subtitle>
                    <Subtitle>{dictionary.teacherSuccess.subtitle4}</Subtitle>
                    <Subtitle>{dictionary.teacherSuccess.subtitle5}</Subtitle>
                    <Subtitle>{dictionary.teacherSuccess.subtitle6}</Subtitle>
                    <Subtitle><em>{dictionary.teacherSuccess.subtitle7}</em></Subtitle>
                    <Subtitle>1. {dictionary.teacherSuccess.subtitle8}</Subtitle>
                    <Subtitle>2. {dictionary.teacherSuccess.subtitle9}</Subtitle>
                    <Subtitle><em>{dictionary.teacherSuccess.subtitle10}</em></Subtitle>
                    <Subtitle>1. {dictionary.teacherSuccess.subtitle11}</Subtitle>
                    <Subtitle>2. {dictionary.teacherSuccess.subtitle12}</Subtitle>
                    <Subtitle>3. {dictionary.teacherSuccess.subtitle13}</Subtitle>
                    <Subtitle>4. {dictionary.teacherSuccess.subtitle14}</Subtitle>
                    <Subtitle><em>{dictionary.teacherSuccess.subtitle15}</em></Subtitle>
                    <Subtitle>1. {dictionary.teacherSuccess.subtitle16}</Subtitle>
                    <Subtitle>2. {dictionary.teacherSuccess.subtitle17}</Subtitle>
                    <Subtitle>3. {dictionary.teacherSuccess.subtitle18}</Subtitle>
                    <Subtitle>4. {dictionary.teacherSuccess.subtitle19}</Subtitle>
                    <Subtitle>5.{dictionary.teacherSuccess.subtitle20}</Subtitle>
                    <Subtitle>6. {dictionary.teacherSuccess.subtitle21}</Subtitle>

                </InfoContainer>
              
            </Wrapper>
        </Container>
    );
}

export default TeacherSuccess;
