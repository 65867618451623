import React, { useContext } from 'react';
import {
    Container,
    Item,
    ItemLink,
    ItemContainer,
    Title,
    Wrapper,
    Texts,
    Subtitle,
    StyledSlider,
    PostDate,
    PostTitle,
    PostSubtitle,
} from './newsComponents';
import { LanguageContext } from '../../../containers/Language';

function News() {
    const { dictionary } = useContext(LanguageContext);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
        ],
    };

    return (
        <Container>
            <Wrapper>
                <Texts>
                    <Title> {dictionary.news.title}</Title>
                    <Subtitle>{dictionary.news.subtitle}</Subtitle>
                </Texts>
                <StyledSlider {...settings}>
                    <ItemContainer>
                        <Item>
                            <ItemLink href="/admission#examination-results">
                                <PostDate>20 - Мамыр</PostDate>
                                <PostTitle>ҚАБЫЛДАУ ЕМТИХАНЫ НӘТИЖЕЛЕРІ 2024</PostTitle>
                            </ItemLink>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemLink href="/careers-page">
                                <PostDate>20-наурызға дейін</PostDate>
                                <PostTitle>Қабылдау жүреді</PostTitle>
                                <PostSubtitle>
                                    ВАКАНСИЯЛАР
                                </PostSubtitle>
                            </ItemLink>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemLink href="/news-page">
                                <PostDate>{dictionary.news.postDate1}</PostDate>
                                <PostTitle>{dictionary.news.postTitle1}</PostTitle>
                                <PostSubtitle>
                                    {dictionary.news.postSubtitle1}
                                </PostSubtitle>
                            </ItemLink>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate2}</PostDate>
                            <PostTitle>{dictionary.news.postTitle2}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle2}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate3}</PostDate>
                            <PostTitle>{dictionary.news.postTitle3}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle3}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate4}</PostDate>
                            <PostTitle>{dictionary.news.postTitle4}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle4}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate5}</PostDate>
                            <PostTitle>{dictionary.news.postTitle5}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle5}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate6}</PostDate>
                            <PostTitle>{dictionary.news.postTitle6}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle6}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate7}</PostDate>
                            <PostTitle>{dictionary.news.postTitle7}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle7}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate8}</PostDate>
                            <PostTitle>{dictionary.news.postTitle8}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle8}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate9}</PostDate>
                            <PostTitle>{dictionary.news.postTitle9}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle9}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate10}</PostDate>
                            <PostTitle>{dictionary.news.postTitle10}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle10}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate11}</PostDate>
                            <PostTitle>{dictionary.news.postTitle11}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle11}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate12}</PostDate>
                            <PostTitle>{dictionary.news.postTitle12}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle12}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate13}</PostDate>
                            <PostTitle>{dictionary.news.postTitle13}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle13}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate14}</PostDate>
                            <PostTitle>{dictionary.news.postTitle14}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle14}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate15}</PostDate>
                            <PostTitle>{dictionary.news.postTitle15}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle15}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate16}</PostDate>
                            <PostTitle>{dictionary.news.postTitle16}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle16}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate17}</PostDate>
                            <PostTitle>{dictionary.news.postTitle17}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle17}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate18}</PostDate>
                            <PostTitle>{dictionary.news.postTitle18}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle18}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate19}</PostDate>
                            <PostTitle>{dictionary.news.postTitle19}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle19}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <PostDate>{dictionary.news.postDate20}</PostDate>
                            <PostTitle>{dictionary.news.postTitle20}</PostTitle>
                            <PostSubtitle>
                                {dictionary.news.postSubtitle20}
                            </PostSubtitle>
                        </Item>
                    </ItemContainer>
                </StyledSlider>
            </Wrapper>
        </Container>
    );
}

export default News;
