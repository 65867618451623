import React, { useContext } from "react";
import { languageOptions } from "../../languages";
import { LanguageContext } from "../../containers/Language";
import {
    LanguageOption,
    FilterLanguage,
    FilterLanguageOption,
} from "./headerComponents";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function LanguageSelector() {
    const { userLanguage, userLanguageChange } = useContext(LanguageContext);

    const handleLanguageChange = (e) => userLanguageChange(e.target.value);
    return (
        <LanguageOption>
            <FontAwesomeIcon
                icon={faGlobe}
                style={{ color: "#10a74b", marginRight: "-5px" }}
            />
            <FilterLanguage
                onChange={handleLanguageChange}
                value={userLanguage}
            >
                {Object.entries(languageOptions).map(([id, name]) => (
                    <FilterLanguageOption key={id} value={id}>
                        {name}
                    </FilterLanguageOption>
                ))}
            </FilterLanguage>
        </LanguageOption>
    );
}
