import kz from "./kz.json";
import ru from "./ru.json";
import en from "./en.json";

export const dictionaryList = { kz, ru, en };

export const languageOptions = {
    kz: "KZ",
    ru: "RU",
    en: "EN",
};
