import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;

export const Wrapper = styled.div`
    width: 1280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin: 60px 0 90px;

    @media only screen and (max-width: 67em) {
        margin: 90px 0 70px;
    }

    @media only screen and (max-width: 48em) {
        margin: 70px 0 60px;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
        flex-direction: column-reverse;
        padding: 0 15px;
        margin: 50px 0;
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(53% - 125px);

    @media only screen and (max-width: 67em) {
        width: calc(53% - 50px);
        align-self: center;
    }

    @media only screen and (max-width: 48em) {
        width: calc(53% - 40px);
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        margin-bottom: 20px;
    }
`;

export const Title = styled.h1`
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 20px;
    color: #1a1a4d;

    @media only screen and (max-width: 67em) {
        font-size: 3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const Subtitle = styled.p`
    font-size: 1.5rem;
    line-height: 1.7;
    color: gray;
    padding-bottom: 10px;

    @media only screen and (max-width: 67em) {
        font-size: 1.3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1.1rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;

export const ImgContainer = styled.div`
    width: 47%;

    @media only screen and (max-width: 30em) {
        width: 100%;
        height: 300px;
    }
`;

export const Img = styled.img`
    width: 100%;

    @media only screen and (max-width: 67em) {
        height: 100%;
        object-fit: cover;
    }
`;
