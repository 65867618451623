import React, {useContext} from 'react';
import {
    Container,
    Wrapper,
    InfoContainer,
    ImgContainer,
    Title,
    Subtitle,
    Img,
} from "./assessmentComponents";
import { LanguageContext } from "../../../containers/Language";
import evaluation from "../.././../images/education-process/evaluation.jpeg";

function Mission() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="assessment">
            <Wrapper>
                <ImgContainer>
                    <Img src={evaluation} />
                </ImgContainer>
                <InfoContainer>
                    <Title>{dictionary.assessment.title}</Title>
                    <Subtitle>
                    {dictionary.assessment.subtitle1}
                    </Subtitle>
                    <Subtitle>
                    {dictionary.assessment.subtitle2}
                    </Subtitle>
                </InfoContainer>
            </Wrapper>
        </Container>
    );
}

export default Mission;
