import React, { useContext } from "react";
import {
    Container,
    Wrapper,
    Item,
    Icon,
    Title,
    Subtitle,
    StyledSlider,
    ItemContainer
} from "./featureComponents";
import {
    faBookOpen,
    faPerson,
    faSchool,
    faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LanguageContext } from "../../../containers/Language";

function Feature() {
    const { dictionary } = useContext(LanguageContext);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
        ],
    };

    return (
        <Container>
            <Wrapper>
            <StyledSlider {...settings}>
            <ItemContainer>
                <Item>
                    <Icon>
                        <FontAwesomeIcon icon={faBookOpen} className="fa-2xl" />
                    </Icon>
                    <Title>{dictionary.feature.title1}</Title>
                    <Subtitle>
                    {dictionary.feature.subtitle1}
                    </Subtitle>
                </Item>
                </ItemContainer>
                <ItemContainer>
                <Item>
                    <Icon>
                        <FontAwesomeIcon icon={faPerson} className="fa-2xl" />
                    </Icon>
                    <Title>{dictionary.feature.title2}</Title>
                    <Subtitle>
                    {dictionary.feature.subtitle2}
                    </Subtitle>
                </Item>
                </ItemContainer>
                <ItemContainer>
                <Item>
                    <Icon>
                        <FontAwesomeIcon icon={faSchool} className="fa-2xl" />
                    </Icon>

                    <Title>{dictionary.feature.title3}</Title>
                    <Subtitle>
                    {dictionary.feature.subtitle3}
                    </Subtitle>
                </Item>
                </ItemContainer>
                <ItemContainer>
                <Item>
                    <Icon>
                        <FontAwesomeIcon
                            icon={faShieldHalved}
                            className="fa-2xl"
                        />
                    </Icon>
                    <Title>{dictionary.feature.title4}</Title>
                    <Subtitle>
                    {dictionary.feature.subtitle4}
                    </Subtitle>
                </Item>
                </ItemContainer>
                </StyledSlider>
            </Wrapper>
        </Container>
    );
}

export default Feature;
