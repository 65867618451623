import React, { useContext } from "react";
import {
    Container,
    Wrapper,
    InfoContainer,
    Video,
    Title,
    Subtitle,
    Source,
    VideoContainer,
} from "./onlineComponents";
import onlineTolem from "../../../images/education-cost/onlineTolem.mp4";
import { LanguageContext } from "../../../containers/Language";

function Mission() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="online">
            <Wrapper>
                <InfoContainer>
                    <Title>{dictionary.online.title}</Title>
                    <Subtitle>{dictionary.online.subtitle1}</Subtitle>
                    <Subtitle> {dictionary.online.subtitle2}</Subtitle>
                    <Subtitle> </Subtitle>
                </InfoContainer>
                <VideoContainer>
                    <Video controls>
                        <Source src={onlineTolem} type="video/mp4" />
                    </Video>
                </VideoContainer>
            </Wrapper>
        </Container>
    );
}

export default Mission;
