import styled from 'styled-components';
import { Button } from '@mui/material';

export const Container = styled.div`
    color: black;
    display: flex;
    justify-content: center;
`;

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1280px;
    padding: 0 20px;
    margin: 50px 0 90px;
    justify-content: center;

    @media only screen and (max-width: 48em) {
        margin: 50px 0 70px;
    }

    @media only screen and (max-width: 30em) {
        padding: 0 15px;
        margin: 40px 0 60px;
    }
`;

export const FormAndResultContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;

    @media only screen and (max-width: 48em) {
        flex-direction: column;
    }
`;

export const FormContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
`;

export const StyledButton = styled(Button)`
    width: 25%;
    margin-top: 15px;

    @media only screen and (max-width: 48em) {
        width: 35%;
    }
`;

export const Lists = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
`;

export const List = styled.li`
    margin-bottom: 5px;
    font-size: 1.25rem;

    @media only screen and (max-width: 48em) {
        font-size: 1.1rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;
