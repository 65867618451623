import React from 'react';
import Banner from '../components/admission/banner/Banner';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import Navigation from '../components/admission/navigation/Navigation';
import Exam from '../components/admission/exam/Exam';
import Requirement from '../components/admission/samples/Samples';
import Documents from '../components/admission/documents/Documents';
import Step from '../components/admission/steps/Step';
import ExaminationResults from '../components/admission/examination-results/ExaminationResults';

function Admission() {
    return (
        <div>
            <Header />
            <Banner />
            <Navigation />
            <Step />
            <Exam />
            <Requirement />
            <Documents />
            <ExaminationResults />
            <Footer />
        </div>
    );
}

export default Admission;
