import React, { useState, useContext, useRef } from "react";
import emailjs from "emailjs-com";
import {
    Container,
    Wrapper,
    Content,
    ImgContainer,
    Img,
    Text,
    Title,
    Subtitle,
    StyledFormGroup,
    StyledFormControl,
    StyledButton,
    StyledInput,
    Confirm,
    ConfirmText,
    StyledForm,
    InfoContainer,
} from "./formComponents";
import registration from "../.././../images/registration/IMG_5025.JPG";
import { LanguageContext } from "../../../containers/Language";
import { InputLabel, Checkbox } from "@mui/material";
import Alert from "@mui/material/Alert";

export default function Form() {
    const { dictionary } = useContext(LanguageContext);

    const form = useRef();
    const [isChecked, setIsChecked] = useState(false);
    const [isEmailSuccess, setIsEmailSuccess] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        studentsFullName: "",
        parentsFullName: "",
        email: "",
        studentId: "",
        phoneNumber: "",
        classLevel: "",
        additionalInfo: "",
    });

    const sendEmail = async (e) => {
        e.preventDefault();
        setIsFormSubmitted(true);
        try {
            const { text } = await emailjs.sendForm(
                process.env.REACT_APP_SERVICE_ID,
                process.env.REACT_APP_TEMPLATE_ID,
                form.current,
                process.env.REACT_APP_PUBLIC_KEY
            );
            console.info(`Send email response: ${text}`);
            text === "OK" && setIsEmailSuccess(true);
        } catch (error) {
            console.error(`Send email response: ${error}`);
            setIsEmailError(true);
        }
        setIsFormSubmitted(false);
        e.target.reset();
    };

    function emailResponseAlert(isSuccess) {
        return (
            <Alert severity={isSuccess ? "success" : "error"}>
                {isSuccess
                    ? dictionary.form.alertSuccess
                    : dictionary.form.alertError}
            </Alert>
        );
    }

    return (
        <Container id="price">
            <Wrapper>
                <Content>
                    <ImgContainer>
                        <Img src={registration} />
                    </ImgContainer>
                    <InfoContainer>
                        <StyledForm onSubmit={sendEmail} ref={form}>
                            <StyledFormGroup>
                                <Text>
                                    <Title>{dictionary.form.title}</Title>
                                    <Subtitle>
                                        {dictionary.form.subtitle}
                                    </Subtitle>
                                </Text>
                                <StyledFormControl variant="outlined" required>
                                    <InputLabel htmlFor="my-input">
                                        {dictionary.form.inputlabel1}
                                    </InputLabel>
                                    <StyledInput
                                        aria-describedby="my-helper-text"
                                        required
                                        name="name1"
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                studentsFullName:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </StyledFormControl>
                                <StyledFormControl variant="outlined" required>
                                    <InputLabel htmlFor="my-input">
                                        {dictionary.form.inputlabel2}
                                    </InputLabel>
                                    <StyledInput
                                        aria-describedby="my-helper-text"
                                        required
                                        name="name2"
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                parentsFullName: e.target.value,
                                            })
                                        }
                                    />
                                </StyledFormControl>
                                <StyledFormControl required>
                                    <InputLabel htmlFor="my-input">
                                        {dictionary.form.inputlabel23}
                                    </InputLabel>
                                    <StyledInput
                                        aria-describedby="my-helper-text"
                                        required
                                        type="tel"
                                        name="studentId"
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                studentId: e.target.value,
                                            })
                                        }
                                    />
                                </StyledFormControl>
                                <StyledFormControl required>
                                    <InputLabel htmlFor="my-input">
                                        E-mail
                                    </InputLabel>
                                    <StyledInput
                                        aria-describedby="my-helper-text"
                                        required
                                        type="email"
                                        name="email"
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                email: e.target.value,
                                            })
                                        }
                                    />
                                </StyledFormControl>
                                <StyledFormControl required>
                                    <InputLabel htmlFor="my-input">
                                        {dictionary.form.inputlabel3}
                                    </InputLabel>
                                    <StyledInput
                                        aria-describedby="my-helper-text"
                                        required
                                        type="tel"
                                        name="number"
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                phoneNumber: e.target.value,
                                            })
                                        }
                                    />
                                </StyledFormControl>
                                <StyledFormControl required>
                                    <InputLabel htmlFor="my-input">
                                        {dictionary.form.inputlabel4}
                                    </InputLabel>
                                    <StyledInput
                                        aria-describedby="my-helper-text"
                                        required
                                        type="number"
                                        name="class"
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                classLevel: e.target.value,
                                            })
                                        }
                                    />
                                </StyledFormControl>
                                <StyledFormControl>
                                    <InputLabel htmlFor="my-input">
                                        {dictionary.form.inputlabel5}
                                    </InputLabel>
                                    <StyledInput
                                        name="addition"
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                additionalInfo: e.target.value,
                                            })
                                        }
                                    />
                                </StyledFormControl>
                                <Confirm>
                                    <Checkbox
                                        onClick={() => setIsChecked(!isChecked)}
                                    />
                                    <ConfirmText>
                                        {dictionary.form.confirmText}
                                    </ConfirmText>
                                </Confirm>
                                <StyledButton
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    disabled={!isChecked || isFormSubmitted}
                                >
                                    {dictionary.form.button}
                                </StyledButton>
                                {isEmailSuccess && emailResponseAlert(true)}
                                {isEmailError && emailResponseAlert(false)}
                            </StyledFormGroup>
                        </StyledForm>
                    </InfoContainer>
                </Content>
            </Wrapper>
        </Container>
    );
}
