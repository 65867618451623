import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    background-color: #10a74b;
    color: white;
`;

export const Wrapper = styled.div`
    width: 1280px;
    display: flex;
    justify-content: space-between;
    margin: 70px 0;
    padding: 0 20px;

    @media only screen and (max-width: 67em) {
        margin: 40px 0;
    }

    @media only screen and (max-width: 30em) {
        flex-direction: column;
        margin: 10px 0;
        padding: 0 15px;
    }
`;

export const Title = styled.h1`
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 20px;
    color: white;

    @media only screen and (max-width: 67em) {
        font-size: 3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
        
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
        margin-bottom: 10px;
    }
`;


export const ImgContainer = styled.div`
    width: 47%;
    align-self: center;

    @media only screen and (max-width: 67em) {
        margin-top: 0;
    }

    @media only screen and (max-width: 48em) {
        height: 400px;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        height: 530px;
        margin-bottom: 70px;
    }
`;

export const Img = styled.img`
    width: 100%;
    align-self: center;

    @media only screen and (max-width: 67em) {
        height: 100%;
        object-fit: cover;
    }
`;
