import React, {useContext} from 'react';
import {
    Container,
    Wrapper,
    InfoContainer,
    Title,
    Subtitle,
} from "./internatInfoComponents";
import { LanguageContext } from "../../../containers/Language";

function InternatInfo() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="internat-info">
            <Wrapper>
            <InfoContainer>
                    <Title>{dictionary.internatInfo.title}</Title>
                    <Subtitle>{dictionary.internatInfo.subtitle1}</Subtitle>
                    <Subtitle>{dictionary.internatInfo.subtitle2}</Subtitle> 
                    <Subtitle>{dictionary.internatInfo.subtitle3}</Subtitle> 
                    <Subtitle>{dictionary.internatInfo.subtitle4}</Subtitle>
                    <Subtitle>{dictionary.internatInfo.subtitle5}</Subtitle> 
                    <Subtitle>{dictionary.internatInfo.subtitle6}</Subtitle> 
                    <Subtitle>{dictionary.internatInfo.subtitle7}</Subtitle> 
                </InfoContainer>
            </Wrapper>
        </Container>
    );
}

export default InternatInfo;
