import React, {useContext} from "react";
import {
    Container,
    Wrapper,
    Text,
    Content,
    Title, 
    Subtitle,
    Right,
    Left,
    RightActivityItem,
    LeftActivityItem,
    ActivityInfo,
    ActivityTitle,
    ActivityItemSubtitle,
    ActivityVisual,
    ActivityImg1,
    ActivityImg2,
    ActivityImg3
} from "./activityComponents";
import activity3 from "../.././../images/educational-work/activity/activity3.jpg";
import activity2 from "../.././../images/educational-work/parent/parent4.PNG";
import activity1 from "../.././../images/home/DSC04403.jpg";
import { LanguageContext } from "../../../containers/Language";

function Life() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container>
            <Wrapper>
                <Text>
                <Title> {dictionary.activity.title}</Title>
                <Subtitle>{dictionary.activity.subtitle}</Subtitle>
                </Text>
                <Content>
                <Left>
                    <LeftActivityItem topActivityItem>
                        <ActivityInfo topItem>
                            <ActivityTitle>
                            {dictionary.activity.activityTitle}
                            </ActivityTitle>
                            <ActivityItemSubtitle>
                            {dictionary.activity.activityItemSubtitle1}
                            </ActivityItemSubtitle>
                        </ActivityInfo>
                        <ActivityVisual>
                            <ActivityImg1 src={activity1}/>
                        </ActivityVisual>
                    </LeftActivityItem>
                    <LeftActivityItem>
                        <ActivityVisual>
                            <ActivityImg2 src={activity2} />
                        </ActivityVisual>
                        <ActivityInfo>
                            <ActivityTitle>{dictionary.activity.activityTitle2}</ActivityTitle>
                            <ActivityItemSubtitle>
                            {dictionary.activity.activityItemSubtitle2}
                            </ActivityItemSubtitle>
                        </ActivityInfo>
                    </LeftActivityItem> 
                </Left>
                <Right>
                    <RightActivityItem>
                        <ActivityVisual>
                            <ActivityImg3 src={activity3} />
                        </ActivityVisual>
                        <ActivityInfo right>
                            <ActivityTitle>{dictionary.activity.activityTitle3}</ActivityTitle>
                            <ActivityItemSubtitle>
                            {dictionary.activity.activityItemSubtitle3}
                            </ActivityItemSubtitle>
                        </ActivityInfo>
                    </RightActivityItem>
                </Right>
                </Content>
            </Wrapper>
        </Container>
    );
}

export default Life;
