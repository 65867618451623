import React, { useContext } from 'react';
import {
    Container,
    Item,
    ItemContainer,
    ItemInfo,
    Title,
    Wrapper,
    Texts,
    ItemImg,
    ItemImgContainer,
    ItemTitle,
    Subtitle,
    StyledSlider,
} from './teacherComponents';
import { LanguageContext } from '../../../containers/Language';
import mugalim1a from '../.././../images/about-school/mugalim/mugalim1a.png';
import mugalim1aa from '../.././../images/about-school/mugalim/mugalim1aa.png';
import mugalim2a from '../.././../images/about-school/mugalim/mugalim2a.png';
import mugalim2aa from '../.././../images/about-school/mugalim/mugalim2aa.png';
import mugalim3a from '../.././../images/about-school/mugalim/mugalim3a.JPG';
import mugalim3aa from '../.././../images/about-school/mugalim/mugalim3aa.png';
import mugalim4a from '../.././../images/about-school/mugalim/mugalim4a.png';
import mugalim6 from '../.././../images/about-school/mugalim/mugalim6.png';
import mugalim7 from '../.././../images/about-school/mugalim/mugalim7.png';
import mugalimbastauysh from '../.././../images/about-school/mugalim/mugalimbastauysh.png';
import mugalimdombyra from '../.././../images/about-school/mugalim/mugalimdombyra.png';
import mugalimdsh from '../.././../images/about-school/mugalim/mugalimdsh.png';
import mugalimdsh2 from '../.././../images/about-school/mugalim/mugalimdsh2.png';
import mugalimenbek from '../.././../images/about-school/mugalim/mugalimenbek.png';
import mugalimenglish from '../.././../images/about-school/mugalim/mugalimenglish.png';
import mugalimenglish2 from '../.././../images/about-school/mugalim/mugalimenglish2.png';
import mugalimintellekt from '../.././../images/about-school/mugalim/mugalimintellekt.png';
import mugalimmuzyka from '../.././../images/about-school/mugalim/mugalimmuzyka.png';
import mugalimorys from '../.././../images/about-school/mugalim/mugalimorys.png';
import mugalimpedagog from '../.././../images/about-school/mugalim/mugalimpedagog.png';
import mugalimqazaq from '../.././../images/about-school/mugalim/mugalimqazaq.png';
import mugalimrobot from '../.././../images/about-school/mugalim/mugalimrobot.png';
import mugalimtarih from '../.././../images/about-school/mugalim/mugalimtarih.png';
import mugalimturik from '../.././../images/about-school/mugalim/mugalimturik.png';
import mugalimmatem from '../.././../images/about-school/mugalim/mugalimmatem.png';

function Teacher() {
    const { dictionary } = useContext(LanguageContext);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
        ],
    };

    return (
        <Container id="teacher">
            <Wrapper>
                <Texts>
                    <Title>{dictionary.teacher.title}</Title>
                    <Subtitle>{dictionary.teacher.subtitle}</Subtitle>
                </Texts>
                <StyledSlider {...settings}>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalim1a} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Алтынбай Шырын Теміралықызы</b>
                            </ItemTitle>
                            <ItemInfo>1 "A" сынып жетекшісі</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalim1aa} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Лязева Амангуль Мутиголлаевна</b>
                            </ItemTitle>
                            <ItemInfo>1 "Ә" сынып жетекшісі</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalim2a} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Курмашева Айнур Нуруллаевна</b>
                            </ItemTitle>
                            <ItemInfo>2 "A" сынып жетекшісі</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalim2aa} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Куванышева Ильмира Макатжановна</b>
                            </ItemTitle>
                            <ItemInfo>2 "Ә" сынып жетекшісі</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalim3a} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Тойбазар Бақытнұр</b>
                            </ItemTitle>
                            <ItemInfo>3 "A" сынып жетекшісі</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalim3aa} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Абдоллаева Айнур Сериковна</b>
                            </ItemTitle>
                            <ItemInfo>3 "Ә" сынып жетекшісі</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalim4a} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Өтемұратова Кәмшат Мырзағалиқызы </b>
                            </ItemTitle>
                            <ItemInfo>4 "A" сынып жетекшісі</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalimqazaq} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Шаукет Назым</b>
                            </ItemTitle>
                            <ItemInfo>5 "A" сынып жетекшісі</ItemInfo>
                            <ItemInfo>
                                Қазак тілі мен әдебиеті мұғалімі
                            </ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalim6} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Жумекин Кайрат Есмуратович</b>
                            </ItemTitle>
                            <ItemInfo>6 "A" сынып жетекшісі</ItemInfo>
                            <ItemInfo>
                            Математика пәні мұғалімі
                            </ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalim7} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Муратов Асхат Айтбаевич</b>
                            </ItemTitle>
                            <ItemInfo>7 "A" сынып жетекшісі</ItemInfo>
                            <ItemInfo>
                            Информатика пәні мұғалімі
                            </ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalimbastauysh} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Аманова Мейрамгүл Бахитқызы</b>
                            </ItemTitle>
                            <ItemInfo>Бастауыш сынып мұғалімі</ItemInfo>
                        </Item>
                    </ItemContainer>
                
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalimmatem} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Қазыбекқызы Жансая</b>
                            </ItemTitle>
                            <ItemInfo>Математика пән мұғалімі</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalimorys} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Камбарова Бактыгул Бабеновна</b>
                            </ItemTitle>
                            <ItemInfo>Орыс тілі пән мұғалімі</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalimdsh} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Нурекешов Бекзат Нұрлыбекұлы</b>
                            </ItemTitle>
                            <ItemInfo>Денешынықтыру пән мұғалімі</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalimdsh2} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Тұрмұқанұлы Ислам</b>
                            </ItemTitle>
                            <ItemInfo>Денешынықтыру пән мұғалімі</ItemInfo>
                        </Item>
                    </ItemContainer>

                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalimenglish} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Успанова Жанаргуль Бекетовна</b>
                            </ItemTitle>
                            <ItemInfo>Ағылшын тілі пән мұғалімі</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalimenglish2} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Канлыбаева Айдана Нурлановна </b>
                            </ItemTitle>
                            <ItemInfo>Ағылшын тілі пән мұғалімі</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalimmuzyka} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Бердібаев Нұрдәулет Бақыткерейұлы</b>
                            </ItemTitle>
                            <ItemInfo>Музыка пән мұғалімі</ItemInfo>
                        </Item>
                    </ItemContainer>

                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalimpedagog} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Саяқызы Жанерке</b>
                            </ItemTitle>
                            <ItemInfo>Педагог - Психолог</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalimintellekt} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Тұрғынбек Ғазиза Болатқызы</b>
                            </ItemTitle>
                            <ItemInfo>Интеллектум пән мұғалімі</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalimrobot} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Акбулут Бейзанұр</b>
                            </ItemTitle>
                            <ItemInfo>
                                Робототехника-информатика пән мұғалімі
                            </ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalimdombyra} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Зейнелов Әлихан Бақытбекұлы</b>
                            </ItemTitle>
                            <ItemInfo>Домбыра пән мұғалімі</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalimturik} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Қуатова Камшат Тлеуханқызы</b>
                            </ItemTitle>
                            <ItemInfo>Түрік тілі пән мұғалімі</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalimenbek} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Едербаева Мөлдір Табандықызы </b>
                            </ItemTitle>
                            <ItemInfo>Көркем еңбек пән мұғалімі</ItemInfo>
                        </Item>
                    </ItemContainer>
                    <ItemContainer>
                        <Item>
                            <ItemImgContainer>
                                <ItemImg src={mugalimtarih} />
                            </ItemImgContainer>
                            <ItemTitle>
                                <b>Хасан Айымгүл Мерекеқызы</b>
                            </ItemTitle>
                            <ItemInfo>Тарих пән мұғалімі</ItemInfo>
                        </Item>
                    </ItemContainer>
                </StyledSlider>
            </Wrapper>
        </Container>
    );
}

export default Teacher;
