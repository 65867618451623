import React, { useContext } from 'react';
import {
    Container,
    Wrapper,
    InfoContainer,
    Title,
    Subtitle,
    ImgContainer,
    Img
} from "./cisComponents";
import { LanguageContext } from "../../../containers/Language";
import certificate from "../../../images/success/certificateCis.png";

function Cis() {
    const { dictionary } = useContext(LanguageContext);

    return (
        <Container id="cis">
            <Wrapper>
                <InfoContainer>
                    <Title>{dictionary.cis.title}</Title>
                    <Subtitle>
                        {dictionary.cis.subtitle1}
                    </Subtitle>
                    <Subtitle>
                        {dictionary.cis.subtitle2}
                    </Subtitle>
                    <Subtitle>
                        •  {dictionary.cis.subtitle3}
                    </Subtitle>
                    <Subtitle>
                        •  {dictionary.cis.subtitle4}

                    </Subtitle>
                    <Subtitle>
                        •  {dictionary.cis.subtitle5}

                    </Subtitle>
                    <Subtitle>
                        • {dictionary.cis.subtitle6}

                    </Subtitle>
                    <Subtitle>
                        •  {dictionary.cis.subtitle7}

                    </Subtitle>

                </InfoContainer>
                <ImgContainer>
                    <Img src={certificate} />
                </ImgContainer>
            </Wrapper>
        </Container>
    );
}

export default Cis;
