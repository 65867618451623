import React, {useContext} from 'react';
import {
    Container,
    Wrapper,
    InfoContainer,
    Title,
    Subtitle,
} from "./alumniComponents";
import { LanguageContext } from "../../../containers/Language";

function Alumni() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="english">
            <Wrapper>
                <InfoContainer>
                    <Title>{dictionary.alumni.title} </Title>
                    <Subtitle>
                    {dictionary.alumni.subtitle1}
                    </Subtitle>
                    <Subtitle>
                    {dictionary.alumni.subtitle2}
                    </Subtitle>
                </InfoContainer>
            </Wrapper>
        </Container>
    );
}

export default Alumni;
