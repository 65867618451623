import React, { useContext } from "react";
import {
    Container,
    Wrapper,
    Title,
    TextContainer,
    Item,
   Text,
} from "./internatRulesComponents";
import dormitoryRules from ".././../../documents/dormitoryRules.docx";
import { LanguageContext } from "../../../containers/Language";

function InternatRules() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="plan">
            <Wrapper>
                <Title>{dictionary.internatRules.title}</Title>
                <TextContainer>
                    <Item>
                        <Text>
                            {" "}
                            <a
                                href={dormitoryRules}
                                download={dormitoryRules}
                                style={{ color: "inherit", cursor: "pointer" }}
                            >
                                {dictionary.internatRules.text}
                            </a>
                        </Text>
                       
                    </Item>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        </div>
                </TextContainer>
            </Wrapper>
        </Container>
    );
}

export default InternatRules;
