import React, {useContext} from 'react';
import {
    Container,
    Wrapper,
    Item,
    Title,
    Subtitle,
    Text,
    Info,
    Items
} from "./bankAccountComponents";
import { LanguageContext } from "../../../containers/Language";

function BankAccount() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="bank-account">
            <Wrapper>
                <Info>{dictionary.bankAccount.info}</Info>
                <Items>
                <Item>
                    <Title>Мекеме атауы:</Title>
                    <Text>
                        <Subtitle>ЖШС «KEMEL BILIM» мектеп-интернаты</Subtitle>
                    </Text>
                </Item>
                <Item>
                    <Title>БСН:</Title>
                    <Text>
                        <Subtitle>180240034859</Subtitle>
                    </Text>
                </Item>
                <Item>
                    <Title>ЖСК:</Title>
                    <Text>
                        <Subtitle>KZ8396515</Subtitle>
                        <Subtitle>F0007473687</Subtitle>
                    </Text>
                </Item>
                <Item>
                    <Title>Мекен-жай:</Title>
                    <Text>
                        <Subtitle>030000, Ақтөбе облысы, Ақтөбе қаласы, Маресьев көшесі 81</Subtitle>
                    </Text>
                </Item>
                <Item>
                    <Title>Банк:</Title>
                    <Text>
                        <Subtitle>
                        "ForteBank" Акционерліқ қоғамы Ақтөбе филиалы  
                        </Subtitle>
                        <Subtitle>
                        IRTYKZKA КбЕ 17
                        </Subtitle>
                    </Text>
                </Item>
                </Items>
            </Wrapper>
        </Container>
    );
}

export default BankAccount;
