import React, { useContext } from "react";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
    faEnvelope,
    faLocationDot,
    faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Container,
    Lists,
    List,
    Logo,
    ListItem,
    ListItemWrapper,
    ListItemInfo,
    Wrapper,
    IconContainer,
    LogoContainer,
    TopContainer,
    BottomContainer,
    SocialMedia,
    Texts,
    Text,
    Link,
    ListLink,
} from "./footerComponents";
import logo1cut from "../../images/footer/logo1cut.png";
import { LanguageContext } from "../../containers/Language";

function Footer() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container>
            <Wrapper>
                <TopContainer>
                    <LogoContainer>
                        <Logo src={logo1cut} />
                    </LogoContainer>
                    <Lists>
                        <List>
                            <ListItem>
                                <ListLink href="/about-school">
                                    {dictionary.header.aboutSchool}
                                </ListLink>
                            </ListItem>

                            <ListItem>
                                <ListLink href="/about-school">
                                    {dictionary.header.learningProcess}
                                </ListLink>
                            </ListItem>
                            <ListItem>
                                <ListLink href="/learningProcess">
                                    {dictionary.header.tarbieJumystary}
                                </ListLink>{" "}
                            </ListItem>
                        </List>
                        <List>
                            <ListItem>
                                <ListLink href="/admission">
                                    {dictionary.header.admission}
                                </ListLink>
                            </ListItem>
                            <ListItem>
                                <ListLink href="/academic-plan">
                                    {dictionary.header.pricelist}
                                </ListLink>
                            </ListItem>
                            <ListItem>
                                <ListLink href="/contacts">
                                    {dictionary.header.contact}
                                </ListLink>{" "}
                            </ListItem>
                        </List>
                    </Lists>
                    <List>
                        <ListItemWrapper>
                            <ListItemInfo>
                                <IconContainer>
                                    <FontAwesomeIcon icon={faLocationDot} />
                                </IconContainer>
                                <b>{dictionary.info.listItemInfo1}</b>
                            </ListItemInfo>
                            <ListItemInfo>
                            {dictionary.info.listItemInfo11}
                            </ListItemInfo>
                        </ListItemWrapper>
                        <ListItemWrapper>
                            <ListItemInfo>
                                <IconContainer>
                                    <FontAwesomeIcon icon={faClock} />
                                </IconContainer>
                                <b>{dictionary.info.listItemInfo2}</b>
                            </ListItemInfo>
                            <ListItemInfo>
                            {dictionary.contacts.listItemInfo22}
                            </ListItemInfo>
                        </ListItemWrapper>
                    </List>
                    <List>
                        <ListItemWrapper>
                            <ListItemInfo>
                                <IconContainer>
                                    <FontAwesomeIcon icon={faPhone} />
                                </IconContainer>
                                <b>{dictionary.info.listItemInfo3}</b>
                            </ListItemInfo>
                            <ListItemInfo>8 (705) 601-24-24</ListItemInfo>
                            <ListItemInfo>admission@kemelbilim.edu.kz</ListItemInfo>
                        </ListItemWrapper>
                        <ListItemWrapper>
                            <ListItemInfo>
                                <IconContainer>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </IconContainer>
                                <b>{dictionary.info.listItemInfo4}</b>
                            </ListItemInfo>
                            <ListItemInfo>info@kemelbilim.edu.kz</ListItemInfo>
                        </ListItemWrapper>
                    </List>
                </TopContainer>
                <BottomContainer>
                    <Texts>
                        <Text>
                            © <b>KEMEL BILIM</b>
                        </Text>
                        <Text>{dictionary.footer.text}</Text>
                    </Texts>
                    <SocialMedia>
                        <Link href="https://www.instagram.com/kemelbilim.kz/">
                            <img
                                src="https://img.icons8.com/color/48/null/instagram-new--v1.png"
                                alt=""
                                style={{ width: "35px" }}
                            />
                        </Link>
                        <Link
                            href="https://m.facebook.com/kemelbilim.mektebi?eav=AfZNugtVSSNnu7o162hOf9avHLvHXEexrCanDPvu-bbnzxMN94PKHXgKakL1qRrzn08&fref=nf&paipv=0"
                        >
                            <img
                                src="https://img.icons8.com/color/48/null/facebook-new.png"
                                alt=""
                                style={{ width: "35px" }}
                            />
                        </Link>
                        <Link href="http://wa.me/77075552424/">
                            <img
                                src="https://img.icons8.com/color/48/null/whatsapp--v1.png"
                                alt=""
                                style={{ width: "35px" }}
                            />
                        </Link>
                        <Link href="www.tiktok.com/@kemelbilim.kz">
                            <img
                                src="https://img.icons8.com/color/48/null/tiktok--v1.png"
                                alt=""
                                style={{ width: "35px" }}
                            />
                        </Link>
                        <Link href="https://t.me/kemelbilim">
                            <img
                                src="https://img.icons8.com/color/48/null/telegram-app--v1.png"
                                alt=""
                                style={{ width: "35px" }}
                            />
                        </Link>
                        <Link href="https://www.youtube.com/channel/UCASJic-k_E6XIV1yRXEOePA">
                            <img
                                src="https://img.icons8.com/color/48/null/youtube-play.png"
                                alt=""
                                style={{ width: "35px" }}
                            />
                        </Link>
                    </SocialMedia>
                </BottomContainer>
            </Wrapper>
        </Container>
    );
}

export default Footer;
