import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;

export const Wrapper = styled.div`
    width: 1280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 90px 0;
    padding: 0 20px;
    
    @media only screen and (max-width: 67em) {
        
    }

    @media only screen and (max-width: 48em) {
        margin: 70px 0;
        padding: 0 20px;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
        flex-direction: column-reverse;
        margin: 50px 0 70px;
        padding: 0 15px;
    }
`;

export const InfoContainer = styled.div`
        display: flex;
    flex-direction: column;
    width: calc(57% - 70px);

    @media only screen and (max-width: 67em) {
        width: calc(57% - 50px);
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
    }
`;

export const Title = styled.h1`
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 20px;
    color: #1a1a4d;

    @media only screen and (max-width: 67em) {
        font-size: 3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const Subtitle = styled.p`
    font-size: 1.5rem;
    line-height: 1.7;
    color: gray;
    padding-bottom: 10px;

    @media only screen and (max-width: 67em) {
        font-size: 1.3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1.1rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;

export const ImgContainer = styled.div`
       width: 43%;
    align-self: center;

    @media only screen and (max-width: 67em) {
        margin-top: 0;
    }

    @media only screen and (max-width: 48em) {
        height: 400px;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        height: 420px;
        margin-top: 30px;
    }
`;

export const Img = styled.img`
    width: 100%;
    align-self: center;

    @media only screen and (max-width: 67em) {
        height: 100%;
        object-fit: cover;
    }
`;
