import React, {useContext} from 'react';
import {
    Container,
    Wrapper,
    InfoContainer,
    Title,
    Subtitle,
    List,
    Lists,
    Img,
    ImgContainer,
} from "./englishComponents";
import english from "../.././../images/education-process/english.jpg";
import { LanguageContext } from "../../../containers/Language";

function English() {
    const { dictionary } = useContext(LanguageContext);
    return (
        <Container id="english">
            <Wrapper>
                <InfoContainer>
                    <Title>{dictionary.english.title} </Title>
                    <Subtitle>
                    {dictionary.english.subtitle1}
                    </Subtitle>
                    <Subtitle>
                    {dictionary.english.subtitle2}
                    </Subtitle>
                    <Subtitle>
                    {dictionary.english.subtitle3}
                    </Subtitle>
                    <Lists>
                        <List>1. Starters</List>
                        <List>2. Movers</List>
                        <List>3. Flyers</List>
                    </Lists>
                    <Subtitle>{dictionary.english.subtitle4}</Subtitle>
                    <Subtitle>{dictionary.english.subtitle5}</Subtitle>
                    <Subtitle>{dictionary.english.subtitle6}</Subtitle>
                </InfoContainer>
                <ImgContainer>
                        <Img src={english} />
                </ImgContainer>
            </Wrapper>
        </Container>
    );
}

export default English;
