import React, { useContext } from "react";
import {
    Container,
    Wrapper,
    InfoContainer,
    ImgContainer,
    Title,
    Subtitle,
    Img,
    Top,
    Bottom,
    BottomImgContainer,
    BottomImg,
    StyledSlider
} from "./uniformComponents";
import forma1 from "../.././../images/about-school/forma/forma1.JPG";
import forma2 from "../.././../images/about-school/forma/forma2.JPG";
import forma3 from "../.././../images/about-school/forma/forma3.JPG";
import forma4 from "../.././../images/about-school/forma/forma4.png";

import { LanguageContext } from "../../../containers/Language";

function Activities() {
    const { dictionary } = useContext(LanguageContext);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                },
            },
        ],
    };

    return (
        <Container id="uniform">
            <Wrapper>
                <Top>
                    <InfoContainer>
                        <Title>{dictionary.uniform.title}</Title>
                        <Subtitle>{dictionary.uniform.subtitle}</Subtitle>
                    </InfoContainer>
                    <ImgContainer>
                        <Img src={forma1} />
                    </ImgContainer>
                </Top>
                <Bottom>
                    <StyledSlider {...settings}>
                        <BottomImgContainer>
                            <BottomImg src={forma2} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={forma3} />
                        </BottomImgContainer>
                        <BottomImgContainer>
                            <BottomImg src={forma4} />
                        </BottomImgContainer>

                    </StyledSlider>
                </Bottom>
            </Wrapper>
        </Container>
    );
}

export default Activities;
