import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;

export const Wrapper = styled.div`
    width: 1280px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 90px 0 60px 0;
    padding: 0 20px;

    @media only screen and (max-width: 67em) {
        margin: 70px 0;
    }

    @media only screen and (max-width: 48em) {
        margin: 60px 0 0;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
        margin: 50px 0 0;
        padding: 0 15px;
    }
`;

export const MainContainer = styled.div`
    display: flex;
    color: gray;
    padding-bottom: 30px;


    @media only screen and (max-width: 30em) {
        flex-direction: ${props => props.second ? "column-reverse" : "column"};
        padding-bottom: 10px;
    }
    
`;
export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 57%;
    padding: ${props => props.secondary ? "0 0 0 90px" : "0 90px 0 0 "};

    @media only screen and (max-width: 67em) {
        padding: ${props => props.secondary ? "0 0 0 50px" : "0 50px 0 0 "};
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        padding: ${props => props.secondary ? "0" : "0"};
    }
`;
export const TopImgContainer = styled.div`
    width: 43%;
    align-self: center;
    margin-top: 20px;

    @media only screen and (max-width: 67em) {
       height: 720px;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        height: 540px;
        margin-bottom: 30px;
        
    }
`;

export const TopImg = styled.img`
    width: 100%;
    object-fit: cover;
    height: 100%;
`;

export const ImgContainer = styled.div`
    width: 43%;
    align-self: center;
    margin-top: 20px;

    @media only screen and (max-width: 67em) {
       height: 720px;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        height: 770px;
        margin-bottom: 30px;
        
    }
`;

export const Img = styled.img`
    width: 100%;
    object-fit: cover;
    height: 100%;
`;

export const Title = styled.h1`
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 30px;
    color: #1a1a4d;
    align-self: center;

    @media only screen and (max-width: 67em) {
        font-size: 3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const Subtitle = styled.p`
    font-size: 1.4rem;
    line-height: 1.7;
    padding-bottom: 20px;
    

    @media only screen and (max-width: 67em) {
        font-size: 1.3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1.1rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;

export const Lists = styled.ul`
    list-style: disc;
    padding-left: 20px;
`;

export const List = styled.li`
    font-size: 1.3rem;
    line-height: 1.5;
    padding-bottom: 10px;

    @media only screen and (max-width: 67em) {
        font-size: 1.1rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;
