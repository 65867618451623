import styled from "styled-components";
import Slider from "react-slick";

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
`;

export const Wrapper = styled.div`
    width: 90%;
    max-width: 1280px;
    padding-top: 90px;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 48em) {
        padding-top: 50px;
    }
`;

export const Texts = styled.div`
    margin-bottom: 50px;
    padding: 0 20px;

    @media only screen and (max-width: 67em) {
        margin-bottom: 30px;
    }

    @media only screen and (max-width: 48em) {
        margin-bottom: 20px;
    }
`;

export const Title = styled.h1`
    color: #1a1a4d;
    font-size: 4rem;
    padding-left: 100px;
    font-weight: bold;
    padding-bottom: 15px;

    @media only screen and (max-width: 67em) {
        padding-left: 40px;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
        padding-left: 0;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const Subtitle = styled.h5`
    font-size: 1.5rem;
    padding-left: 350px;
    letter-spacing: 0.15em;

    @media only screen and (max-width: 67em) {
        padding-left: 100px;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1rem;
        padding-left: 0;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;

export const ItemContainer = styled.div`
    padding: 0 1rem;
    height: 350px;

    @media only screen and (max-width: 30em) {
        padding: 0 15px;
    }
`;

export const Item = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgrey;
    height: 100%;
    
    &:hover {
        transform: translateY(-10px);
        transition: transform 0.5s;
    }

    @media only screen and (max-width: 48em) {
        &:hover {
            transform: none;
            transition: none;
        }
    }
`;

export const ItemImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const ItemInfo = styled.div`
    font-size: 1.375rem;
    padding: 25px 0;
    font-weight: bold;
    cursor: pointer;

    @media only screen and (max-width: 67em) {
        font-size: 1.25rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;

export const ItemLink = styled.a`
    text-decoration: none;
    color: black;

    &:hover {
        color: #10a74b;;
    }
`;

export const StyledSlider = styled(Slider)`
    padding-bottom: 20px;
    .slick-prev {
        width: 3rem;
        height: 3rem;
        left: -3.4rem;

        @media only screen and (max-width: 67em) {
            width: 2.5rem;
            height: 2.5rem;
            left: -2.4rem;
        }

        @media only screen and (max-width: 30em) {
            width: 2rem;
            height: 2rem;
            left: -1.4rem;
        }
    }

    .slick-next {
        width: 3rem;
        height: 3rem;
        right: -3.4rem;

        @media only screen and (max-width: 67em) {
            width: 2.5rem;
            height: 2.5rem;
            right: -2.4rem;
        }

        @media only screen and (max-width: 30em) {
            width: 2rem;
            height: 2rem;
            right: -1.4rem;
        }
    }

    .slick-arrow {
        border: 1px solid #10a74b;
        background-color: white;
        border-radius: 50%;

        @media only screen and (max-width: 30em) {
            border: none;
            background-color: white;
            border-radius: none;
        }
    }

    .slick-prev:before,
    .slick-next:before {
        color: #10a74b !important;
    }
`;
