import styled from "styled-components";
import Slider from "react-slick";

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
`;

export const Wrapper = styled.div`
    width: 90%;
    max-width: 1280px;
    display: flex;
    margin: 70px 0;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 30em) {
        padding: 0 10px;
        margin: 40px 0 50px;
    }
`;

export const Top = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 32px;
    padding: 0 20px;

    @media only screen and (max-width: 30em) {
        flex-direction: column;
    }
`;

export const Bottom = styled.div`
    flex-direction: column;
    display: flex;
    max-width: 1280px;
`;

export const StyledSlider = styled(Slider)`
      .slick-prev {
        width: 3rem;
        height: 3rem;
        left: -3.4rem;

        @media only screen and (max-width: 67em) {
            width: 2.5rem;
            height: 2.5rem;
            left: -2.4rem;
        }

        @media only screen and (max-width: 30em) {
            width: 2rem;
            height: 2rem;
            left: -1.4rem;
        }
    }

    .slick-next {
        width: 3rem;
        height: 3rem;
        right: -3.4rem;

        @media only screen and (max-width: 67em) {
            width: 2.5rem;
            height: 2.5rem;
            right: -2.4rem;
        }

        @media only screen and (max-width: 30em) {
            width: 2rem;
            height: 2rem;
            right: -1.4rem;
        }
    }

    .slick-arrow {
        border: 1px solid #121233;
        background-color: white;
        border-radius: 50%;

        @media only screen and (max-width: 30em) {
         border: none;
        background-color: white;
        border-radius: none;
        }
    }

    .slick-prev:before,
    .slick-next:before {
        color: #121233; !important;
    }
`;

export const ImgContainer = styled.div`
    width: 35%;

    @media only screen and (max-width: 67em) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media only screen and (max-width: 48em) {
        width: 45%;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
    }
`;

export const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media only screen and (max-width: 67em) {
        height: 600px;
    }

    @media only screen and (max-width: 30em) {
        height: 400px;
    }
`;

export const InfoContainer = styled.div`
    border-left: none;
    padding: 30px 90px 30px 100px;
    width: 65%;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 67em) {
        padding: 30px 40px;
    }

    @media only screen and (max-width: 48em) {
        width: 55%;
        padding: 30px;
    }

    @media only screen and (max-width: 30em) {
        width: 100%;
        padding: 30px 0;
    }
`;

export const InfoContainerTitle = styled.div`
    font-weight: 600;
    font-size: 1.6rem;
    padding-bottom: 10px;
`;

export const InfoContainerSubtitle = styled.div`
    color: gray;
    font-size: 1.2rem;
    padding-bottom: 15px;
`;

export const InfoContainerBody = styled.div``;

export const InfoContainerBodyTitle = styled.div`
    font-size: 1.3rem;
    padding: 10px 0;
    border-bottom: 1px solid #c5c5c5;

    @media only screen and (max-width: 48em) {
        font-size: 1.1rem;
    }
`;

export const InfoContainerList = styled.p`
    font-size: 1.1rem;
    line-height: 1.5;
    font-weight: 100;
    padding-top: 10px;
    color: #666464;

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;

export const Texts = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Title = styled.h1`
    color: #1a1a4d;
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 20px;
    text-align: center;

    @media only screen and (max-width: 67em) {
        font-size: 3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const Subtitle = styled.p`
    font-size: 1.5rem;
    line-height: 1.3;

    @media only screen and (max-width: 67em) {
        font-size: 1.3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1.2rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;


export const ItemContainer = styled.div`
    display: flex;
    cursor: pointer;
`;

export const Item = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid lightgrey;
    margin: 20px;
    height: 540px;

    @media only screen and (max-width: 30em) {
        height: 640px;
    }
`;

export const ItemImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const ItemImgContainer = styled.div`
    height: 430px;

    @media only screen and (max-width: 30em) {
        height: 520px;
    }
`;

export const ItemTitle = styled.span`
    font-size: 17px;
    line-height: 1.5;
    margin-bottom: 7px;
    padding: 10px 20px;
    margin: 0 1px;
`;

export const ItemInfo = styled.span`
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 7px;
    padding: 0 20px 10px;
    margin: 0 1px;
    color: #666666;
`;

