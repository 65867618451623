import styled from "styled-components";
import Slider from "react-slick";

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
`;

export const Wrapper = styled.div`
    width: 90%;
    max-width: 1280px;
    display: flex;
    padding: 70px 0;
    flex-direction: column;

    @media only screen and (max-width: 30em) {
        padding: 0 10px;
        margin: 40px 0;
    }
`;

export const Texts = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
`;

export const Title = styled.h1`
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 20px;
    text-align: center;
    color: #1a1a4d;

    @media only screen and (max-width: 67em) {
        font-size: 3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 2.25rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1.625rem;
    }
`;

export const Subtitle = styled.p`
    font-size: 1.5rem;
    line-height: 1.3;
    color: gray;

    @media only screen and (max-width: 67em) {
        font-size: 1.3rem;
    }

    @media only screen and (max-width: 48em) {
        font-size: 1.2rem;
    }

    @media only screen and (max-width: 30em) {
        font-size: 1rem;
    }
`;

export const ItemContainer = styled.div`
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const Item = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 50px 50px 20px;
    height: 250px;

    @media only screen and (max-width: 67em) {
        margin: 30px;
    }

    @media only screen and (max-width: 48em) {
        margin: 20px 20px 80px;
    }

    @media only screen and (max-width: 30em) {
        margin: 30px 30px 60px;
    }
`;

export const ItemImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media only screen and (max-width: 48em) {
        height: 360px;
    }

    @media only screen and (max-width: 30em) {
      height: 340px;
    }
`;

export const ItemTitle = styled.span` 
    font-weight: 500;
    font-size: 22px;
    letter-spacing: .09em;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.1;
`;

export const ItemInfo = styled.span`
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 7px;
    padding: 0 20px 10px;
    margin: 0 1px;
    color: lightgray;
`;


export const StyledSlider = styled(Slider)`
    .slick-prev {
        width: 3rem;
        height: 3rem;
        left: -3.4rem;

        @media only screen and (max-width: 67em) {
            width: 2.5rem;
            height: 2.5rem;
            left: -2.4rem;
        }

        @media only screen and (max-width: 30em) {
            width: 2rem;
            height: 2rem;
            left: -1.4rem;
        }
    }

    .slick-next {
        width: 3rem;
        height: 3rem;
        right: -3.4rem;

        @media only screen and (max-width: 67em) {
            width: 2.5rem;
            height: 2.5rem;
            right: -2.4rem;
        }

        @media only screen and (max-width: 30em) {
            width: 2rem;
            height: 2rem;
            right: -1.4rem;
        }
    }

    .slick-arrow {
        border: 1px solid #10a74b;
        background-color: white;
        border-radius: 50%;

        @media only screen and (max-width: 30em) {
         border: none;
        background-color: white;
        border-radius: none;
        }
    }

    .slick-prev:before,
    .slick-next:before {
        color: #10a74b !important;
    }
`;
